import * as React from 'react';
import {Box,Avatar} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {   
        field: 'logo',
        headerName: '',
        width: 90,
        display:'flex',
        renderCell: (params) => (
          <Avatar sx={{ width: 24, height: 24 }} alt={params.row.symbol} src={`https://storage.googleapis.com/iex/api/logos/${params.row.symbol}.png`} />
        ),
        flex:1
    },
    {   
        field: 'symbol',
        headerName: 'Symbol',
        width: 90 ,
        flex:2
    },
    /*{
      field: 'company_name',
      headerName: 'Security Name',
      width: 150,
      editable: true,
      flex:1
    },*/
    {
      field: 'weight',
      headerName: 'Weight',
      width: 150,
      flex:2,
      valueGetter: (value) => {
        if (!value) {
          return value;
        }
        return value * 100;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

export default function WeightingTable(props) {

    return (
    <Box sx={{ width: '100%', height: '100%' , overflow:"auto"}}>
      <DataGrid
        sx={{border:0}}
        checkboxSelection={false}
        getRowId={(r) => r.figi}
        rows={props.rowData ?? []}
        columns={columns}
        rowHeight={35}
        columnHeaderHeight={35}
        hideFooter={true}
        //initialState={{
        //  pagination: {
        //    paginationModel: {
        //      pageSize: 10,
        //    },
        //  },
        //}}
        //pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
    );
  }