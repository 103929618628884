export default [
    "#4ECDC4",
    "#FC4798",
    "#3D5A80",
    "#844DCC",
    "#95CC4D",
    "#4F46FC",
    "#F3FC46",
    "#3D7F41",
    "#7F3D7B",
    "#46C5FC",
    "#FC46DC"
]