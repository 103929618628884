export default function getParamsJoined(params,props,searchParams,urlParams){
    var thisProps = {}
    if(props == undefined){
        thisProps = {}
    } else {
        thisProps = props
    }
    var response = {}
    for (var i = 0; i < params.length; i++) {
        var p = params[i]
        if (thisProps[p]){ response[p] = thisProps[p]}
        else if (urlParams[p]){ response[p] = urlParams[p]}
        else if (searchParams){
            try{
                if (['colors','lineColors'].includes(p)){
                    response[p] = searchParams.get(p).split(",")
                    response[p] = response[p].map(i => '#' + i)
                } else if (['securities'].includes(p)){
                    response[p] = searchParams.get(p).split(",")
                } else {
                    response[p] = searchParams.get(p)
                }
            } catch(e) {
                response[p] = undefined 
            }
        } else {
            response[p] = undefined
        }
    }
    Object.keys(response).forEach(key => response[key] === null && delete response[key])
    return response
}