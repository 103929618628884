import React from 'react';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

export default function HowItWorks(props) {
  document.title = `Index One | T&C`
  return (
    <Container className="content">
      <Typography variant='h2'>PRIVACY POLICY</Typography>
            <h2>Privacy Policy</h2>
            <p>Your privacy is important to us. It is INDEX ONE LIMITED's policy to respect your privacy regarding any information we may collect from you across our website, <a href="https://indexone.io">https://indexone.io</a>, and other sites we own and operate.</p>
            <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
            <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>
            <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
            <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
            <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
            <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
            <p>This policy is effective as of 30 May 2019.</p>
      <Typography variant='h2'>TERMS OF SERVICE</Typography>
            <h2>INDEX ONE LIMITED Terms of Service</h2>
            <h3>1. Terms</h3>
            <p>By accessing the website at <a href="https://indexone.io">https://indexone.io</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
            <h3>2. Use License</h3>
            <ol type="a">
              <li>Permission is granted to temporarily download one copy of the materials (information or software) on INDEX ONE LIMITED's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
              <ol type="i">
                  <li>modify or copy the materials;</li>
                  <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                  <li>attempt to decompile or reverse engineer any software contained on INDEX ONE LIMITED's website;</li>
                  <li>remove any copyright or other proprietary notations from the materials; or</li>
                  <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
              </ol>
                </li>
              <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by INDEX ONE LIMITED at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
            </ol>
            <h3>3. Disclaimer</h3>
            <ol type="a">
              <li>The materials on INDEX ONE LIMITED's website are provided on an 'as is' basis. INDEX ONE LIMITED makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
              <li>Further, INDEX ONE LIMITED does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
            </ol>
            <h3>4. Limitations</h3>
            <p>In no event shall INDEX ONE LIMITED or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on INDEX ONE LIMITED's website, even if INDEX ONE LIMITED or a INDEX ONE LIMITED authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
            <h3>5. Accuracy of materials</h3>
            <p>The materials appearing on INDEX ONE LIMITED's website could include technical, typographical, or photographic errors. INDEX ONE LIMITED does not warrant that any of the materials on its website are accurate, complete or current. INDEX ONE LIMITED may make changes to the materials contained on its website at any time without notice. However INDEX ONE LIMITED does not make any commitment to update the materials.</p>
            <h3>6. Links</h3>
            <p>INDEX ONE LIMITED has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by INDEX ONE LIMITED of the site. Use of any such linked website is at the user's own risk.</p>
            <h3>7. Modifications</h3>
            <p>INDEX ONE LIMITED may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
            <h3>8. Governing Law</h3>
            <p>These terms and conditions are governed by and construed in accordance with the laws of United Kingdom and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
            <h3>9. Important notes regarding indices</h3>
            <p>The details given on these pages do not constitute an offer. They are given for information purposes only. No liability is assumed for the
correctness and accuracy of the details given. It is not possible to invest directly in an index. INDEX ONE LIMITED does not sponsor, endorse, sell or promote any investment fund or other investment product that is offered by third
parties and that seeks to provide an investment return based on the performance of any index. INDEX ONE LIMITED does not make investment
recommendations, and inclusion of a security within an index are not recommendations by INDEX ONE LIMITED for the purchase or
sale of any security or other asset or to pursue any investment strategy. This document is for information purposes only and does not
constitute an offer of services in jurisdictions where INDEX ONE LIMITED does not have the necessary licenses.</p>

      <Typography variant='h2'>SUBSCRIPTION TERMS</Typography>
      <h1>DEFINITIONS</h1>
<p><strong>Index</strong> (or <strong>Indices</strong>) is defined as a database entry, or time series of database entries, which is calculated by the Software, based on calculation rules defined by, or approved by the Customer.</p>
<p><strong>Backtest </strong>is defined as an index calculation using only historical data.</p>
<p><strong>Data Consumption </strong>is defined as the total number of Data Points utilized or retrieved by an Index, or any number of Indices, over any given time period.</p>
<p><strong>Data Point</strong> is defined as an observation at either single point in time in the case of time-series data, or a static observation, which is retrieved by the Software from an internal or external database, and used as a component of the index calculation. Indices consume Data Points at a rate based on Index characteristics defined by the Customer, including but not limited to: recalculation frequency, number of index constituents, and repricing variables. Examples of commonly consumed data points&nbsp; include, but are not limited to: stock price for an asset at any given point in time, market capitalization, balance sheet data.</p>
<p><strong>Live Index </strong>(or <strong>Live</strong> <strong>Indices</strong>)&nbsp; refers to an Index which is continuously calculated and stored according to a calculation schedule defined by, or approved by the Customer. An Index is defined as Live as soon as the Index has performed its first Real-Time calculation. An Index ceases to be live when calculation has been halted by the Customer or Company, or automatically when reaching a predetermined end time. A purely Backtested Index does not classify as a Live Index.</p>
<p><strong>Real-Time </strong>refers to the latest available unit of observation, retrieved or distributed by the Company on a commercially reasonable efforts basis.</p>
<h1>TERMS AND CONDITIONS</h1>
<ol>
<li>
<h2>INDEX ONE SERVICES AND SUPPORT</h2>
</li>
<ol>
<li>Subject to the terms of this Agreement, Company will use commercially reasonable efforts to provide Customer the Services [in accordance with the Service Level Terms attached hereto as Exhibit B]. As part of the registration process, Customer will identify an administrative user name and password for Customer&rsquo;s Company account.&nbsp; Company reserves the right to refuse registration of, or cancel passwords it deems inappropriate.</li>
<li>Subject to the terms hereof, Company will provide Customer with reasonable technical support services in accordance with the terms set forth in Exhibit C.</li>
</ol>
<li>
<h2>RESTRICTIONS AND RESPONSIBILITIES</h2>
</li>
<ol>
<li>Customer will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (&ldquo;Software&rdquo;); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by Company or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third party; or remove any proprietary notices or labels.&nbsp; With respect to any Software that is distributed or provided to Customer for use on Customer premises or devices, Company hereby grants Customer a non-exclusive, non-transferable, non-sublicensable license to use such Software during the Term only in connection with the Services.</li>
<li>Customer represents, covenants, and warrants that Customer will use the Services only in compliance with this Agreement and any of the Company&rsquo;s standard published policies then in effect (the &ldquo;Policy&rdquo;) and all applicable laws and regulations. Customer hereby agrees to indemnify and hold harmless the Company against any damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys&rsquo; fees) in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from Customer&rsquo;s use of Services. Although Company has no obligation to monitor Customer&rsquo;s use of the Services, Company may do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.</li>
<li>Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, &ldquo;Equipment&rdquo;).&nbsp; Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer&rsquo;s knowledge or consent.</li>
</ol>
<li>
<h2>CONFIDENTIALITY; PROPRIETARY RIGHTS</h2>
</li>
<ol>
<li>Each party (the &ldquo;Receiving Party&rdquo;) understands that the other party (the &ldquo;Disclosing Party&rdquo;) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party&rsquo;s business (hereinafter referred to as &ldquo;Proprietary Information&rdquo; of the Disclosing Party).&nbsp; Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.&nbsp; Proprietary Information of Customer includes non-public data provided by Customer to Company to enable the provision of the Services (&ldquo;Customer Data&rdquo;). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.&nbsp; The Disclosing Party agrees that the foregoing shall not apply with respect to any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.</li>
<li>Customer shall own all right, title and interest in and to the Customer Data. Company shall own and retain all right, title and interest in and to (a) the Services and Software, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with Implementation Services or support, and (c) all intellectual property rights related to any of the foregoing.</li>
<li>Notwithstanding anything to the contrary, Company shall have the right to collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning Customer Data and data derived therefrom), and&nbsp; Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Company offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business.&nbsp; No rights or licenses are granted except as expressly set forth herein.</li>
<li>Customer shall own all right, title and interest in and to any Indices created by Customer, or created by Company at the instruction of Customer. Company shall not distribute, license, share, or make available any Customer Indices unless agreed to explicitly in writing, or the Index is made publicly available by Customer through using the Software.</li>
</ol>
<li>
<h2>PAYMENT OF FEES</h2>
</li>
<ol>
<li>Customer will pay Company the then applicable fees described in the Order Form and detailed in Exhibit D for the Services and Implementation Services in accordance with the terms therein (the &ldquo;Fees&rdquo;).&nbsp; If Customer&rsquo;s use of the Services exceeds the Service Capacity set forth on the Order Form or otherwise requires the payment of additional fees (per the terms of this Agreement), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein.&nbsp; Company reserves the right to change the Fees or applicable charges and to institute new charges and Fees at the end of the Initial Service Term or the current renewal term, upon thirty (30) days prior notice to Customer (which may be sent by email). If Customer believes that Company has billed Customer incorrectly, Customer must contact Company no later than 60 days after the date on the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit.&nbsp; Inquiries should be directed to Company&rsquo;s customer support department.</li>
<li>Company may choose to bill through an invoice, in which case, full payment for invoices issued in any given month must be received by Company thirty&nbsp;(30) days after the mailing date of the invoice.&nbsp; Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in immediate termination of Service. Customer shall be responsible for all taxes associated with Services (other than taxes payable by Company based on its income), including any VAT payable to the Company in relation to the Services.</li>
</ol>
<li>
<h2>TERM AND TERMINATION</h2>
</li>
<ol>
<li>Subject to earlier termination as provided below, this Agreement is for the Initial Service Term as specified in the Order Form, and shall be automatically renewed for additional periods of the same duration as the Initial Service Term (collectively, the &ldquo;Term&rdquo;), unless either party requests termination at least thirty (30) days prior to the end of the then-current term.</li>
<li>In addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30) days&rsquo; notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of this Agreement.&nbsp; Customer will pay in full for the Services up to and including the last day on which the Services are provided. Upon any termination, Company will make all Customer Data available to Customer for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but is not obligated to, delete stored Customer Data. All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.</li>
</ol>
<li>
<h2>WARRANTY AND DISCLAIMER</h2>
</li>
<ol>
<li>Company shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner which minimizes errors and interruptions in the Services and shall perform the Implementation Services in a professional and workmanlike manner.&nbsp; Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company&rsquo;s reasonable control, but Company shall use reasonable efforts to provide advance notice in writing or by email of any scheduled service disruption.&nbsp; HOWEVER, COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES.<strong><em>&nbsp; </em></strong>EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES AND IMPLEMENTATION SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; FOR ANY PILOT PERIOD AS WELL AS THE REMAINING TERM OF THE AGREEMENT AND THE&nbsp; COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
<li>THE COMPANY DOES NOT GUARANTEE CORRECTNESS OF ANY INDICES CALCULATED BY THE COMPANY, OR BY THE CUSTOMER USING THE SERVICES. INDICES MAY CONTAIN ERRORS DUE TO MISSING OR INCORRECT DATA OR CALCULATION ERRORS IN THE SOFTWARE.</li>
<li>INDICES ARE HYPOTHETICAL AND NOT A REPRESENTATION OF ACTUAL PERFORMANCE. ACTUAL PERFORMANCE MAY DIFFER FROM OR BE LOWER THAN INDEX PERFORMANCE.</li>
<li>THE COMPANY DOES NOT OFFER INVESTMENT ADVICE. INDICES ARE NOT INVESTMENT ADVICE, AND SHOULD NOT BE CONSTRUED AS INVESTMENT ADVICE.</li>
</ol>
<li>
<h2>INDEMNITY</h2>
</li>
<ol>
<li>Company shall hold Customer harmless from liability to third parties resulting from infringement by the Service of any patent or any copyright or misappropriation of any trade secret, provided Company is promptly notified of any and all threats, claims and proceedings related thereto and given reasonable assistance and the opportunity to assume sole control over defense and settlement; Company will not be responsible for any settlement it does not approve in writing.&nbsp; The foregoing obligations do not apply with respect to portions or components of the Service (i) not supplied by Company, (ii) made in whole or in part in accordance with Customer specifications, (iii) that are modified after delivery by Company, (iv) combined with other products, processes or materials where the alleged infringement relates to such combination, (v) where Customer continues allegedly infringing activity after being notified thereof or after being informed of modifications that would have avoided the alleged infringement, or (vi) where Customer&rsquo;s use of the Service is not strictly in accordance with this Agreement.&nbsp; If, due to a claim of infringement, the Services are held by a court of competent jurisdiction to be or are believed by Company to be infringing, Company may, at its option and expense (a) replace or modify the Service to be non-infringing provided that such modification or replacement contains substantially similar features and functionality, (b) obtain for Customer a license to continue using the Service, or (c) if neither of the foregoing is commercially practicable, terminate this Agreement and Customer&rsquo;s rights hereunder and provide Customer a refund of any prepaid, unused fees for the Service.</li>
</ol>
<li>
<h2>LIMITATION OF LIABILITY</h2>
</li>
<ol>
<li>NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A)&nbsp;FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B)&nbsp;FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C)&nbsp;FOR ANY MATTER BEYOND COMPANY&rsquo;S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 1 MONTH PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
</ol>
<li>
<h2>MISCELLANEOUS</h2>
</li>
<ol>
<li>If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.&nbsp; This Agreement is not assignable, transferable or sublicensable by Customer except with Company&rsquo;s prior written consent.&nbsp; Company may transfer and assign any of its rights and obligations under this Agreement without consent.</li>
<li>This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.</li>
<li>No agency, partnership, joint venture, or employment is created as a result of this Agreement and Customer does not have any authority of any kind to bind Company in any respect whatsoever.&nbsp; In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and attorneys&rsquo; fees.</li>
<li>All notices under this Agreement will be in writing and will be deemed to have been duly given when received, if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or email; the day after it is sent, if sent for next day delivery by recognized overnight delivery service; and upon receipt, if sent by certified or registered mail, return receipt requested.</li>
<li>This Agreement shall be governed by the laws of England and Wales without regard to its conflict of laws provisions. Each of the parties to this Agreement irrevocably agrees that the courts of England shall have exclusive jurisdiction to hear and decide any suit, action or proceedings, and/or to settle any disputes, which may arise out of or in connection with this Agreement or its formation or validity and, for these purposes, each party irrevocably submits to the jurisdiction of the courts of England.</li>
<li><a href="https://www.lawinsider.com/clause/counterparts" target="_blank" rel="noopener">This Agreement</a> may be executed in two or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.</li>
</ol>
</ol>
<h1>EXHIBIT A</h1>
<p><strong>Statement of Work</strong></p>
<h1>EXHIBIT B</h1>
<p><strong>Service Level Terms</strong></p>
<p>The Services shall be available for use 99.9% of the time during the hours of 9am and 5pm GMT, measured monthly, excluding holidays and weekends and scheduled maintenance.&nbsp; If Customer requests maintenance during these hours, any uptime or downtime calculation will exclude periods affected by such maintenance.&nbsp; Further, any downtime resulting from outages of third party connections or utilities or other reasons beyond Company&rsquo;s control will also be excluded from any such calculation. Customer's sole and exclusive remedy, and Company's entire liability, in connection with Service availability shall be that for each period of downtime lasting longer than five hours, Company will credit Customer 5% of Service fees for each period of 1 or more consecutive hours of downtime; provided that no more than one such credit will accrue per day. In order to calculate such credit, the monthly Service Fee shall be pro rated for each hour by the total being divided by the number of days in the particular month and each day&rsquo;s fee being divided by 24 hours. Downtime shall begin to accrue as soon as Customer (with notice to Company) recognizes that downtime is taking place, and continues until the availability of the Services is restored.&nbsp; In order to receive downtime credit, Customer must notify Company in writing within 24 hours from the time of downtime, and failure to provide such notice will forfeit the right to receive downtime credit.&nbsp; Such credits may not be redeemed for cash and shall not be cumulative beyond a total of credits for one (1) week of Service Fees in any one (1) calendar month in any event.&nbsp; Company will only apply a credit to the month in which the incident occurred.&nbsp; Company&rsquo;s blocking of data communications or other Service in accordance with its policies shall not be deemed to be a failure of Company to provide adequate service levels under this Agreement.</p>
<h1>EXHIBIT C</h1>
<p><strong>Support Terms</strong></p>
<p>Company will provide Technical Support to Customer via both telephone and electronic mail on weekdays during the hours of 9:00 am through 5:00 pm GMT, with the exclusion of National Holidays (&ldquo;Support Hours&rdquo;).&nbsp;</p>
<p>Customer may initiate a helpdesk ticket during Support Hours by calling [+44 7894 122311] or any time by emailing [support@indexone.io].</p>
<p>Company will use commercially reasonable efforts to respond to all Helpdesk tickets within one (1) business day.</p>
<h1>EXHIBIT D</h1>
<p><strong>Usage Based Pricing</strong></p>
<p>Usage based pricing is dependent on the number of Live Indices calculated using the Service (&ldquo;Index Based Pricing&rdquo;), as well as the total number of Data Points consumed by the Indices over each one-month period (&ldquo;Consumption Based Pricing&rdquo;).</p>
<p>Index Based Pricing is calculated by multiplying the monthly price per Live Index defined below, with the number of unique Live Indices during the billing period. The precise definition of a Live Index is found in the definitions section of this document.</p>
<p>Consumption Based Pricing is defined as the sum of Data Points used across all Live and Backtested Indices calculated by the Customer over the billing period, rounded to the next 1000 increment.</p>
<p><strong>Price per Live Index per month: </strong>$90</p>
<p><strong>Price per 1000 Data Points : </strong>$1</p>
<p><strong>EXAMPLE</strong></p>
<p>Customer creates 10 indices using the Services on the first day of the billing period (&ldquo;Calculation Date&rdquo;). 5 of these indices do not have any predetermined end date, 2 of the indices have an ending time 15 days in the future, and the remaining 3 indices have an ending time 15 days in the past.</p>
<p>Each index in this example starts 100 days before the Calculation Date, consists of 100 assets, and is set to be repriced once per day.</p>
<p>At the end of the billing period, the Index Based Pricing calculation will include 7 Live Indices, as there were 7 unique Live Indices during the billing period.</p>
<p>The total number of Data Points consumed will amount to the total number of calculations multiplied by the total number of assets: (130 + 130 + 130 + 130 + 130 + 85 + 85 + 115 + 115) * 100 = 105,000 .</p>
<p>The total Usage Based Pricing for this billing period will therefore be calculated as (105,000 / 1000 = 105)&nbsp; + (7 * 90 = 630) = 105 + 630</p>
<p>Consumption Based Pricing is capped at a maximum of $150 per month per index.</p>
      </Container>
  );
}