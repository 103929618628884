import React, { useState, useEffect } from 'react';
import {Tabs,Tab,Box,Button} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';

import UniverseUpload from './CustomUniverse';
import WeightingConfig from './WeightingConfig'
import CustomCode from './CustomCode';

function TabPanel(props){
  const { children, value, index, ...other } = props;
  return (
    <Box hidden={value !== index} sx={{height:'100%'}}>
      {children}
    </Box>
  )
}

export default function IndexSetup(props) {
  const [tabSelected, setTabSelected] = useState(0)

  return (
    <Box sx={{width:'100%',height:'100%'}}>
      <Tabs
        value={tabSelected}
        onChange={(event,value)=>setTabSelected(value)}
        //variant='fullWidth'
        //centered
        sx={{height:'40px',minHeight:'40px'}}
      >
        <Tab sx={{minHeight:'15px'}} value={0} iconPosition="start"  icon={<PhoneIcon />} label="Universe"/>
        <Tab sx={{minHeight:'15px'}} value={1} iconPosition="start"  icon={<PhoneMissedIcon />} label="Weighting" />
        <Tab sx={{minHeight:'15px'}} value={2} iconPosition="start"  icon={<FavoriteIcon />} label="Custom Universe" />
        <Tab sx={{minHeight:'15px'}} value={3} iconPosition="start"  icon={<PersonPinIcon />} label="Code" />
        <Tab sx={{minHeight:'15px'}} value={4} iconPosition="start"  icon={<PersonPinIcon />} label="Datasets" />
        <Tab sx={{minHeight:'15px'}} value={5} iconPosition="start"  icon={<PersonPinIcon />} label="Specifications" />
        <Tab sx={{minHeight:'15px'}} value={6} iconPosition="start"  icon={<PersonPinIcon />} label="Corporate Actions" />
      </Tabs>
      <Box sx={{height:'calc(100% - 40px)',overflow:'hidden'}}>
        <TabPanel value={tabSelected} index={0}>
          <div style={{backgroundColor:'black',height:'100%'}}>
            <Button onClick={e=>props.testChange()}>Test change</Button>
          </div>
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <WeightingConfig
            handleChange={props.handleChange}
            indexSpecifications={props.indexSpecifications}
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={2}>
          <UniverseUpload
            setIndexSpecifications={props.setIndexSpecifications}
            indexSpecifications={props.indexSpecifications}
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={3}>
          <CustomCode
            handleChange={props.handleChange}
            setIndexSpecifications={props.setIndexSpecifications}
            indexSpecifications={props.indexSpecifications}
          />
        </TabPanel>
        <TabPanel value={tabSelected} index={4}>

        </TabPanel>
        <TabPanel value={tabSelected} index={5}>

        </TabPanel>
        <TabPanel value={tabSelected} index={6}>
          {JSON.stringify(props.indexSpecifications)}
        </TabPanel>
      </Box>
    </Box>
  );
}