import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from '../../elements/NotificationContext';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { DataGrid,  GridToolbarContainer, GridToolbarExport, gridSortedRowIdsSelector } from '@mui/x-data-grid';

import StageChip from '../../elements/StageChip'

//icons
import DeleteIcon from '@mui/icons-material/Delete';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventIcon from '@mui/icons-material/Event';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import CircularProgress from '@mui/material/CircularProgress';

//dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { set } from 'lodash';
import { useTeamIndicesQuery,useTeamPatchMutation,useIndexPatchMutation } from '../../../store/indexOneApi';


const fetchAttrs = ["id","version","created_at","name","calculation_frequency","stage","current_divisor","team_id","stats"]

const getFilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

export default function TeamIndices(props) {
  const { createNotification } = useContext(NotificationContext)
  const [teamIndices,setTeamIndices] = useState([])
  const [visibleColumns,setVisibleColumns] = useState({})
  const [columns,setColumns] = useState([])
  const [selected,setSelected] = useState([])
  const [open,setOpen] = useState(false)
  const teamId = useSelector((state) => state.main.teamData.id)
  const newTeamId = useSelector((state) => state.main.teamData.id)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const {data,isLoading} = useTeamIndicesQuery({id:newTeamId, attributes: String(fetchAttrs) , watchlist_include: true,benchmark_include:true})
  const [teamPatchTrigger,{}] = useTeamPatchMutation()
  const [indexPatchTrigger,{}] = useIndexPatchMutation()


  useEffect(() => {(async()=>{
    console.log('run')
    //dispatch({type:'SET_PAGE_TITLE',payload:'TEAM INDICES'})
    //document.title = `Index One | Team Indices`
    //const attrs = ["id","version","created_at","name","calculation_frequency","stage","current_divisor","team_id","stats"]
    //var response = await getTeamIndices(teamId,attrs)
    if(data){
      var parsed = JSON.parse(JSON.stringify(data))
      const {rows,thisColumns,colVis} = parseResponse(parsed)
      setColumns(thisColumns)
      setVisibleColumns(colVis)
      setTeamIndices(rows)
    }
    //const {rows,thisColumns,colVis} = parseResponse(response)
    //setColumns(thisColumns)
    //setVisibleColumns(colVis)
    //setTeamIndices(rows)
    //setIsLoading(false)
  })()},[isLoading])

  const parseResponse = (data) => {
    const usedCols = new Set()
    var colVis = {...baseColVis}
    var thisColumns = [...baseColumns]
    for (let i = 0; i < data.length; i++) {
      var thisIndex = data[i]
      if(thisIndex.stats){
        for (const [statName,statDef] of Object.entries(thisIndex.stats)) {
          for (const [duration,value] of Object.entries(statDef)) {
            var thisValue = value
            var keyName = `${statName}_${duration}`
            if(statName === 'max_drawdown'){
              thisValue = thisValue.value
            }
            data[i][keyName] = thisValue
            colVis[keyName] = false
            if(!usedCols.has(keyName)){
              thisColumns.push({"field":keyName})
              usedCols.add(keyName)
            }
          }
        }
      }
    }
    data = data.sort((a, b) => b.created_at?.localeCompare(a.created_at ?? ''));
    return {
      rows:data,
      thisColumns:thisColumns,
      colVis:colVis
    }
  }

  const deleteDialog = () => {return(
    <Dialog
  open={open}
  onClose={e=>setOpen(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Delete selected indices?"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      The selected indices and their associated data will be permanently deleted.
      {
        selected.map((id)=>(<p><br/>{id}</p>))
      }
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={e=>setOpen(false)} color="primary">
      Go Back
    </Button>
    <Button color='secondary' onClick={e=>{setOpen(false); handleDelete()}} autoFocus>
      Proceed
    </Button>
  </DialogActions>
</Dialog>
)}

  const removeWatchlist = async (id) => {
    //setIsLoading(true)
    try{
      const r = await teamPatchTrigger({"id":teamId,"watchlist_remove":true,"index_id":id})
      dispatch({ type: 'WATCHLIST_REMOVE', payload:id })
      setTeamIndices(JSON.parse(JSON.stringify(teamIndices)).filter(obj => obj.id !== id.slice(0, -2)))
      createNotification({status: 'success',message: "Index removed from watchlist."})
    } catch (error) {
      createNotification({status: 'error',message: "Failed to remove index from watchlist."})
    }
    /*
    api.patch(`teams/${teamId}`,{"watchlist_remove":true,"index_id":id}).then(response => {
      dispatch({ type: 'WATCHLIST_REMOVE', payload:id })
      setTeamIndices(JSON.parse(JSON.stringify(teamIndices)).filter(obj => obj.id !== id.slice(0, -2)))
      //setIsLoading(false)
      createNotification({status: 'success',message: "Index removed from watchlist."})
    }, error => {
      createNotification({status: 'error',message: "Failed to remove index from watchlist."})
      //setIsLoading(false)
    })  
    */
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ allColumns:true,getRowsToExport:getFilteredRows }} />
        <div style={{flex:1}}/>
        <Button color='secondary' size='small' startIcon={<DeleteIcon />} onClick={e=>setOpen(true)} disabled={selected.length == 0}>Delete Selected</Button>
      </GridToolbarContainer>
    );
  }

  const handleDelete = (id_versioned) => {
    var edited = []
    if(id_versioned !== undefined){
      let body = {'stage':'deleting','id':id_versioned}
      const response = indexPatchTrigger(body).unwrap()
      console.log(response)
      edited.push(response)
    }
    else{
      var copy = JSON.parse(JSON.stringify(teamIndices))
      for (let i = 0; i < selected.length; i++) {
        for (let ii = 0; ii < copy.length; ii++) {
          if (copy[ii].id.concat('-',copy[ii].version.toString()) == selected[i]) {
            if (copy[ii].team_id !== teamId){
              alert("Unable to delete index belonging to another team, please remove it from your watchlist instead.")
              return
            }
            copy[ii].stage = 'deleting'
            break
          }
        }
      }
      for (let i = 0; i < selected.length; i++) {
        let body = {'stage':'deleting','id':selected[i]}
        const response = indexPatchTrigger(body).unwrap()
        console.log(response)
      }
      setTeamIndices(copy)
    }
  }

  const baseColVis = {
    team_id:false,
    watchlist:false,
    benchmark:false
  }
  const baseColumns = [
    { field: 'team_id'},
    { field: 'watchlist'},
    { field: 'benchmark'},
    { field: 'id', headerName: 'ID',flex:1 },
    /*{ field: 'version', headerName: 'Version', type: 'number',width:100 },*/
    { field: 'created_at', headerName: 'Created At',flex:1 },
    { field: 'current_divisor', headerName: 'Rebalance',flex:1 },
    { field: 'name', headerName:'Index Name',flex:3 },
    /*
    { field: 'calculation_frequency', headerName: 'Calculation Frequency',width:200 },
    { field: 'start_time', headerName: 'Start Time',width:200 },
    { field: 'end_time', headerName: 'End Time',width:200 },
    */
    { field: 'calculation_frequency', headerName: 'Frequency', width:100 , renderCell: (params) => (
      StageChip(params.row.calculation_frequency)
    )},
    { field: 'stage', headerName: 'Stage', width:80 , renderCell: (params) => (
      StageChip(params.row.stage)
    )},
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <>
          { !params.row.watchlist ?
            <div>
              <Tooltip title="Overview"><IconButton onClick={e=>window.open(`/index/${params.row.id.concat('-',params.row.version.toString())}/overview`, "_blank")}><EqualizerIcon fontSize='small'/></IconButton></Tooltip>
              <Tooltip title="Edit"><IconButton onClick={e=>navigate(`/index/${params.row.id.concat('-',params.row.version.toString())}/edit`)}><EditIcon fontSize='small'/></IconButton></Tooltip>
              <Tooltip title="Index Events"><IconButton onClick={e=>navigate(`/index/${params.row.id.concat('-',params.row.version.toString())}/track`)}><EventIcon fontSize='small'/></IconButton></Tooltip>
              {/*<Tooltip title="Clone"><IconButton onClick={e=>navigate({pathname:'indexbuilder',props:{indexCloneId:params.row.id.concat('-',params.row.version.toString())}})}><ContentCopyIcon fontSize='small'/></IconButton></Tooltip>*/}
              <Tooltip title="Delete"><IconButton onClick={e=>{setSelected([params.row.id.concat('-',params.row.version.toString())]); setOpen(true)}}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
            </div>
            :
            <div>
              <Tooltip title="Overview"><IconButton onClick={e=>window.open(`/index/${params.row.id.concat('-',params.row.version.toString())}/overview`, "_blank")}><EqualizerIcon fontSize='small'/></IconButton></Tooltip>
              <Tooltip title="Index Events"><IconButton onClick={e=>navigate(`/index/${params.row.id.concat('-',params.row.version.toString())}/track`)}><EventIcon fontSize='small'/></IconButton></Tooltip>
              <Tooltip title="Remove from Watchlist"><IconButton onClick={e=>removeWatchlist(params.row.id.concat('-',params.row.version.toString()))}><BookmarkRemoveIcon fontSize='small'/></IconButton></Tooltip>
            </div>
          }
          </>
      )
    }
    /*{
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue('firstName') || ''} ${
          params.getValue('lastName') || ''
        }`,
    },*/
  ]

  return (
    <Container maxWidth={false} disableGutters style={{padding:'2rem', backgroundColor:'#fafafa'}}>
      {deleteDialog()}
      <div style={{ height: '80vh', width: '100%', marginBottom:'1em' }}>
        <DataGrid
          loading={isLoading}
          slots={{toolbar:CustomToolbar}}
          rows={teamIndices}
          columns={columns}
          autoPageSize
          checkboxSelection
          //onSelectionChange={(newSelection) => setSelected(newSelection.rows.map(r=>r.id.concat('-',r.version.toString())))}
          onRowSelectionModelChange={(e) => {
            const selectedIDs = new Set(e);
            const selectedRowData = teamIndices.filter((row) =>
              selectedIDs.has(row.id.toString())
            )
            setSelected(selectedRowData.map(r=>r.id.concat('-',r.version.toString())))
          }}
          columnVisibilityModel={visibleColumns}
          filterModel={{
            items: [{ field: 'team_id', operator: 'isNotEmpty', value: "false" }],
          }}
        />
      </div>
    </Container>
  );
}