import React, {useState, useEffect} from 'react';

import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useExportFactsheetMutation } from '../../store/indexOneApi';

export default function FactsheetExport(props) {
  const dispatch = useDispatch()
  const [loading,setLoading] = useState(false)
  const [url,setUrl] = useState(false)
  const [trigger, {data={}, isLoading}] = useExportFactsheetMutation()

  const handleSubmit = () => {
    trigger({id:props.indexId})
  }

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = data.url;
    link.click();
  }

  return (
    <Container disableGutters>
      <Stack direction='column' spacing={2}>
        <LoadingButton loading={isLoading} disabled={url} fullWidth variant='contained' onClick={e=>handleSubmit()}>Generate Factsheet</LoadingButton>
        <Button disabled={!data.url} fullWidth variant='contained' onClick={e=>downloadFile()}>Download</Button>
      </Stack>
    </Container>
  )
}