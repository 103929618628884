import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack'

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';

function EventCountdownFunc(props){
    const startTime = new Date(props.startTime).getTime()/1000
    const adjTime = new Date(props.adjTime).getTime()/1000
    const rebalTime = new Date(props.rebalTime).getTime()/1000
    const adjSeconds = adjTime-startTime
    const rebalSeconds = rebalTime-startTime

    const [adjRemaining,setAdjRemaining] = useState(null)
    const [rebalRemaining,setRebalRemaining] = useState(null)
    const [elapsed,setElapsed] = useState(null)


    useEffect(() => {
      const interval = setInterval(() => {
        var now = Date.now()/1000
        setElapsed(Math.floor(now-startTime))
        setAdjRemaining(Math.floor(adjTime-now))
        setRebalRemaining(Math.floor(rebalTime-now))
      }, 1000)
      return () => clearInterval(interval)
    }, [props])

    function remainingString(title,remaining){
      var seconds = parseInt(remaining, 10);
      var days = Math.floor(seconds / (3600*24));
      seconds  -= days*3600*24;
      var hrs   = Math.floor(seconds / 3600);
      seconds  -= hrs*3600;
      var mnts = Math.floor(seconds / 60);
      seconds  -= mnts*60;
      return(
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='right'>
            <Typography variant='h6'>{title}</Typography>   
          <Stack direction='column' alignItems='center' spacing={-1}>
            <Typography fontWeight={600}>{String(days).padStart(2,'0')}</Typography>
            <Typography>days</Typography>
          </Stack>
          <Stack direction='column' alignItems='center' spacing={-1}>
            <Typography fontWeight={600}>{String(hrs).padStart(2,'0')}</Typography>
            <Typography>hrs</Typography>
          </Stack>
          <Stack direction='column' alignItems='center' spacing={-1}>
            <Typography fontWeight={600}>{String(mnts).padStart(2,'0')}</Typography>
            <Typography>min</Typography>
          </Stack>
          <Stack direction='column' alignItems='center' spacing={-1}>
            <Typography fontWeight={600}>{String(seconds).padStart(2,'0')}</Typography>
            <Typography>sec</Typography>
          </Stack>
        </Stack>
      )
    }
    
    return(
        <Box sx={{ width: '300px' }}>
          {remainingString("Adjustment",adjRemaining)}
          {remainingString("Rebalance",rebalRemaining)}
          <LinearProgress variant="buffer" value={elapsed/rebalSeconds*100} valueBuffer={elapsed/adjSeconds*100} />
        </Box>
    )
  }
  const comparisonFn = function (prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  }

  const EventCountdown = React.memo(EventCountdownFunc,comparisonFn)
  export default EventCountdown