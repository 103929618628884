import React, { useState, useEffect } from 'react';
import { useRegisterMutation } from '../../store/indexOneApi';
import BookDemo from './BookDemo'

import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'

import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


//dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const industries=[
  "Investment Advisory",
  "Fintech",
  "Asset Management",
  "Wealth Management",
  "ETF Issuer",
  "Banking",
  "Academia"

]

const useCases = [
  "Benchmarking",
  "Index Replication",
  "Research",
  "Software Development",
  "Trading",
  "Shadow Calculation"
]

const heardAboutOptions = [
  "Social media",
  "Email",
  "Blog or publication",
  "Search engine",
  "Colleague",
  "Website"
]

export default function ContactForm(props) {
  const [errors, setErrors] = useState({})


  const [first_name, setFirstName] = useState()
  const [last_name, setLastName] = useState()
  const [reply_to, setReplyTo] = useState()
  const [body, setBody] = useState()
  const [industry, setIndustry] = useState('')
  const [orgName, setOrgName] = useState('')
  const [useCase, setUseCase] = useState('')
  const [heardAbout,setHeardAbout] = useState('')
  const [marketing, setMarketing] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [activeTab,changeActiveTab] = useState('1')
  const [sendContactRequest, { isLoading }] = useRegisterMutation()

  const isLanding = window.location.pathname === '/contact' ? true : false
 
  const handleSubmit = async () => {
    /*const templateParams = {
      first_name: first_name,
      last_name: last_name,
      reply_to: reply_to,
      body: body
    }
    emailjs.send('gmail', 'careers', templateParams, 'user_xx1aJ4dTsUPz3iiUpY7W5')
      .then((response) => {
        console.log(response)
      }, (e) => {
        console.log(e);
        alert('Failed to submit contact request, please get in touch via contact@indexone.io')
      })*/
    let e = false
    if(!first_name){
      setErrors(p=>({...p,first_name:'First name is required.'}))
      e=true
    } else{
      setErrors(p=>({...p,first_name:undefined}))
    }
    if(!reply_to){
      setErrors(p=>({...p,reply_to:'Email is required.'}))
      e=true
    } else{
      setErrors(p=>({...p,reply_to:undefined}))
    }
    if(!last_name){
      setErrors(p=>({...p,last_name:'Last name is required.'}))
      e=true
    } else{
      setErrors(p=>({...p,last_name:undefined}))
    }
    if(!orgName){
      setErrors(p=>({...p,orgName:'Company name is required.'}))
      e=true
    } else{
      setErrors(p=>({...p,orgName:undefined}))
    }
    if(!body){
      setErrors(p=>({...p,body:'Message is required.'}))
      e=true
    } else{
      setErrors(p=>({...p,body:undefined}))
    }

    if (e===false){
      const hubspotObject = {
          hubspot_only:true,
          company:orgName,
          email:reply_to,
          message:body,
          firstname:first_name,
          lastname:last_name,
          use_case:useCase,
          industry:industry,
          marketing_email_optin: marketing,
          heard_about:heardAbout
      }
      try{
        const contactResponse = await sendContactRequest(hubspotObject)
        setConfirmDialogOpen(true)
      }catch(e){
        console.log(e)
      }
    }
  }

  const returnConfirmationDialog = () => {
    return(
      <Dialog
        open={confirmDialogOpen}
        onClose={e => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Thank you for reaching out.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Our team will respond to your query within the next 2 working days.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={e=>setConfirmDialogOpen(false)}>Proceed</LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }


  const returnFreeSolo = (state, setFunc, label, options) => {
    return(
      <Autocomplete
            id={label}
            freeSolo
            fullWidth
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={(e,value)=>setFunc(value)}
            onInputChange={(event, newInputValue) => {
              setFunc(newInputValue);
            }}
            required
          />

    )
  }

  return (
    <form>
      <Container maxWidth='sm'>
      {returnConfirmationDialog()}
      <Stack justifyContent='center' alignItems='center' sx={{ backgroundColor:'#fafafa',padding:'0em',margin:'0em', borderRadius:'10px'}} spacing={0}>
        <TabContext value={activeTab}>
          <TabList onChange={(e,v) => changeActiveTab(v)} aria-label="basic tabs example" variant='fullWidth' sx={{width:'100%',padding:'1em', paddingBottom:'0px', marginBottom:'0px'}}>
            <Tab label="contact us" value='1' />
            <Tab label="book a demo" value='2' />
          </TabList>
          <TabPanel value='1'>
          <Stack justifyContent='center' alignItems='center' spacing={2} sx={{marginTop:'0px',paddingTop:'0px'}}>
            <Stack direction='row' alignItems='baseline' justifyContent='center' spacing={2} width='100%' sx={{padding:0,margin:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={errors.first_name}
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  autoComplete="first_name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={first_name}
                  sx={{margin:0}}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={errors.last_name}
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="last_name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={last_name}
                  sx={{margin:0}}
                />
            </Stack>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={errors.reply_to}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setReplyTo(e.target.value)}
              value={reply_to}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={errors.orgName}
              id="orgName"
              label="Organization Name"
              name="orgName"
              autoComplete="company"
              onChange={(e) => setOrgName(e.target.value)}
              value={orgName}
            />
            {returnFreeSolo(industry,setIndustry,'Industry',industries)}
            {returnFreeSolo(useCase,setUseCase,'Use Case',useCases)}
            {returnFreeSolo(heardAbout,setHeardAbout,'How did you hear about us?',heardAboutOptions)}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={errors.body}
              name="body"
              label="Request Body"
              multiline
              rows={4}
              id="body"
              onChange={(e) => setBody(e.target.value)}
              value={body}
            />
            <FormControlLabel control={<Checkbox checked={marketing} onChange={e=>setMarketing(e.target.checked)}/>} label="I would like to receive email marketing from Index One" />
            <LoadingButton
              loading={isLoading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={()=>handleSubmit()}
              size='large'
            >
              Submit
            </LoadingButton>
            </Stack>
      </TabPanel>
      <TabPanel value='2'>
        <BookDemo/>
      </TabPanel>
      </TabContext>
      </Stack>
    </Container>
    </form>
  );
}