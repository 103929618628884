import React from 'react'
import Chip from '@mui/material/Chip'

import CircularProgress from '@mui/material/CircularProgress'
import CheckIcon from '@mui/icons-material/Check'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function stageChip(stage){
    if(typeof stage === 'string'){
        if (stage.includes('*')){stage = 'EOD'}
        let colo
        switch(stage) {
            case "pending":
              colo = '#fff3cd'; // Color for pending
              break;
            case "live":
              colo = '#d4edda'; // Color for processing
              break;
            case "processing":
              colo = '#cce5ff'; // Color for deleted
              break;
            case "failed":
                colo = '#f8d7da'
                break;
            case "deleting":
                colo = '#f8d7da'
                break;
            case "completed":
                colo = '#4ecdc4'
                break;
            default:
              colo = '#d4edda'; // Optional: Default color for any other stages
          }
        return(
            <Chip
                size='small'
                label={stage}
                color={
                    stage === 'live' ? 'primary'
                    : stage === 'failed' ? 'secondary'
                    : stage === 'deleting' ? 'secondary'
                    : 'default'
                }
                style={{backgroundColor:colo}}
                icon={
                    stage ==='processing' ? <CircularProgress size='1em' thickness={5} color="secondary"/>
                    : stage === 'live' ? <FiberManualRecordIcon style={{ color: '#4ecdc4' }} />
                    : stage === 'realtime' ? <FiberManualRecordIcon style={{ color: '#4ecdc4' }} />
                    : stage ==='deleting' ? <CircularProgress size='1em' thickness={5} color="secondary"/>
                    : stage === 'completed' ? <CheckIcon/>
                    : ''
                }
            
            
            />
        )
    } else {
        return(<></>)
    }
}