import React from 'react'
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  parent: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    backdropFilter:'blur(5px)',
    zIndex:999
  },
  child:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    textAlign: 'center',
    color:'white'
  }
})

export default function LoadingScreen() {
  const classes = useStyles()
  return(
  <div className={classes.parent}>
    <div className={classes.child}>
      <CircularProgress/>
    </div>
  </div>
  )
}