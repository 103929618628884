import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import attributeOptions from './attributeOptions.json'

import { useTeamQuery } from "../../../store/indexOneApi";
import { Container, Stack, IconButton } from '@mui/material'
import Grid from "@mui/material/Grid2"
import Snackbar from '@mui/material/Snackbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

//radio
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';

//tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//inputs
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';

//icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

//imports
import FileUpload from './FileUpload'

const constraints = [
  {
    label:'Maximum Weight',
    value:'max_weight_constituent'
  },
  {
    label:'Minimum Weight',
    value:'min_weight_constituent'
  }
]

export default function Weighting(props) {
  const [weighitngHistorical,setWeightingHistorical] = useState({})
  const [weightingHistoricalUploadShow,setWeightingHistoricalUploadShow] = useState(false)
  const [dialogOpen,setDialogOpen] = useState(false)
  const [snackbarOpen,setSnackbarOpen] = useState(false)
  const [snackbarMessage,setSnackbarMessage] = useState('')
  //const {data:teamData = {files:{}}} = useTeamQuery({"id":props.teamId})
  const teamData = useSelector(state => state.main.teamData)

  useEffect(() => {(async()=>{
    /*
    if(props.weightingObjective.type == 'custom_code'){
      var response = await getTeamFiles(props.teamId)
      if('files' in response){
        setFiles(response.files)
      }
    }
    */
  })()},[props.weightingObjective.type])

  const returnDialog = () => {
    return (
      (<Dialog
        onClose={e=>setDialogOpen(false)}
        open={dialogOpen}
        maxWidth={'md'}
        fullWidth
      >
        <DialogTitle>
          Upload Custom Universe
          <IconButton
          aria-label="close"
          onClick={e=>setDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Allowed file extensions: .CSV
            <br/>
            All date formats in YYYY-MM-DD
            <br/>
            No column headers except for those specified in the samples below
          </Alert>
          <Grid container spacing={3} sx={{paddingTop:'1em'}} alignItems='stretch'>
            <Grid size={6}>
              <FileUpload setWeighting={updateWeightingObj} type='panel'/>
            </Grid>
            <Grid size={6}>
              <FileUpload setWeighting={updateWeightingObj} type='basket'/>
            </Grid>
            <Grid size={6}>
              <FileUpload setWeighting={updateWeightingObj} type='basket_unweighted'/>
            </Grid>
            <Grid size={6}>
              <FileUpload setWeighting={updateWeightingObj} type='fixed'/>
            </Grid>
            <Grid size={6}>
              <FileUpload setWeighting={updateWeightingObj} type='fixed_unweighted'/>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>)
    );
  }

  const pushConstraint = (idx=props.weightingConstraints.length,filterType='max_weight_constituent') => {
    let thisFilter = {type:filterType}
    let newFilters = Array.from(props.filters)
    thisFilter.id = props.weightingConstraints.length.toString()
    newFilters.splice(idx,0,thisFilter)
    props.setWeightingConstraints(newFilters)
  }

  const updateConstraint = (idx,attr,value) => {
    let newCons = Array.from(props.weightingConstraints)
    newCons[idx][attr] = value
    props.setWeightingConstraints(newCons)
  }

  const deleteConstraint = (idx) => {
    let newFilters = Array.from(props.weightingConstraints)
    newFilters.splice(idx,1)
    props.setWeightingConstraints(newFilters)
  }

  const setHistoricalWeightings = (data) => {
    props.setWeightingDefinedHistorical(data)
    var uniObj = {}
    for (const [key, value] of Object.entries(data)) {
      uniObj[key] = Object.keys(value)
    }
    props.setUniverseDefinedHistorical(uniObj)
    props.setWeightingHistoricalTab(Object.keys(data)[0])
  }

  const updateWeightingObj = (type,data) => {
    if(type == 'panel'){type = 'basket'}
    if(type.includes('unweighted')){
      props.setWeightingObjective(p => ({...p,universe:data}))
      props.setWeightingObjective(p => ({...p,type:'equal'}))
    } else {
      const wObj = {
        type:type,
        value:data
      }
      props.setWeightingObjective(wObj)
      //props.setWeightingObjective(p => ({...p,value:data}))
    }
    if(["basket","panel"].includes(type)){
      props.setWeightingHistoricalTab(Object.keys(data)[0])
    }
    props.setWeightingType(type)
    setSnackbarMessage('File upload successful.')
    setSnackbarOpen(true)
    setDialogOpen(false)
  }

  function returnAttributeOptions(type){
    if(attributeOptions.hasOwnProperty(type)){
      return(
        <Stack direction='column' spacing={1}>
          {JSON.stringify(attributeOptions)}
          asdf
        </Stack>
      )
    } else {
      return(null)
    }
  }

  return (
    (<Grid container spacing={4}>
      {returnDialog()}
      <Snackbar open={snackbarOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={6000} message={snackbarMessage}/>
      <Grid size={4}>
      <Stack direction='column' spacing={2}>
      <FormControlLabel control={<Switch checked={props.customUniverse} onChange={e=>props.setCustomUniverse(e.target.checked)}/>} label="Use Custom Universe" />
      <FormControl>
        <Typography id="demo-row-radio-buttons-group-label">Rebalancing Objective</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={props.weightingObjective.type}
          onChange={e=>props.setWeightingObjective(p => ({...p,type:e.target.value}))}
        >
          <FormControlLabel value="equal" control={<Radio />} label="Equal" />
          <Tooltip title="Weight index by an attribute, e.g. market cap"><FormControlLabel value="proportional" control={<Radio />} label="Proportional" /></Tooltip>
          <Tooltip title="Upload historical weightings for selected time periods."><FormControlLabel value="basket" control={<Radio />} label="Basket" /></Tooltip>
          <FormControlLabel value="fixed" control={<Radio />} label="Fixed"/>
          <FormControlLabel value="custom_code" control={<Radio />} label="Custom Code" />
        </RadioGroup>
      </FormControl>
      {
        props.weightingObjective.type == 'proportional' ?
          <Stack direction='column' spacing={1}>
            <TextField
              id="outlined-select-attribute"
              select
              size='small'
              label="Attribute"
              defaultValue=""
              value={props.weightingObjective.attribute}
              onChange={e=>props.setWeightingObjective(p => ({...p,attribute:e.target.value}))}
            >
              {Object.entries(props.attributeConfig).map(([key, value]) =>{
                if(value.type == 'numeric'){
                  return(
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  )
                }
              })}
            </TextField>
            {/*returnAttributeOptions(props.weightingObjective.attribute)*/}
          </Stack>
        : props.weightingObjective.type == 'custom_code' ?
          <Stack direction='row'>
            <TextField
              id="outlined-select-key-custom"
              select
              size='small'
              label="File Name"
              value={props.weightingObjective.key ?? " "}
              //defaultValue={props.weightingObjective.key}
              onChange={e=>props.setWeightingObjective(p => ({...p,key:e.target.value}))}
            >
              {Object.keys(teamData.files).map((fileName) =>{
                  return(
                    <MenuItem key={fileName} value={fileName}>
                      {fileName}
                    </MenuItem>
                  )
              })}
            </TextField>
            <Button onClick={e=> props.openDialog('Custom Code','custom_code')}>Edit Code</Button>
          </Stack>
        : ""
      }

      {props.customUniverse && <Button onClick={e=>setDialogOpen(true)} variant='outlined'>Upload Universe</Button>}
      
      </Stack>
      </Grid>
      <Grid size={4}>
        <Tooltip title='Add constraints to the weighting algorithm'>
        <Typography>Weighting Constraints</Typography>
        </Tooltip>
        <Stack direction='column' spacing={2} sx={{marginTop:'5px',marginBottom:'15px'}}>
        {props.weightingConstraints.map((con,idx)=>
          <Paper sx={{padding:'1em'}}>
            <Stack direction='row' alignItems='center' spacing={1}>
            {
              ['max_weight_constituent','min_weight_constituent'].includes(con.type) ?
              <>
              <Typography>{con.type == 'max_weight_constituent' ? 'Maximum Weight' : 'Minimum Weight'}</Typography>
              <TextField
              id="outlined-constraint-value"
              label="Constraint Value"
              type='number'
              size='small'
              value={props.weightingConstraints[idx].value*100}
              onChange={e=>updateConstraint(idx,'value',e.target.value/100)}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }
              }}
              />
              </>
              :''
            }
            <div style={{flexGrow:1}}/>
            <IconButton size='small' onClick={e=>deleteConstraint(idx)} aria-label="delete">
              <DeleteOutlineIcon fontSize='small' color='secondary' />
            </IconButton>
            </Stack>
          </Paper>
        )}
        </Stack>
        <Stack direction='row' spacing={1} alignItems='center'>
        <TextField
            id="outlined-select-constraint"
            select
            defaultValue='max_weight_constituent'
            sx={{width:'100%'}}
            size='small'
            label="Add Constraint"
            //value={currency}
            onChange={e=>props.setWeightingConstraints(oldArray => [...oldArray,{type:e.target.value}] )}
          >
            {constraints.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        </Stack>
      </Grid>
      <Grid direction='column' size={4}>
        {/*WEIGHTING TABLE*/}

        { !props.customUniverse ?
          <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
            <Table aria-label="a dense table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width:30}}></TableCell>
                  <TableCell>Security</TableCell>
                  <TableCell align="right">Weight (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Object.entries(props.weighting).map(([key, value]) =>
                <TableRow
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{width:30}}>
                    <Avatar sx={{ width: 24, height: 24 }} alt={key} src={`https://storage.googleapis.com/iex/api/logos/${key}.png`} />
                  </TableCell>
                  <TableCell component="th" scope="row" align='left'>
                    {key}
                  </TableCell>
                  <TableCell align="right">{(value*100).toFixed(2)}</TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
        : props.weightingType == 'basket' ?
        <>
        <Stack alignItems='center' justifyContent='center' direction='row' sx={{width:'100%'}}>
          <Tabs
            sx={{width:'40vw'}}
            value={props.weightingHistoricalTab}
            onChange={(e,v)=>props.setWeightingHistoricalTab(v)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
          {Object.keys(props.weightingObjective.value).map((key)=>(
            <Tab label={key} value={key}/>
          ))}
          </Tabs>
        </Stack>
        <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Ticker</TableCell>
                <TableCell align="right">Weight (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.weightingObjective.value.hasOwnProperty(props.weightingHistoricalTab) &&
                Object.entries(props.weightingObjective.value[props.weightingHistoricalTab]).map((ticker,weight) => (
                <TableRow
                  key={ticker}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {ticker[0]}
                  </TableCell>
                  <TableCell align="right">{(ticker[1]*100).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>

        : props.weightingType == 'basket_unweighted' ?
        <>
        <Stack alignItems='center' justifyContent='center' direction='row' sx={{width:'100%'}}>
          <Tabs
            sx={{width:'40vw'}}
            value={props.weightingHistoricalTab}
            onChange={(e,v)=>props.setWeightingHistoricalTab(v)}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
          {Object.keys(props.weightingObjective.universe).map((key)=>(
            <Tab label={key} value={key}/>
          ))}
          </Tabs>
        </Stack>
        <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Ticker</TableCell>
                <TableCell align="right">Weight (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.weightingObjective.universe.hasOwnProperty(props.weightingHistoricalTab) &&
                props.weightingObjective.universe[props.weightingHistoricalTab].map((ticker) => (
                <TableRow
                  key={ticker}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {ticker}
                  </TableCell>
                  <TableCell align="right">NaN</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>

        : props.weightingObjective.type == 'fixed' ? 
        <>
          <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
            <Table aria-label="a dense table" size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Security</TableCell>
                  <TableCell align="right">Weight (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {Object.entries(props.weighting).map(([key, value]) =>
                <TableRow
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      size='small'
                      type='number'
                      value={props.weightingFixed[key]}
                      onChange={e => props.setWeightingFixed(p => ({ ...p, [key]: parseFloat(e.target.value) }))}
                      slotProps={{
                        input: {
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography>
            Sum weighting: 
            {Object.values(props.weightingFixed).reduce((a, b) => a + b, 0)}
            %
          </Typography>
          </>
        :
        <Paper sx={{height:'60vh'}} elevation={2}>
          <Stack direction='column' spacing={2} sx={{height:'100%'}} alignItems='center' justifyContent='center'>
            <Typography variant='body2'>No universe has been specified.</Typography>
            <Button onClick={e=>setDialogOpen(true)} variant='outlined'>Upload Universe</Button>
          </Stack>
        </Paper>
        }
      </Grid>
    </Grid>)
  );
}