
import BusinessIcon from '@mui/icons-material/Business';
import logo from '../../images/logo.png'
export default function ReturnLogo(props) {
    var w = '30px'
    if(props.width){
        w = props.width
    }
    if (`https://storage.googleapis.com/iex/api/logos/${props.symbol}.png`){
        return(
            <div style={{
                height: w,
                width: w,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius:'50%'
            }}>
            <img
                src={`https://storage.googleapis.com/iex/api/logos/${props.symbol}.png`}
                onError={(e)=>{e.target.onerror = null; e.target.src=logo}}
                style={{
                    maxHeight:'100%',
                    maxWidth:'100%'
                }}
            />
            </div>
            )
    } else {
        return(
        <BusinessIcon/>
        )
    }
  }