import React, { useState, useEffect } from 'react';
import { Container,Box,LinearProgress,Button,Alert,Link,AlertTitle,Stack,TextField,MenuItem } from '@mui/material';
import Editor from '@monaco-editor/react';


import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function CustomCode(props) {

  return (
    <Container maxWidth={false} sx={{height:'100%',padding:'0em'}} disableGutters>
        <Editor
            height="100%"
            width='100%'
            defaultLanguage="python"
            defaultValue="#comment"
            theme="vs-dark"
        />
    </Container>
  );
}