import { createSlice } from '@reduxjs/toolkit'
import { indexOneApi } from './indexOneApi'



const initialState = {
    authenticating : localStorage.getItem('authToken') ? false : true,
    authenticated : localStorage.getItem('authToken') ? true : false,
    authToken : localStorage.getItem('authToken'),
    refreshToken : localStorage.getItem('authToken'),
    userData : JSON.parse(localStorage.getItem('userData')),
    teamData : JSON.parse(localStorage.getItem('teamData')),
    pageTitle : '',
    desktopMenuOpen : false,
    watchlist : localStorage.getItem('teamData') ? Object.keys(JSON.parse(localStorage.getItem('teamData')).watchlist ?? {}) : [],
    files:{}
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      console.log(state)
      console.log(action)
      state.authToken = action.payload
      state.authenticating = false
      state.authenticated = true
      localStorage.setItem("authToken", action.payload)
    },
    logOut: (state) => {
      localStorage.clear()
      state.authenticating = false
      state.authenticated = false
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
        indexOneApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          localStorage.setItem("authToken", payload.id_token)
          localStorage.setItem("refreshToken", payload.refresh_token)
          state.authenticating = false
          state.authenticated = true
          state.authToken = payload.id_token
          state.refreshToken = payload.refresh_token
        },
    )
    builder.addMatcher(
      indexOneApi.endpoints.self.matchFulfilled,
      (state, { payload }) => {
          if(payload.team_data){
            state.teamData = payload.team_data
            localStorage.setItem("teamData",JSON.stringify(payload.team_data))
            delete payload.team_data
          }
          state.userData = payload
          localStorage.setItem("userData",JSON.stringify(payload))
      },
    )
    builder.addMatcher(
      indexOneApi.endpoints.team.matchFulfilled,
      (state, { payload }) => {
          console.log("TEAM GET INTERCEPT")
          console.log(payload)
          state.teamData = payload
          localStorage.setItem("teamData",JSON.stringify(payload))
      },
    )
    builder.addMatcher(
      indexOneApi.endpoints.teamPatch.matchFulfilled,
      (state, { payload }) => {
          console.log("TEAM PATCH INTERCEPT")
          console.log(payload)
          if(payload){
            if(payload.status == 200){
              if(payload.data){state.teamData = payload.data}
              localStorage.setItem("teamData",JSON.stringify(payload.data))
            }
          }
      },
    )
    builder.addMatcher(
      indexOneApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
          state.authToken = payload.id_token
          localStorage.setItem("authToken", payload.id_token)
      },
    )
  }
})

// Action creators are generated for each case reducer function
export const {
  setAuthToken,
  logOut,
  increment,
  decrement,
  incrementByAmount
} = mainSlice.actions

export default mainSlice.reducer