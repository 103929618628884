import React, { useState, useEffect } from 'react';
import { Box, Card, CardMedia, Typography } from '@mui/material';
import backtest from '../../../src/images/pic1.png';
import calc from '../../../src/images/pic2.png';
import maintain from '../../../src/images/pic3.png';
import report from '../../../src/images/pic4.png';

const images = [
    { image: backtest, alt: 'Unlimited Backtesting' },
    { image: calc, alt: 'Live Index Calculation' },
    { image: maintain, alt: 'Automated Index Maintenance' },
    { image: report, alt: 'Delivery and Reporting' },
];

const ImageGallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // 3 seconds interval

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {/* Carousel with fixed aspect ratio and faded borders */}
            <Box 
                sx={{
                    width: '100%', 
                    maxWidth: '1920px', 
                    height: 'auto', 
                    position: 'relative', 
                    paddingBottom: '56.25%', 
                    overflow: 'hidden', 
                    borderRadius: '12px', // Slight border radius for smooth edges
                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)', // Soft shadow to enhance fade effect
                }}
            >
                {/* Fading overlay effect */}
                <Box 
                    sx={{
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        right: 0, 
                        bottom: 0,
                        pointerEvents: 'none', // Disable interaction with the overlay
                        background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.2) 100%)',
                        zIndex: 1, // Ensures the overlay is on top of the image
                    }}
                />
                <Card sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                    <CardMedia
                        component="img"
                        image={images[currentIndex].image}
                        alt={images[currentIndex].alt}
                        sx={{ width: '100%', height: '100%', objectFit: 'contain' }} // Use objectFit 'contain' to make sure full image is visible
                    />
                </Card>
            </Box>
            {/* Caption */}
            <Typography
                variant="body2"
                style={{ fontSize: 10, marginTop: '8px', color: 'white' }} // Caption text color set to white
                align="center"
            >
                {images[currentIndex].alt}
            </Typography>
        </Box>
    );
};

export default ImageGallery;
