// in notification-context.js
import React, { useState, useRef } from 'react'

//warning: orange
//error: red
//success: green
//info: blue

// ... Our notification component
// in notification-context.js
import { Snackbar,Alert } from '@mui/material'

function Notification({ message, status, open, handleClose, handleExited }) {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={open}
      autoHideDuration={3500} //calls onClose after 3500ms (3.5secs)
      onClose={handleClose}
      onExited={handleExited}
    >
      <Alert severity={status}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export const NotificationContext = React.createContext()

export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [messageData, setMessageData] = useState(undefined)
  const queueRef = useRef([])

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  function processQueue() {
    if (queueRef.current.length > 0) {
      setMessageData(queueRef.current.shift())
      setOpen(true)
    }
  }

  function handleExited() {
    processQueue()
  }

  function createNotification(notification) {
    queueRef.current.push(notification)

    if (open) {
      // If it is already open, close it, calls handleExited.
      setOpen(false)
    } else {
      // If not opened already, process your notification.
      processQueue()
    }
  }

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      {children}
      <Notification
        {...messageData}
        open={open}
        handleClose={handleClose}
        handleExited={handleExited}
      />
    </NotificationContext.Provider>
  )
}