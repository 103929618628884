import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

const dataSample = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: -3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: -2000,
    pv: -9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: -1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: -3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{padding:'5px'}}>
        <Typography variant='body1Heavy'>{payload[0].payload.name}</Typography>
        <Typography>return: {(payload[0].payload.return*100).toFixed(2)}%</Typography>
      </Paper>
    );
  }
  return null;
};

function toPct(f){
  return((f*100).toFixed(2))
}

export default function Tornado(props){
  const [data,setData] = useState([])
  const [data2,setData2] = useState([])
  const [botTop,setBotTop] = useState(['',''])
  const [showLines,setShowLines] = useState([])
  const [loading,setLoading] = useState(true)
  

  useEffect(() => {(async()=>{
    if(Object.keys(props.data).length == 0 ){return}
    var dataNew = []
    for (const [key, value] of Object.entries(props.data)) {
      try{
        dataNew.push({name:value.name,return:value.stats.returns["30d"]})
      }catch{
        continue
      }
    }
    dataNew.sort((a,b)=>b.return - a.return)
    setData(dataNew)
    setLoading(false)
  })()},[props])

  if(loading){return <Skeleton variant='rectangular' height='200px'/>}
  return (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
          layout='vertical'
          width={500}
          height={200}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: -5,
          }}
          style={{fontSize:'12px'}}
        >
          <XAxis domain={["auto","auto"]} unit=" %" type="number" dataKey="return" name="return" tickFormatter={toPct} axisLine={false} padding={{"top":500}}/>
          <CartesianGrid strokeDasharray="3 3" horizontal={false}/>
          <YAxis type="category" dataKey="name" hide/>
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
          <Bar dataKey="return" fill="#8884d8" barSize={7}>
            {data.map((d,i)=>(
              <Cell key={i} fill={d.return > 0 ? "#4ecdc4":"#fc4798"}/>
            ))}
          </Bar>
        </BarChart>
    </ResponsiveContainer>
  );
}
