import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container'


//images_old
import indices from '../../images/indices.PNG'
import index from '../../images/index.PNG'
import weighting from '../../images/weighting.png'
import corporateactions from '../../images/index_corporateactions.png'
import distribution from '../../images/distribution.svg'

//images
import backtest from '../../../src/images/backtest.png'
import custom_indexing from '../../../src/images/custom_indexing.png'
import delivery from '../../../src/images/delivery.png'
import integrations from '../../../src/images/integrations.png'
import market_indices from '../../../src/images/market_indices.png'
import rebalance from '../../../src/images/rebalance.png'



const steps = [
  {
    label: 'Index Design and Backtesting',
    description:'Index One provides boundless backtesting capabilities, accommodating up to 15 years of historical data. Users can smoothly transition their index backtests into either live real-time or end-of-day (EOD) indices.',
    image: backtest
  },
  {
    label: 'Custom Index Calculation',
    description: `With Index One, transform any strategy into a dynamic live index, offering flexibility in adjusting parameters such as Backtest Duration, Reconstitution Frequency, and Corporate Action Management.`,
    image: custom_indexing
  },
  {
    label: 'Rebalancing and Corporate Actions',
    description: `Index One streamlines index adjustments by automating the rebalancing process and managing corporate actions. Beyond its platform capabilities, Index One boasts a specialized team dedicated to handling index adjustments efficiently.`,
    image: rebalance
  },
  {
    label: 'Data Delivery',
    description: `Index One's platform facilitates straightforward delivery of index data via API, Webhook, SFTP, and email channels.`,
    image: delivery
  },
  {
    label: 'Index Integrations',
    description: `
    Incorporate your index seamlessly onto any platform using Index One's streamlined integration choices, which include API, SFTP, and iframes.`,
    image: integrations
  }
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const stepperRef = React.useRef(null);
  

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      navigate('/solutions');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    window.scrollTo({behavior: 'smooth' });
  };

  const useStyles = makeStyles((theme) => ({
    root: { 
      flexGrow: 1,
      width: '100%',
      justifyContent:'center'
    },
    screen:{
      color:'#353b41',
      boxShadow:'10px 10px 27px 0px',
      borderRadius:10,
      width:'100%'
    },
    scroller:{
      flexGrow:'0'
    },
      
    grid:{
        display: 'grid',
        width: '100%',
        justifyContent: 'end'
    }
    
    
  }));

const classes = useStyles();

const navigate = useNavigate();
 
const scrollStepper = () => {
    if (stepperRef.current) {
      const stepHeight = stepperRef.current.children[activeStep].offsetTop;
      stepperRef.current.scrollTo({
        top: stepHeight,
        behavior: 'smooth',
      });
    }
  };
  


  return (
    <Container>

    <Box sx={{ display: 'flex', alignItems: 'left',
    width: '900px',
    }}>
      <Stepper ref={stepperRef} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography variant='h4' color= '#fafafa'>{step.label}</Typography>
            </StepLabel>
           
            <StepContent>
            <Box sx={{ display: 'flex', alignItems: 'right' }}>
              <div style={{ marginRight: '10px' }}>
              <Typography>{step.description}</Typography>
              <br/>
              <Button onClick={e => navigate(`/indexbuilder`)} sx={{ mt: 1, mr: 1 }} variant="outlined" size="large">Get started</Button>
              </div>
              <br/>
                    {activeStep === index && <img className={classes.screen} src={step.image} alt={`Step ${index + 1}`}  margin='100px' style={{ maxHeight: '500px', maxWidth: '500px' }}/>}
                    </Box>  
                    
                   
                  
              
<br/><br/>


<Box sx={{ mt: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {activeStep === steps.length - 1 ? 'View all solutions' : 'Continue'}
                    </Button>
                    {activeStep !== 0 && (
                      <Button
                        
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Container>
  );
}