import React, {useState, useEffect} from 'react';
import { useSearchParams,useParams } from "react-router-dom"
import { useIndexWeightingQuery } from '../../store/indexOneApi';
import { BarChart, Bar, PieChart, Pie, Cell, Legend, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import getParamsJoined from '../helpers/paramHelper'
import {returnLongestLabel,truncateLabels} from '../helpers/chartHelper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function WeightingChart(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const p = useParams()
    var params = getParamsJoined(['indexId','attribute','colors','category','chartType','minWidth','minHeight','sliceLabel','showLegend','limit'],props,searchParams,useParams())
    for(const p of ['minWidth','minHeight']){
        if(!params[p]){
            params[p] = 320
        }
    }
    const {data,isLoading,isError} = useIndexWeightingQuery({...params,...{id:params.indexId}})
    const [weightingData,setWeightingData] = useState([])
    //const [data, setData] = useState([])
    const [longestLabel, setLongestLabel] = useState(10)
    const [colors,setColors] = useState(['#4ecdc4'])
    useEffect(() => {(async()=>{
        if(isLoading){return}
        let array_data = Object.entries(data).map((e)=>({label:e[0],value:e[1]}))
        array_data.sort((b,a) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

        if(params.limit){
            array_data = array_data.slice(0,parseInt(params.limit))
        }
        setWeightingData(array_data)
        array_data = truncateLabels(array_data)
        setLongestLabel(returnLongestLabel(array_data))
        if (params.colors){
            setColors(params.colors)
        }
    })()},[isLoading])

    const RADIAN = Math.PI / 180
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
        <>
            <rect x={x-17} y={y-10} width="36" height="19" fill="#4c4e56" rx={5} dominantBaseline="central"></rect>
          <text x={x} y={y} font-weight="bold" fill="white" textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)} %`}
          </text>
        </>
        );
      };

    return(
        <ResponsiveContainer  minWidth={parseInt(params.minWidth)} minHeight={parseInt(params.minHeight)}>
            { 
            isLoading ? 
            <Stack>
            {['95%','90%','85%','80%','75%','70%','65%','60%','55%','50%'].map((w) => (
               <Skeleton variant='text' width={w} height='100%'></Skeleton>  
            ))}
            </Stack>
            : params.chartType == 'donut' ?
                <PieChart data={weightingData}>
                    <Pie
                        data={weightingData}
                        dataKey="value"
                        nameKey="label"
                        startAngle={450}
                        endAngle={90}
                        paddingAngle={0}
                        innerRadius={'40%'}
                        outerRadius={'80%'}
                        cx="50%"
                        cy="50%"
                        label={ params.sliceLabel ? renderCustomizedLabel : (e) => e.label}
                        labelLine={ !params.sliceLabel }
                        legendType={'square'}
                        >
                        {
          	                weightingData.map((entry, index) => (<Cell key={index} fill={colors[index % colors.length]}/>))
                        }
                    </Pie>
                    {params.showLegend && <Legend layout='vertical'/>}
                    <Tooltip formatter={(value) => (value*100).toFixed(2) + '%'} />
                </PieChart>
            :
                <BarChart data={weightingData} layout='vertical'>
                    <XAxis type="number" hide={true}/>
                    <YAxis type="category" dataKey="label" width={(longestLabel+7)*5} interval={0}/>
                    <Tooltip formatter={(value) => `${(value*100).toFixed(2)} %`}/>
                    <Bar dataKey="value" /*fill="#4ecdc4"*/ fill={colors[0]} />
                </BarChart>
            }
        </ResponsiveContainer>
    )
  }