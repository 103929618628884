import React, { useEffect,useState } from 'react';
import { LineChart, Line, ResponsiveContainer, YAxis} from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 0,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 100,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: -200,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: -100,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: -100,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 120,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 300,
    amt: 2100,
  },
];

export default function Sparkline(props) {
    const [colorBreakPointPercentage,setColorBreakPointPercentage] = useState("50%")

    const gradId = Math.random().toString()

    useEffect(() => {(async()=>{
      if(!props.data){return}
      if(props.data.length === 0){return}
      const { min, max } = props.data.reduce((result, dataPoint) => ({
        min: (dataPoint.value < result.min || result.min === 0) ? dataPoint.value : result.min,
        max: (dataPoint.value > result.max || result.max === 0) ? dataPoint.value : result.max,
      }), { min: 0, max: 0 });
      setColorBreakPointPercentage(`${parseInt((1 - ((props.data[0].value - min) / (max - min))) * 100)}%`)
    })()},[props])
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={props.data}>
            <YAxis domain={['dataMin', 'dataMax']} hide/>
            <defs>
              <linearGradient id={gradId} x1="0" y1="0" x2="0" y2="100%">
                  <stop offset="0%" stopColor='#4ecdc4' />
                  <stop offset={colorBreakPointPercentage} stopColor='#4ecdc4' />
                  <stop offset={colorBreakPointPercentage} stopColor='#fc4798' />
                  <stop offset="100%" stopColor='#fc4798' />
              </linearGradient>
            </defs>
          <Line dot={false} type="monotone" dataKey="value" stroke={`url(#${gradId})`} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    )
}
