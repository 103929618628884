import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function Support(props) {
  const dispatch = useDispatch()

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'Support'})
    document.title = `Index One | Support`
  })()},[])

  return (
      <Stack sx={{minHeight:'80vh'}} alignItems="center" justifyContent="center">
        <Typography variant='h5'>Please get in touch with our support team at support@indexone.io</Typography>
      </Stack>
  );
}