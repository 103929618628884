
import React from "react";


import { Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function TotalTurnoverChartf(props) {
  const toPct = (x)=>{return ((x.turnover*100).toFixed(2))}
  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
        width='100%'
        height='100%'
        data={props.data}
        margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        }}
        >
        <XAxis  type="category" dataKey="time"/>
        <YAxis type="number" tickFormatter={tick => `${tick}%` }/>
        <Tooltip />

            <Bar dataKey='turnover' fill="#4ecdc4">
            {
              props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#D3D3D3"/>
              ))
            }
            </Bar>
   
        </BarChart>
    </ResponsiveContainer>
  );
}

const TotalTurnoverChart = React.memo(TotalTurnoverChartf);
export default TotalTurnoverChart