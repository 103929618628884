
import React from "react";


import { Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';


function ContributionChartf(props) {
  const toPct = (x)=>{return ((x.return*100).toFixed(2))}
  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
        width='100%'
        height='100%'
        layout='vertical'
        data={props.data}
        margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        }}
        >
        <XAxis type="number" allowDataOverflow={false} tickFormatter={tick => `${tick}%` } />
        <YAxis type="category" dataKey="security" />
        <ReferenceLine x={0} stroke="#000" />
        <Tooltip />

            <Bar dataKey='return' fill="#4ecdc4">
            {
              props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.return > 0 ? "#4ecdc4" : "#fc4798"}/>
              ))
            }
            </Bar>
   
        </BarChart>
    </ResponsiveContainer>
  );
}

const ContributionChart = React.memo(ContributionChartf);
export default ContributionChart