import React, {useState, useEffect} from 'react';
import { useStore, useDispatch } from 'react-redux'
import { useNavigate,useParams } from "react-router-dom";
import { useSecurityQuery } from '../../../store/indexOneApi';

import { CSVLink, CSVDownload } from "react-csv";

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CustomizedAxisTick from '../../elements/indexwizard/CustomAxis'

//chart
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const rows = {
  'name':'Name',
  'id':'FIGI',
  'iex_sector':'Sector',
  'iex_industry':'Industry',
  'symbol':'Symbol',
  'exchange_name':'Exchange Name',
  'mic':'Exchange Code'
}


export default function Streams(props) {
  const store = useStore().getState()
  const dispatch = useDispatch()
  const params = useParams()
  //const [securityData, setSecurityData] = useState({})
  const {data:securityData} = useSecurityQuery({id:params.id})

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'Security Overview'})
    document.title = `Index One | Security Overview`
  })()},[])

  return (
    <Container maxWidth={false} disableGutters style={{padding:'2rem', backgroundColor:'#fafafa'}}>
      <Stack direction='column' spacing={2}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={securityData.values}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis height={60} dataKey='time' tick={<CustomizedAxisTick />} />
            <YAxis type="number" />
            <Tooltip />
            <Area type="monotone" dataKey="close" stroke="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
        <TableContainer component={Paper}>
      <Table aria-label="a dense table" size='small'>
        <TableBody>
        {Object.entries(rows).map(([key, value]) =>
          <TableRow
            key={key}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <b>{value}</b>
            </TableCell>
            <TableCell align="right">{securityData[key]}</TableCell>
          </TableRow>
        )}
        </TableBody>
      </Table>
    </TableContainer>
    <Typography>{securityData.description}</Typography>
    </Stack>
    </Container>
  );
}