import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import DataViewer from "../../../components/elements/indexcreate/DataViewer"

//external components
import { CSVLink, CSVDownload } from "react-csv";

import Container from '@mui/material/Container';
import config from '../../../config'


import timezones from '../../helpers/timezones'
import { backtestAllowed,goLiveAllowed } from '../../helpers/authHelper'

import Chip from '@mui/material/Chip';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab'
import { Typography, Stack, Tooltip, IconButton, Button, Paper } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { TextField, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CodeInterface from '../../elements/CodeInterface';
import SecurityDialog from '../../elements/SecurityDialog'
import IndexSpecifications from '../../elements/indexwizard/IndexSpecifications'
import FilterList from '../../elements/indexwizard/FilterList'
import SecuritiesList from '../../elements/indexwizard/SecuritiesList'
import Weighting from '../../elements/indexwizard/Weighting'
import SimpleChart from '../../elements/indexwizard/SimpleChart'
import VolatilityChart from '../../elements/indexwizard/VolatilityChart'
import DrawdownChart from '../../elements/indexwizard/DrawdownChart'
import ContributionChart from '../../elements/indexwizard/ContributionChart'
import TotalTurnoverChart from '../../elements/indexwizard/TotalTurnoverChart'
import TurnoverChart from '../../elements/indexwizard/TurnoverChart'
import WeightingPie from '../../elements/indexwizard/WeightingPie'
import WeightingDataGrid from '../../elements/indexwizard/WeightingDataGrid'
import IntroDialog from '../../elements/indexwizard/IntroDialog'
import BasketUpload from '../../elements/indexwizard/BasketUpload'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

//snackbar
import Snackbar from '@mui/material/Snackbar';

/*
async function getResult(payload){
  console.log("GETTING RES")
  try {
    const response = await api.post(`backtest`,payload)
    const data = response.data
    //alert("RUNDONE")
    return response
  } catch (error) {
    if(error.response){
      return error.response
    }else{
      return {status:504,message:'Initial request timed out, you can still proceed to backtest'}
    }
  }
}
*/


export default function BacktestResults(props) {
  //const dispatch = useDispatch()
  //const navigate = useNavigate()
  //const teamId = useSelector(state => state.activeTeamId)
  //const teamName = useSelector(state => state.activeTeamName)
  //const teamSubscription = useSelector(state => state.activeTeam.subscription)
  //const teamData = useSelector(state => state.activeTeam)

  //const [backtestData,setBacktestData] = useState({})
  //const [primaryId,setPrimaryId] = useState(null)
  //const [secondaryIds,setSecondaryIds] = useState([])

  useEffect(() => {(async()=>{
  })()},[])

  return (
    <Container maxWidth={false} style={{ padding: '1em', backgroundColor:'#121212' }} disableGutters>
      <DataViewer/>
    </Container>
  );
}