import React, {useState, useEffect} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useLazyTeamKeysGetQuery,useTeamKeysPostMutation,useTeamKeysDeleteMutation,useTeamKeysGetQuery } from '../../../store/indexOneApi';

import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack'
import { TextField, Typography,Tooltip,IconButton, } from '@mui/material';
import Grid from "@mui/material/Grid2"
import Container from '@mui/material/Container';


import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function TeamOverview(props) {
  const store = useStore().getState()
  const dispatch = useDispatch()
  //const teamId = useSelector(state => get(state, 'activeTeam.id', ''))
  const [keys,setKeys] = useState([])
  //const [isLoading,setIsLoading] = useState(true)
  const teamData = useSelector(state => state.main.teamData)
  const teamId = useSelector(state => state.main.teamData.id)

  //const [fetchTrigger, { data =[], error, isLoading, isFetching } , lastPromiseInfo] = useLazyTeamKeysGetQuery()
  const {data,isLoading:fetchLoading} = useTeamKeysGetQuery({teamId:teamId})
  const [postKeyTrigger, { isLoading:postLoading }] = useTeamKeysPostMutation()
  const [deleteKeyTrigger, { isLoading:deleteLoading }] = useTeamKeysDeleteMutation()

  const columns = [
    { field: 'id', headerName: 'Key ID',width:200},
    { field: 'created_at', headerName: 'Created At',width:200},
    { field: 'enabled', headerName: 'Enabled',width:200},
    {
      field: 'value',
      headerName: 'Secret',
      flex:1,
      renderCell: (params) => (
          <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
            <Typography sx={!params.row.show ? {color:'transparent',textShadow:'0 0 8px rgba(0,0,0,0.7)'}:{}} >{params.row.value}</Typography>
            <IconButton onClick={e=>toggleShow(params.row.id)}>{params.row.show ? <VisibilityOffIcon fontSize='small'/>: <VisibilityIcon fontSize='small'/>}</IconButton>
          </Stack>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <div>
            <Tooltip title="Remove"><IconButton onClick={e=>handleDelete(params.row.id)}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
          </div>
      )
    }
  ];

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:teamData.name})
    document.title = `Index One | API Keys`

    try {
      var response = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < response.length; i++) {
        response[i].show = false
      }
      setKeys(response)
   } catch (error) {
      console.log(error)
     // you can handle errors here if you want to
   }


    /*
    try {
      var response = await fetchTrigger({"teamId":teamId}).unwrap();
      console.log(response)
      response = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < response.length; i++) {
        response[i].show = false
      }
      setKeys(response)
   } catch (error) {
      console.log(error)
     // you can handle errors here if you want to
   }
   */

    /*
    var response = await getTeamKeys(teamData.id)
    console.log(response)
    for (let i = 0; i < response.length; i++) {
      response[i].show = false
    }
    setKeys(response)
    setIsLoading(false)
    */

  })()},[data])

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <div style={{flexGrow:1}}/>
        <LoadingButton loading={postLoading} variant='outlined' onClick={e=>handleCreate()}>Create New Key</LoadingButton>
      </GridToolbarContainer>
    );
  }

  function toggleShow(id){
    var copy = JSON.parse(JSON.stringify(keys))
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].id === id) {
        copy[i].show = !copy[i].show
        break
      }
    }
    setKeys(copy)
  }

  const handleDelete = (key_id) => {
    deleteKeyTrigger({"teamId":teamId,"keyId":key_id})
    /*
    setIsLoading(true)
    console.log(teamData.id)
    console.log(key_id)
    api.delete(`teams/${teamData.id}/keys/${key_id}`).then(response => {
        console.log(response.data)
        //update table
        let filteredArray = keys.filter(item => item.id !== response.data.id)
        setKeys(filteredArray);
        setIsLoading(false)
      }, error => {
        console.log(error)
        setIsLoading(false)
      })
    */
  }

  const handleCreate = () => {
    postKeyTrigger({"teamId":teamId})
    /*
    setIsLoading(true)
    api.post(`teams/${teamData.id}/keys`).then(response => {
      console.log(response.data)
      response.data.show = false
      //update table
      setKeys(oldArray => [...oldArray,response.data] )
      setIsLoading(false)
    }, error => {
      console.log(error)
      setIsLoading(false)
    })
    */
  }

  return (
    <Container maxWidth={false} disableGutters sx={{padding:'2em'}}>
      <Typography variant='h4'>API Keys</Typography>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
            rows={Array.isArray(keys) ? keys : []}
            columns={columns}
            autoPageSize
            disableMultipleSelection={true}
            slots={{
              toolbar: CustomToolbar,
            }}
        />
      </div>
    </Container>
  );
}