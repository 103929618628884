import React, {useState, useEffect} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack'
import { TextField,Typography,Tooltip,IconButton, } from '@mui/material';
import Grid from "@mui/material/Grid2"
import { useTeamInvitePostMutation, useTeamPatchMutation,useTeamQuery } from '../../../store/indexOneApi';
import Container from '@mui/material/Container';


import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const inviteColumns = [
  { field: 'id', headerName: 'email',flex:1},
  { field: 'created_at', headerName: 'invited_at',flex:1}
];

export default function TeamOverview(props) {
  const store = useStore().getState()
  const dispatch = useDispatch()
  //const teamId = useSelector(state => get(state, 'activeTeam.id', ''))
  const [teamMembers,updateTeamMembers] = useState([])
  const [teamInvites,updateTeamInvites] = useState([])
  const [isLoading,setLoading] = useState(false)
  const [inviteeId,setInviteeId] = useState('')
  const teamData = useSelector(state => state.main.teamData)
  const userData = useSelector(state => state.main.userData)

  const [patchTrigger] = useTeamPatchMutation()
  const [inviteTrigger] = useTeamInvitePostMutation()
  const {data={},isLoading:queryIsLoading} = useTeamQuery({id:teamData.id})

  const columns = [
    { field: 'id', headerName: 'User ID',width:200},
    { field: 'email', headerName: 'Email',width:200},
    { field: 'admin', headerName: 'Admin',width:200},
    { field: 'joined', headerName: 'Joined',width:120},
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <div>
            <Tooltip title="Remove"><IconButton onClick={e=>handleDelete(params.row.id)}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
            <Tooltip title="Toggle admin"><IconButton onClick={e=>toggleAdmin(params.row.id,params.row.admin ? false:true)}><EditIcon fontSize='small'/></IconButton></Tooltip>
          </div>
      )
    }
  ];


  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:teamData.name})
    document.title = `Index One | Team Overview`
    updateTables()
  })()},[teamData])

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <div style={{flex:1}}/>
      </GridToolbarContainer>
    );
  }

  const updateTables = (response) => {
    console.log(teamData)
    var members = []
    const thisData = JSON.parse(JSON.stringify(teamData))
    for (var [key, value] of Object.entries(thisData.members)) {
      value.id = key
      members.push(value)
    }
    updateTeamMembers(members)

    if(thisData.invites){
      var invites = []
      for (var [key, value] of Object.entries(thisData.invites)) {
        value.id = key
        invites.push(value)
      }
      updateTeamInvites(invites)
    }
  }
  
  const handleInvite = (file) => {
    setLoading(true)
    let inviteObj = {
      email:inviteeId,
      id:teamData.id
    }
    const response = inviteTrigger(inviteObj).unwrap()
    console.log(response)
    updateTables(response)//update table
    setLoading(false) 
  }

  const handleDelete = (user_id) => {
    setLoading(true)
    let body = {
      remove_member:user_id,
      id:teamData.id
    }
    const response = patchTrigger(body).unwrap()
    console.log(response)
    updateTables(response)
    setLoading(false)
  }

  const toggleAdmin = (user_id,make) => {
    setLoading(true)
    var body = {id:teamData.id}
    if(make){
      body['make_admin'] = user_id
    }else{
      body['remove_admin'] = user_id
    }
    const response = patchTrigger(body).unwrap()
    console.log(response)
    updateTables(response)
    setLoading(false)
  }

  return (
    (<Container maxWidth={false} disableGutters sx={{padding:'2em'}}>
      <Grid container spacing={3}>
        <Grid size="grow">
          <Typography variant='h4'>Members</Typography>
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
                rows={Array.isArray(teamMembers) ? teamMembers : []}
                columns={columns}
                autoPageSize
                disableMultipleSelection={true}
                slots={{
                  toolbar: CustomToolbar,
                }}
            />
          </div>
        </Grid>
        <Grid size={3}>
          <Typography variant='h4'>Invitations</Typography>
          <Stack direction='row' spacing={1} alignItems='stretch' sx={{marginBottom:"10px"}}>
            <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={(e) => setInviteeId(e.target.value)}
                value={inviteeId}
            />
            <LoadingButton variant='outlined' loading={isLoading} onClick={e=>handleInvite()}>Invite</LoadingButton>
          </Stack>
          <div style={{ height: '70vh', width: '100%' }}>
            <DataGrid
                rows={Array.isArray(teamInvites) ? teamInvites : []}
                columns={inviteColumns}
                autoPageSize
                disableMultipleSelection={true}
            />
          </div>
          <Typography variant='body2'><strong>Team ID:</strong> {teamData.id}</Typography>
          <Typography variant='body2'><b>User ID:</b> {userData.id}</Typography>
        </Grid>
      </Grid>
    </Container>)
  );
}