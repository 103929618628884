import React, { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, ReferenceLine, Cell } from 'recharts';
import { Typography, Paper, Skeleton }  from '@mui/material';

const dataTest = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];

const xKey = "volatility_30d"
const yKey = "return_30d"
const rf = 0

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{padding:'5px'}}>
        <Typography variant='body1Heavy'>{payload[0].payload.name}</Typography>
        <Typography>return: {(payload[1].value*100).toFixed(2)}%</Typography>
        <Typography>risk: {(payload[0].value*100).toFixed(2)}</Typography>
        <Typography>sharpe: {((payload[1].value-rf)/payload[0].value).toFixed(2)}</Typography>
      </Paper>
    );
  }
  return null;
};

function toPct(f){
  return((f*100).toFixed(2))
}

export default function IndicesScatter(props){
  const [data,setData] = useState([])
  const [efficientLine,setEfficientLine] = useState([])
  const [slope,setSlope] = useState(0)
  const [maxSharpe,setMaxSharpe] = useState("")
  const [minSharpe,setMinSharpe] = useState("")
  const [loading,setLoading] = useState(true)

  useEffect(() => {(async()=>{
    if(Object.keys(props.data).length === 0){return}
    var dataNew = []
    var maxY = ""
    var maxX = ""
    var thisMaxSharpe = ["",""]
    var thisMinSharpe = ["",""]
    for (const [key, value] of Object.entries(props.data)) {
      if(!value.stats){continue}
      if(value.stats.volatility["30d"] && value.stats.returns["30d"]){
        value[xKey] = value.stats.volatility["30d"]
        value[yKey] = value.stats.returns["30d"]
      }
      dataNew.push(value)
      if(value.stats.sharpe["30d"]){
        if(value.stats.sharpe["30d"] > thisMaxSharpe[1]){thisMaxSharpe = [key,value.stats.sharpe["30d"]]}
        if(value.stats.sharpe["30d"] < thisMinSharpe[1]){thisMinSharpe = [key,value.stats.sharpe["30d"]]}
      }
      if(value[xKey] > maxX){maxX = value[xKey]}
      if(value[yKey] > maxY){maxY = value[yKey]}
    }
    setMaxSharpe(thisMaxSharpe[0])
    setMinSharpe(thisMinSharpe[0])
    if(props.refIndex){
      //set reference line
      var slope = (props.data[props.refIndex][yKey]-rf)/(props.data[props.refIndex][xKey]-0)
      setSlope(slope)

      if(slope > 0){
        var destination = {x:(maxY-rf)/slope,y:maxY}
      } else if(slope > 1){
        var destination = {x:(maxY-rf)/slope,y:maxY}
      } else {
        var destination = {x:maxX,y:maxX*slope+rf}
      }
      setEfficientLine([{ x: 0, y: rf }, destination])
    }
    setData(dataNew)
    setLoading(false)
  })()},[props.data,props.refIndex])

  if(loading){return <Skeleton variant='rectangular' height='200px'/>}
  return (
    <ResponsiveContainer width="100%" height={200}>
      <ScatterChart
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        style={{fontSize:'12px'}}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis domain={["dataMin - 0.01","dataMax + 0.01"]} unit=" %" type="number" dataKey={xKey} name="risk" label={{ value: "σ", position: "insideBottomLeft",dx:20}} tickFormatter={toPct} axisLine={false} mirror/>
        <YAxis domain={["dataMin - 0.01","dataMax + 0.01"]} type="number" dataKey={yKey} unit=" %" name="return" label={{ fontStyle:"italic", value: "r", position: "insideBottomLeft",dy:-20}} tickFormatter={toPct} axisLine={false} mirror/>
        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
        <ReferenceLine ifOverflow="extendDomain" isFront={true} stroke="gray" strokeDasharray="3 3" segment={efficientLine}/>
        <Scatter name="A school" data={data} fill="#8884d8">
          {data.map((entry, index) => (
            entry.id == props.refIndex ?
              <Cell key={`cell-${index}`} fill='#3D5A80' />
            : entry.id == maxSharpe ?
              <Cell key={`cell-${entry.id}`} fill="#4ecdc4" />
            : entry.id == minSharpe ?
              <Cell key={`cell-${index}`} fill="#fc4798" />
            :
              <Cell key={`cell-${index}`} fill="lightgray"/> 
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}
