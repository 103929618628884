import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {csvToArray,csvWeightsToArray,weightsToArray} from '../helpers/csvToArray'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { props } from 'lodash/fp'

export default function FileUpload(props) {
  const onDrop = useCallback((acceptedFiles) => {
    if(props.setLoading){props.setLoading(true)}
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
      let data = reader.result
      console.log(props)
      if(props.fileType){
        console.log(props.fileType)
        if(props.fileType == 'weights'){
          data = csvWeightsToArray(data)
        } else if(props.fileType == 'weights_event'){
          data = weightsToArray(data)
      }}else{
        data = csvToArray(data)
      }
        
        console.log(data)
        props.returnData(data)
      }
      reader.readAsText(file)
    })
    if(props.setLoading){props.setLoading(false)}
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  if(props.style == 'button'){
    return(
      <div {...getRootProps()}>
        {!props.disabled && <input {...getInputProps()} />}
        <Tooltip title='Upload File'>
          <IconButton size='large' disabled={props.disabled}>
            <FileUploadIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </div>
    )
  }
  return (
    <Paper sx={{padding:'1em',outlineStyle:'dashed'}}>
        <div {...getRootProps()}>
        <input {...getInputProps()} />
            <p>Drag and drop a file here, or click to select file</p>
        </div>
    </Paper>
  )
}