import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function NotFound(props) {
  document.title = `Index One | 404`
  return (
    <Container component="main" disableGutters maxWidth={false}>
    <Container maxWidth='md' style={{padding:'50px 1rem'}}>

      <Typography variant='h1'>404 Page Not Found</Typography>
  
    </Container>
    </Container>
  );
}