function backtestAllowed(teamData){
    if(teamData.hasOwnProperty('backtest_access_until')){
        var toDate = teamData.backtest_access_until
        var now = new Date().toISOString().replace('T', ' ').replace('Z', '')
        if(now <= toDate){
            return true
        }
    }
    return(false)
}

function goLiveAllowed(teamData){
    if(teamData.hasOwnProperty('live_index_limit')){
        if(Object.keys(teamData.indices).length < teamData.live_index_limit){
            return(true)
        }
    }
    return(false)
}

export {
    backtestAllowed,
    goLiveAllowed
}