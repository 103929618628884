import { createTheme } from '@mui/material/styles';

const themeSpec = {
  typography:{
    fontSize:12,
    fontFamily:`"Roboto", sans-serif`,
    fontWeight:300,
    body1:{fontWeight:300},
    body1Heavy:{fontWeight:400},
    body2:{fontWeight:300},
    h1:{fontWeight:200},
    h2:{fontWeight:200},
    h3:{fontWeight:200},
    h4:{fontWeight:200},
    h5:{fontWeight:200},
    h6:{fontWeight:300},
    logothick:{fontWeight:600},
    logothin:{fontWeight:100},
    pageTitle:{
      fontWeight:100,
      textTransform:'uppercase',
      fontSize:20
    },
  },
    palette:{
      primary:{
        main:'#4ecdc4'
      },
      secondary:{
        main:'#fc4798'
      },
      neutral:{
        main:'#3D5A80'
      }
    }
}

const theme = createTheme(themeSpec)

export {
  theme,
  themeSpec
}