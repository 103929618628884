import React from 'react';
import { useStore } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";

import ContactForm from '../../elements/ContactForm'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      'Public access',
      'Data export',
      'Constituents',
      'Streaming data'
    ],
    text:'View-only access to published indices.',
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Professional',
    price: '90',
    description: [
      'Custom Indices',
      'Unlimited Backtesting',
      'Public Indices',
      'Realtime Index Values',
      'Historical Index Values',
      'Index Constituents',
      'Streaming Notifications',
      'Unlimited API Keys',
    ],
    text:'Access to full functionality, including custom index creation.',
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '----',
    description: [
      'Bespoke Functionality',
      'Private Instances',
      'Index Administration',
      'more...',
    ],
    text:'Get in touch with our team at contact@indexone.io for information about our enterprise offering.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

function createData(name, free, professional, enterprise) {
  return { name, free, professional, enterprise };
}

const rows = [
  createData('Public Index Values', true, true, true),  
  createData('Risk / Return Statistics', true, true, true),
  createData('Embed Functionality', true, true, true),
  createData('Public Index Constituents', false, true, true),
  
  //
  createData('API Access', false, true, true),
  createData('Index Builder', false, true, true),
  createData('Index Calculation', false, true, true),
  createData('Basket Calculation', false, true, true),
  createData('Streaming Data', false, true, true),
  //
  createData('Custom Data', false, false, true),
  createData('Custom Charts', false, false, true),
  createData('SLA', false, false, true),
  createData('Premium Support', false, false, true)
];

export default function Pricing(props) {
  const store = useStore().getState()
  const navigate = useNavigate()
  document.title = `Index One | Pricing`
  return (
    (<Container component="main" disableGutters maxWidth={false}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'40vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
          <Typography style={{color:'#fafafa'}} variant="h3">Pricing</Typography>
          <br/>
          <Typography style={{color:'#fafafa'}} variant="h5">Index One provides you with simple yet modern indexing technology and tools, at your fingertips. <br/><br/> Gain license-free access to realtime index data and constituents, and pay only for what you use.</Typography>
      </Box>
      <Container maxWidth="md" component="main" xs={12}>
        <Stack spacing={3}>
        <Grid container spacing={3} alignItems="stretch" justifyContent='center'>
          {tiers.map((tier) => (
            <Grid
              key={tier.title}
              size={{
                xs: 12,
                sm: tier.title === 'Enterprise' ? 12 : 12,
                md: 4
              }}>
              <Card sx={{height:'100%'}}>
                <Stack direction='column' sx={{height:'100%',width:'100%'}}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                />
                <CardContent>
                  <div>
                  <Typography variant="h6" color="textSecondary">
                      from
                    </Typography>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      $ {tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  {/*<ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                    </ul>*/}
                  <Typography>
                    {tier.text}
                  </Typography>
                </CardContent>
                <div style={{flexGrow:1}}/>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary"
                    onClick={
                      tier.title == 'Enterprise' ? (e=>navigate('/contact'))
                      :
                      (e=>navigate('/register'))
                    }
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant='h4' sx={{textAlign:'center'}}>Full Features</Typography>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">Free</TableCell>
            <TableCell align="center">Professional</TableCell>
            <TableCell align="center">Enterprise</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell align="center">{row.free && <CheckIcon color='primary'/>}</TableCell>
              <TableCell align="center">{row.professional && <CheckIcon color='primary'/>}</TableCell>
              <TableCell align="center">{row.enterprise && <CheckIcon color='primary'/>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Stack>
      </Container>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
      <ContactForm/>
      </Box>
    </Container>)
  );
}



