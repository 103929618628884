import React from 'react';

import ContactForm from '../../elements/ContactForm'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const jobAds = [
  {title:'Business Development Associate', location:'London / Remote', url:"https://docs.google.com/document/d/e/2PACX-1vS-hpytqMa6787dWrnENgcFR3nnBT4or-LfgZNuPtJnpph6_X6MEDvYgbJruF5n8IvPaCJRJXh729nc/pub?embedded=true"},
  {title:'Research Intern', location:'London / Remote', url:"https://docs.google.com/document/d/e/2PACX-1vTvcoKJAYQR1662GI3GekT1DwSeHHDhC_GCQRSXOCYYcTYVtvcZZC02hbaCdZkppUHgEXS_TBclmIy2/pub?embedded=true"},
  {title:'Sales Intern', location:'London / Remote', url:"https://docs.google.com/document/d/e/2PACX-1vTrKfw749Cp9PW6nJnVAXdtJFUiUoQhhDi1hkYD3uXsDTdeRbbdHGrNJGq-7Ev1Im_m68_YjLv4rQs4/pub?embedded=true"},
  {title:'Marketing Intern', location:'London / Remote', url:'https://docs.google.com/document/d/e/2PACX-1vS4SECS4_bU7yfCopxuKpkDhfdO4FCQThfqcifmHpRPX2drBCvdj2pHCpPwahTHFFYoYMB8A8utyc1h/pub?embedded=true'}

]

export default function Careers(props) {
  document.title = `Home | Careers`
  const returnAccordion = (title,location,url) => {
    return(
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{title}</Typography>
          <Typography sx={{ color: 'text.secondary' }}>{location}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <iframe width='100%' height='1000px' frameBorder='0' src={url}></iframe>
        </AccordionDetails>
      </Accordion>
      )}

  return (
    <Container component="main" disableGutters maxWidth={false}>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'40vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
        <Typography style={{color:'#fafafa', margin:'1rem'}} variant="h3">Careers</Typography>
        <br/>
        <Typography style={{color:'#fafafa', margin:'1rem', marginBottom:'2rem'}} variant="h5">Join us on our journey to revolutionize the index industry.</Typography>
    </Box>
    <Container maxWidth='md' style={{padding:'50px 1rem'}}>
    {jobAds.map((ad)=>returnAccordion(ad.title,ad.location,ad.url))}
    </Container>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
    <ContactForm/>
      </Box>
    </Container>
  );
}