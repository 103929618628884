import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import { useBacktestMutation } from '../../../store/indexOneApi';
//external components
import { CSVLink, CSVDownload } from "react-csv";

import Container from '@mui/material/Container';
import config from '../../../config'

import timezones from '../../helpers/timezones'
import { backtestAllowed,goLiveAllowed } from '../../helpers/authHelper'

import Chip from '@mui/material/Chip';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab'
import { Typography, Stack, Tooltip, IconButton, Button, Paper } from '@mui/material';
import Grid from "@mui/material/Grid2"
import LinearProgress from '@mui/material/LinearProgress';

import { TextField, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CodeInterface from '../../elements/CodeInterface';
import SecurityDialog from '../../elements/SecurityDialog'
import IndexSpecifications from '../../elements/indexwizard/IndexSpecifications'
import FilterList from '../../elements/indexwizard/FilterList'
import SecuritiesList from '../../elements/indexwizard/SecuritiesList'
import Weighting from '../../elements/indexwizard/Weighting'
import SimpleChart from '../../elements/indexwizard/SimpleChart'
import VolatilityChart from '../../elements/indexwizard/VolatilityChart'
import DrawdownChart from '../../elements/indexwizard/DrawdownChart'
import ContributionChart from '../../elements/indexwizard/ContributionChart'
import TotalTurnoverChart from '../../elements/indexwizard/TotalTurnoverChart'
import TurnoverChart from '../../elements/indexwizard/TurnoverChart'
import WeightingPie from '../../elements/indexwizard/WeightingPie'
import WeightingDataGrid from '../../elements/indexwizard/WeightingDataGrid'
import IntroDialog from '../../elements/indexwizard/IntroDialog'
import BasketUpload from '../../elements/indexwizard/BasketUpload'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

//snackbar
import Snackbar from '@mui/material/Snackbar';

/*
async function getResult(payload){
  console.log("GETTING RES")
  try {
    const response = await api.post(`backtest`,payload)
    const data = response.data
    //alert("RUNDONE")
    return response
  } catch (error) {
    if(error.response){
      return error.response
    }else{
      return {status:504,message:'Initial request timed out, you can still proceed to backtest'}
    }
  }
}
*/

async function getS3OLD(url){
  try {
    const result = await fetch(url,{method:"GET"})
    const data =  await result.json()
    console.log(result)
    console.log(data)
    //const response = await axios.get(url)
    //console.log(response)
    //const data = response.data
    return data
  } catch (error) {
    if(error.response){
      return error.response
    }else{
      return null
    }
  }
}

const weightingColumns = [
  { field: 'id', headerName: 'id',width:120},
  { field: 'weight', headerName: 'weight', flex:2}
];

const basePayload = {

}

/*
const initFilters = [
  {
    id:"0",
    attribute:'iex_sector',
    operator:'=',
    value:'Information'
  },
  {
    id:"1",
    attribute:'issue_type',
    operator:'=',
    value:'cs'
  },
  {
    id:"2",
    attribute:'region',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'country',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'mic',
    operator:'in',
    value:['XNYS','XNAS']
  },
  {
    id:"4",
    attribute:'market_cap',
    operator:'limit_descending',
    value:100
  }
]
*/
/*
const initFilters = [
    {
      "id": "0",
      "attribute": "iex_symbol",
      "operator": "in",
      "value": [
        "GS",
        "MA",
        "VZ"
      ]
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": ">",
      "value": 100000
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": "limit_descending",
      "value": 5
    }
]
*/

/*
const initFilters = [
  {
    "id": "0",
    "attribute": "security_type",
    "operator": "=",
    "value": "Common Stock"
  },
  {
    "id": "1",
    "attribute": "exchange_name",
    "operator": "in",
    "value": [
      "NASDAQ - ALL MARKETS",
      "NEW YORK STOCK EXCHANGE, INC."
    ]
  },
  {
    "id": "2",
    "attribute": "sector",
    "operator": "in",
    "value": [
      "Financial Services",
      "Technology",
      "Communication Services"
    ]
  },
  {
    "id": "3",
    "attribute": "market_cap_currency_usd",
    "operator": "limit_descending",
    "value": 100
  }
]
  */

const initFilters = [
  {
    "id": "0",
    "attribute": "security_type",
    "operator": "=",
    "value": "Common Stock"
  },
  {
    "id": "1",
    "attribute": "mic",
    "operator": "in",
    "value": [
      "XNYS",
      "XNAS"
    ]
  },
  {
    "id": "2",
    "attribute": "sector",
    "operator": "in",
    "value": [
      "Financial Services",
      "Technology",
    ]
  },
  {
    "id": "3",
    "attribute": "market_cap_currency_usd",
    "operator": "limit_descending",
    "value": 100
  }
]

const defaultSpec = {
  "full_backtest": false,
  "benchmark":[
    ["S&P 500 ETF TRUST ETF","SPY","BBG000BDTF76"],
    ["iShares MSCI ACWI ETF","ACWI","BBG000TH6X92"]
  ],
  "start_time": "2020-01-01",
  "schedule":"0 16 1 1 *",
  "calculation_frequency":"eod",
  "start_value":1000,
  "base_currency":"local",
  "open_time":"09:30",
  "close_time":"16:00",
  "eod_time":"16:00",
  "timezone":"US/Eastern",
  "timezone_open":"US/Eastern",
  "timezone_close":"US/Eastern",
  "visibility":"team",
  "adjustment_policies": {
    "dividend": {
      "deployment_scheme": "portfolio",
      "deploy_at": {
        "expression_type": "date",
        "value": "ex_date"
      }
    },
    "split":{
      "deployment_scheme": "security",
      "expression_type":"date",
      "value":"ex_date",
      "deploy_at":{
        "expression_type": "date",
        "value": "ex_date"
      }
    }
  }
}

export default function IndexWizard(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const teamId = useSelector(state => state.main.teamData?.id ?? "")
  //const teamName = useSelector(state => state.activeTeamName)
  //const teamSubscription = useSelector(state => state.activeTeam.subscription)
  const teamData = useSelector(state => state.main.teamData)


  const [websocketConnectionId,setWebsocketConnectionId] = useState(null)
  const [expanded,setExpanded] = useState('specifications')
  const [activeTab,setActiveTab] = useState("1")
  const [securities,setSecurities] = useState([])
  const [attributes,setAttributes] = useState([])
  const [universeLength,setUniverseLength] = useState([])
  const [attributeConfig,setAttributeConfig] = useState({})
  const [schedule,setSchedule] = useState([])
  const [payload,setPayload] = useState(basePayload)

  //loading
  const [loading, setLoading] = useState(false)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [loadingProgress,setLoadingProgress] = useState(0)
  const [loadingStage,setLoadingStage] = useState("Initializing")

  const [response,setResponse] = useState({})
  const [selected,setSelected] = useState([])

  //specs
  const [filters,setFilters] = useState(initFilters)
  const [indexSpecifications,setIndexSpecifications] = useState(defaultSpec)
  const [weightingObjective,setWeightingObjective] = useState({'type':'equal','attribute':'market_cap'})
  const [weightingConstraints,setWeightingConstraints] = useState([])
  const [weighting,setWeighting] = useState({})
  const [weightingDefinedHistorical,setWeightingDefinedHistorical] = useState({})
  const [universeDefinedHistorical,setUniverseDefinedHistorical] = useState({})
  const [customUniverse,setCustomUniverse] = useState(false)
  const [weightingFixed,setWeightingFixed] = useState({})
  const [weightingFixedUnweighted,setWeightingFixedUnweighted] = useState([])
  const [weightingType,setWeightingType] = useState('regular')

  //stats & perf
  const [indexRef,setIndexRef] = useState({})
  const [indexValues,setIndexValues] = useState([{}])
  const [drawdown,setDrawdown] = useState([{}])
  const [volatility,setVolatility] = useState([{}])
  const [contribution,setContribution] = useState({})
  const [weightingResult,setWeightingResult] = useState({})
  const [rebalanceResults,setRebalanceResults] = useState({})
  const [totalTurnover,setTotalTurnover] = useState([])
  const [warnings,setWarnings] = useState(["No warnings"])
  const [weightingLogs,setWeightingLogs] = useState("")

  //results manipulation & ui
  const [dialogOpen,setDialogOpen] = useState(true)
  const [dialogTitle,setDialogTitle] = useState('')
  const [dialogContentName,setDialogContentName] = useState('intro')
  const [resultTabSelected,setResultTabSelected] = useState('')
  const [weightingHistoricalTab,setWeightingHistoricalTab] = useState('')

  //snackbar messages
  const [snackbarOpen,setSnackbarOpen] = useState(false)
  const [snackbarMessage,setSnackbarMessage] = useState('')

  //securityDialog
  const [securityDialogOpen,setSecurityDialogOpen] = useState(false)
  const [securitySelected,setSecuritySelected] = useState(null)

  const latestApiRequest = useRef();

  const [backtestTrigger] = useBacktestMutation()

  //triggered effects on pageload
  //dispatch({type:'SET_PAGE_TITLE',payload:'Index Builder'})
  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'Index Builder'})
    document.title = `Index One | Index Builder`
    //if(loading === true){setLoading(false)}
  })()},[])

  //trigger update to universe/weighting
  //useEffect(() => {
  //  const timeoutId = setTimeout(() => runFilters(filters,weightingObjective,weightingConstraints,indexSpecifications.schedule), 1000);
  //  return () => clearTimeout(timeoutId);
  //}, [filters,weightingObjective,weightingConstraints,indexSpecifications.schedule]);

  useEffect(() => {
    console.log(loading)
    console.log(latestApiRequest.current)
    console.log(JSON.stringify([filters,weightingObjective,weightingConstraints,indexSpecifications.schedule]))
    if(loading === false){
      let currentState = [filters,weightingObjective,weightingConstraints,indexSpecifications.schedule]
      if (JSON.stringify(currentState) !== latestApiRequest.current){
        console.log("running based on loading")
        latestApiRequest.current = JSON.stringify([filters,weightingObjective,weightingConstraints,indexSpecifications.schedule])
        runFilters()
      } else {
        console.log("current EQ")
      }
    }else{
      console.log("loading True")
    }
  }, [filters,weightingObjective,weightingConstraints,indexSpecifications.schedule,loading]);

  


  function openSecurityDialog(securityId) {
    setSecuritySelected(securityId)
    setSecurityDialogOpen(true)
  }

  function openDialog(title,name) {
    setDialogOpen(true)
    setDialogTitle(title)
    setDialogContentName(name)
  }

  const websocketConnect = async () => {
    console.log('connecting websocket')
    //check subscription status
    if(!backtestAllowed(teamData)){
      setSnackbarMessage('Backtesting is not enabled for your account, please upgrade your subscription on the billing page or contact support.')
      setSnackbarOpen(true)
      setLoading(false)
      return
    }
    setDialogContentName('loading')
    setDialogTitle('Calculating Backtest')
    setLoadingStage('Initializing...')
    setLoadingProgress(0)
    setDialogOpen(true)
    var ws = new WebSocket(`${config.wsUrl}`);
    ws.onopen = () => {
      console.log('websocket connected')
      console.log(ws)
    }
    ws.onmessage = async e => {
      //if message contains connection id, request backtest with connection id
      const message = JSON.parse(e.data)
      if(message.hasOwnProperty('status')){
        if(message.status == 'connected'){
          if(message.hasOwnProperty('websocket_id')){
            runBacktest(false,message.websocket_id)
          }
        } else if (message.status == 'completed'){
          if(message.hasOwnProperty('url')){
            setLoadingStage("Parsing backtest data...")
            var data = await getS3OLD(message.url)
            console.log(data)
            setData(data)
            setBackdropOpen(false)
            setDialogOpen(false)
            ws.close()
          }
        } else if (message.status == 'loading'){
          if(message.hasOwnProperty('loading_progress')){
            setLoadingProgress(message.loading_progress)
          }
          if(message.hasOwnProperty('loading_stage')){
            setLoadingStage(message.loading_stage)
          }
        }
      }
      console.log(message)
    }
    ws.onclose = e => { console.log('websocket closed') }
    ws.onerror = err => {
      console.error("Socket encountered error: ", err.message, "Closing socket")
      ws.close()
    }
  }

  const setData = (data) => {
    //setIndexValues([data.values])
    function reformat(timeArray,dataObj,toPct=false) {
      // list into list or arrays
      var formatted = timeArray.map(x=>({'time':x}))
      for (let i=0;i<formatted.length;i++){
        for (const [security,values] of Object.entries(dataObj)) {
          if(toPct){formatted[i][security] = values[i]*100}
          else{formatted[i][security] = values[i]}
        }
      }
      return formatted
    }

    var refObj = {}

    if(data.hasOwnProperty('warnings')){
      setWarnings(data.warnings)
    }

    if(data.hasOwnProperty('stats')){
      //build refObj
      for (const key of Object.keys(data.stats.value)) {
        refObj[key] = {
          selected:true,
          name:'asdf'
        }
      }
      setIndexRef(refObj)

      if(data.stats.hasOwnProperty('volatility')){
        var res = reformat(data.stats.time,data.stats.volatility)
        setVolatility(res)
      }
      if(data.stats.hasOwnProperty('value')){
        var formatted = data.stats.time.map(x=>({'time':x}))
        for (let i=0;i<formatted.length;i++){
          for (const [security,values] of Object.entries(data.stats.value)) {
            formatted[i][security] = values[i]
          }
        }
        setIndexValues(formatted)
      }
      if(data.stats.hasOwnProperty('drawdown')){
        var res = reformat(data.stats.time,data.stats.drawdown,true)
        setDrawdown(res)
      }
      if(data.stats.hasOwnProperty('performance_contribution')){
        var contrib = {}
        for (const [key, value] of Object.entries(data.stats.performance_contribution)) {
          contrib[key] = Object.entries(value).map((e) => ( { 'security':e[0],'return':e[1] } ));
        }
        console.log(contrib)
        setContribution(contrib)
      }
      if(data.stats.hasOwnProperty('weighting')){
        console.log(data.stats.weighting)
        var weighting = {}
        for (const [key, value] of Object.entries(data.stats.weighting)) {
          weighting[key] = Object.entries(value).map((e) => ( { 'name':e[0],'value':e[1] } ));
        }
        console.log(weighting)
        setWeightingResult(weighting)
      }
      if(data.stats.hasOwnProperty('total_turnover')){
        var totalTurnover = Object.entries(data.stats.total_turnover).map((e) => ( { 'time':e[0],'turnover':e[1]*100 } ))
        setTotalTurnover(totalTurnover)
      }
      if(data.stats.hasOwnProperty('rebalances')){
        console.log(data.stats.rebalances)
        var rebalanceRes = {}
        for (const [key, value] of Object.entries(data.stats.rebalances)) {
          var thisr = {
            weighting:Object.entries(value.weighting).map((e) => ( { 'name':e[0],'value':e[1] } ))
          }

          if(value.hasOwnProperty('performance_contribution')){
            thisr.performance_contribution = Object.entries(value.performance_contribution).map((e) => ( { 'security':e[0],'return':e[1]*100 } ))
          }
          if(value.hasOwnProperty('turnover')){
            thisr.turnover = Object.entries(value.turnover).map((e) => ( { 'security':e[0],'return':e[1]*100 } ))
          }
          rebalanceRes[key] = thisr
        }
        setRebalanceResults(rebalanceRes)
        setResultTabSelected(Object.keys(data.stats.rebalances)[Object.keys(data.stats.rebalances).length -1])
      }
    }
  }


  const returnDialog = () => {
    return(
      <Dialog
        onClose={e=>setDialogOpen(false)}
        open={dialogOpen}
        maxWidth={
          dialogContentName == 'custom_code' ? 'lg':
          dialogContentName == 'basket_upload' ? 'md':
          'sm'
        }
        fullWidth
      >
        <DialogTitle>
          {dialogTitle}
          <IconButton
          aria-label="close"
          onClick={e=>setDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            dialogContentName == 'download_data' ? downloadDialogContent()
            : dialogContentName == 'launch_index' ? launchDialogContent()
            : dialogContentName == 'loading' ? loadingDialogContent()
            : dialogContentName == 'custom_code' ? codeDialogContent()
            : dialogContentName == 'intro' ? <IntroDialog
              setCustomUniverse={setCustomUniverse}
              setActiveTab={setActiveTab}
              setDialogOpen={setDialogOpen}
              setDialogContentName={setDialogContentName}
              setWeightingObjective={setWeightingObjective}
            />
            : dialogContentName == 'basket_upload' ? <BasketUpload
              setWeightingObjective={setWeightingObjective}
              setUniverseDefinedHistorical={setUniverseDefinedHistorical}
              setWeightingType={setWeightingType}
              setWeightingHistoricalTab={setWeightingHistoricalTab}
              setDialogOpen={setDialogOpen}
            />
            : ''
          }
        </DialogContent>
      </Dialog>
    )
  }

  const downloadDialogContent = () => {
    return(
      <Stack direction='column' spacing={2} alignItems='stretch'>
        <CSVLink style={{textDecoration:'none'}} filename={"indexone.csv"} data={indexValues}><LoadingButton fullWidth variant='outlined' sx={{textDecoration:'none'}}>Download Values</LoadingButton></CSVLink>
        <CSVLink style={{textDecoration:'none'}} filename={"indexone.csv"} data={volatility}><LoadingButton fullWidth variant='outlined' sx={{textDecoration:'none'}}>Download Volatility</LoadingButton></CSVLink>
        <CSVLink style={{textDecoration:'none'}} filename={"indexone.csv"} data={totalTurnover}><LoadingButton fullWidth variant='outlined' sx={{textDecoration:'none'}}>Download Turnover</LoadingButton></CSVLink>
        <CSVLink style={{textDecoration:'none'}} filename={"indexone.csv"} data={drawdown}><LoadingButton fullWidth variant='outlined' sx={{textDecoration:'none'}}>Download Drawdown</LoadingButton></CSVLink>
      </Stack>
    )
  }

  function codeDialogContent(){
    return(
      <CodeInterface
        weightingObjective={weightingObjective}
        setWeightingObjective={setWeightingObjective}
        weighting={weighting}
        weightingLogs={weightingLogs}
      />
    )
  }

  const returnTextField = (variable, label, type='text') => {
    return(
      <TextField
      id={variable}
      type={type}
      label={label}
      value={indexSpecifications[variable]}
      onChange={e => setIndexSpecifications(p => ({ ...p, [variable]: e.target.value }))}
    />
    )
  }

  const returnSelectField = (variable, label, options) => {
    return(
      <TextField
      id={variable}
      label={label}
      select
      fullWidth
      value={indexSpecifications[variable]}
      onChange={e => setIndexSpecifications(p => ({ ...p, [variable]: e.target.value }))}
      >
      {Array.isArray(options[0]) ?
        options.map((option) => (
          <MenuItem key={option[0]} value={option[0]}>
            {option[1]}
          </MenuItem>
        ))
        :
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))
      }
    </TextField>
    )
  }

  const launchDialogContent = () => {
    return(
      <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={2}>
        <Stack direction='column' spacing={2}>
        {/*<TextField
          id="id"
          label="Index ID"
          value={indexSpecifications.id}
          onChange={e => setIndexSpecifications(p => ({ ...p, id: e.target.value }))}
        />*/}
        <TextField
          id="name"
          label="Index Name"
          value={indexSpecifications.name}
          onChange={e => setIndexSpecifications(p => ({ ...p, name: e.target.value }))}
        />
        <TextField
          id="description"
          label="Index Description"
          multiline
          rows={4}
          value={indexSpecifications.description}
          onChange={e => setIndexSpecifications(p => ({ ...p, description: e.target.value }))}
        />
        {returnSelectField("visibility","Visibility",[['team','Team Visible'],['public','Public']])}
        </Stack>
        <Stack direction="column" spacing={2}>
          {returnSelectField("calculation_frequency","Calculation Frequency",[['eod','EOD'],['realtime','Realtime']])}
          {returnSelectField("timezone","Timezone",timezones)}
          <Stack direction='row' spacing={1}>
            {returnSelectField("timezone_open","Timezone Open",timezones)}
            {returnSelectField("timezone_close","Timezone Close",timezones)}
          </Stack>
          <Stack direction="row" spacing={1}>
            {returnTextField('open_time','Open Time', 'time')}
            {returnTextField('close_time','Close Time', 'time')}
            {returnTextField('eod_time','EOD Time', 'time')}
          </Stack>
        </Stack>
      </Stack>
      <LoadingButton variant='filled' fullWidth loading={loading} onClick={e=>{runBacktest(true);setDialogOpen(false)}}>Launch</LoadingButton>
      </Stack>
    )
  }

  const loadingDialogContent = () => {
    return(
      <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
        <Typography variant="body2">{loadingStage}</Typography>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={loadingProgress} size={100} thickness={5}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4" component="div" color="text.secondary">
              {`${Math.round(loadingProgress)}%`}
            </Typography>
          </Box>
        </Box>
      </Stack>
    )
  }

  const removeSecurity = (figi) => {
    const updateFilter = (idx,attr,value) => {
      let newFilters = Array.from(filters)
      newFilters[idx][attr] = value
      setFilters(newFilters)
    }
  
    const pushFilter = (idx=filters.length,filter) => {
      let newFilters = Array.from(filters)
      let thisFilter = filter
      thisFilter.id = filters.length.toString()
      newFilters.splice(idx,0,thisFilter)
      setFilters(newFilters)
    }
    let done = false
    for (let i = 0; i < filters.length; i++) {
      if(filters[i].attribute == 'figi' && filters[i].operator == 'not_in'){
        let arr = Array.from(filters[i].value)
        arr.push(figi)
        updateFilter(i,'value',arr)
        done = true
      }
    }
    if(!done){
      pushFilter(filters.length,{'attribute':'figi','operator':'not_in','value':[figi]})
    }
  }

  const runFilters = async (f,wo,wc,s) => {
    setLoading(true)
    var payload = JSON.parse(JSON.stringify(indexSpecifications))
    var recon = [{
      "trigger":{
        "schedule":{
          "type":"cron",
          "value":indexSpecifications.schedule
        }
      },
      "universe":filters,
      "weighting":{
        "objectives":[weightingObjective],
        "constraints":weightingConstraints
      }
    }]
    if(weightingObjective.type.includes('basket')){
      var recon = [{
        "trigger":{
          "schedule":{
            "type":"basket",
            "value":Object.keys(weightingObjective.value)
          }
        },
        "universe":[],
        "weighting":{
          "objectives":[{...weightingObjective}],
          "constraints":weightingConstraints
        }
      }]
    }
    if(weightingObjective.hasOwnProperty('universe')){
      recon.universe = []
    }
    if(weightingType == 'basket_unweighted'){
      var recon = [{
        "trigger":{
          "schedule":{
            "type":"basket",
            "value":Object.keys(weightingObjective.universe)
          }
        },
        "universe":[],
        "weighting":{
          "objectives":[{...weightingObjective}],
          "constraints":weightingConstraints
        }
      }]
    }
    payload.reconstitution = recon
    payload.schedule = true
    payload.full_backtest = false
    payload.team_id = teamId
    //var response = await getResult({universe:f,weighting:{objectives:[wo],constraints:wc,key_attr:'symbol'}})
    var response = await backtestTrigger(payload)
    console.log(response)
    //var response = await getResult(payload)
    if(response == null){
      setSnackbarMessage('An unknown error has occurred, please contact support')
      setSnackbarOpen(true)
      setLoading(false)
      return
    } else if (response.status === 504){
      setSnackbarMessage(response.message)
      setSnackbarOpen(true)
      setLoading(false)
      return
    }

    console.log(response)

    console.log(Object.keys(response))
    
    if(response.hasOwnProperty('data')){
      response = response.data
    }

    console.log(Object.keys(response))
    
    //console.log(response)
    if(response.hasOwnProperty('universe')){
      setSecurities(response.universe)
    }
    if(response.hasOwnProperty('attributes')){
      setAttributes(response.attributes)
    }
    if(response.hasOwnProperty('attribute_config')){
      console.log(response.attribute_config)
      setAttributeConfig(response.attribute_config)
    }
    if(response.hasOwnProperty('universe_length')){
      setUniverseLength(response.universe_length)
    }
    if(response.hasOwnProperty('weighting')){
      setWeighting(response.weighting)
      var wFixed = JSON.parse(JSON.stringify(response.weighting))
      for (const [key, value] of Object.entries(wFixed)) {
        wFixed[key] = value*100
      }
      setWeightingFixed(wFixed)
    }
    if(response.hasOwnProperty('schedule')){
      setSchedule(Object.values(response.schedule))
    }
    if(response.hasOwnProperty('weighting_logs')){
      setWeightingLogs(response.weighting_logs)
    }
    setLoading(false)
  }

  const runBacktest = async (live=false,websocketId=null) => {
    setBackdropOpen(true)
    setExpanded('results')
    //add recon
    var payload = JSON.parse(JSON.stringify(indexSpecifications))
    var recon = [{
      "trigger":{
        "schedule":{
          "type":"cron",
          "value":indexSpecifications.schedule
        }
      },
      "universe":filters,
      "weighting":{
        "objectives":[weightingObjective],
        "constraints":weightingConstraints
      }
    }]
    if(weightingObjective.type.includes('basket')){
      var recon = [{
        "trigger":{
          "schedule":{
            "type":"basket",
            "value":Object.keys(weightingObjective.value)
          }
        },
        "universe":[],
        "weighting":{
          "objectives":[{...weightingObjective}],
          "constraints":weightingConstraints
        }
      }]
    }
    if(weightingObjective.hasOwnProperty('universe')){
      recon.universe = []
    }
    if(weightingType == 'basket_unweighted'){
      var recon = [{
        "trigger":{
          "schedule":{
            "type":"basket",
            "value":Object.keys(weightingObjective.universe)
          }
        },
        "universe":[],
        "weighting":{
          "objectives":[{...weightingObjective}],
          "constraints":weightingConstraints
        }
      }]
    }
    payload.reconstitution = recon
    payload.full_backtest = true
    //transform benchmarks
    var transbench = payload.benchmark.map(item => item[2])
    payload.benchmark = transbench
    console.log(payload)

    if(live){
      payload.stage = 'live'
      if(!goLiveAllowed(teamData)){
        setSnackbarMessage('You have reached your live index limit, please contact support')
        setSnackbarOpen(true)
        setBackdropOpen(false)
      }
    }
    if(websocketId){
      payload.websocket_id = websocketId
    }
    payload.team_id = teamId

    console.log(payload)
    //return results
    var response = await backtestTrigger(payload)
    console.log(response)
    //var response = await getResult(payload)

    if(live){
      navigate(`/teamindices`)
      return
    }

    if(websocketId){
      return
    }

    if(response == null){
      setSnackbarMessage('An unknown error has occurred, please contact support')
      setSnackbarOpen(true)
      setBackdropOpen(false)
      return
    }
    response = response.data

    console.log(response)
    //setResponse(response)
    console.log('backtestdone')
    console.log(response.values)
    //setIndexValues([response.values])
    function reformat(timeArray,dataObj,toPct=false) {
      // list into list or arrays
      var data = timeArray.map(x=>({'time':x}))
      for (let i=0;i<data.length;i++){
        for (const [security,values] of Object.entries(dataObj)) {
          if(toPct){data[i][security] = values[i]*100}
          else{data[i][security] = values[i]}
        }
      }
      return data
    }

    var refObj = {}
    if(response.hasOwnProperty('warnings')){
      setWarnings(response.warnings)
    }
    if(response.hasOwnProperty('stats')){
      //build refObj
      for (const key of Object.keys(response.stats.value)) {
        refObj[key] = {
          selected:true,
          name:'asdf'
        }
      }
      setIndexRef(refObj)

      if(response.stats.hasOwnProperty('volatility')){
        var res = reformat(response.stats.time,response.stats.volatility)
        setVolatility(res)
      }
      if(response.stats.hasOwnProperty('value')){
        var data = response.stats.time.map(x=>({'time':x}))
        for (let i=0;i<data.length;i++){
          for (const [security,values] of Object.entries(response.stats.value)) {
            data[i][security] = values[i]
          }
        }
        setIndexValues(data)
      }
      if(response.stats.hasOwnProperty('drawdown')){
        var res = reformat(response.stats.time,response.stats.drawdown,true)
        setDrawdown(res)
      }
      if(response.stats.hasOwnProperty('performance_contribution')){
        var contrib = {}
        for (const [key, value] of Object.entries(response.stats.performance_contribution)) {
          contrib[key] = Object.entries(value).map((e) => ( { 'security':e[0],'return':e[1] } ));
        }
        console.log(contrib)
        setContribution(contrib)
      }
      if(response.stats.hasOwnProperty('weighting')){
        console.log(response.stats.weighting)
        var weighting = {}
        for (const [key, value] of Object.entries(response.stats.weighting)) {
          weighting[key] = Object.entries(value).map((e) => ( { 'name':e[0],'value':e[1] } ));
        }
        console.log(weighting)
        setWeightingResult(weighting)
      }
      if(response.stats.hasOwnProperty('total_turnover')){
        var totalTurnover = Object.entries(response.stats.total_turnover).map((e) => ( { 'time':e[0],'turnover':e[1]*100 } ))
        setTotalTurnover(totalTurnover)
      }
      if(response.stats.hasOwnProperty('rebalances')){
        console.log(response.stats.rebalances)
        var rebalanceRes = {}
        for (const [key, value] of Object.entries(response.stats.rebalances)) {
          var thisr = {
            weighting:Object.entries(value.weighting).map((e) => ( { 'name':e[0],'value':e[1] } ))
          }

          if(value.hasOwnProperty('performance_contribution')){
            thisr.performance_contribution = Object.entries(value.performance_contribution).map((e) => ( { 'security':e[0],'return':e[1]*100 } ))
          }
          if(value.hasOwnProperty('turnover')){
            thisr.turnover = Object.entries(value.turnover).map((e) => ( { 'security':e[0],'return':e[1]*100 } ))
          }
          rebalanceRes[key] = thisr
        }
        setRebalanceResults(rebalanceRes)
        setResultTabSelected(Object.keys(response.stats.rebalances)[Object.keys(response.stats.rebalances).length -1])
      }
    }
    setBackdropOpen(false)
  }

  return (
    (<Container maxWidth={false} style={{ padding: '1em', backgroundColor:'#fafafa' }} disableGutters>
      <Snackbar open={snackbarOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={6000} onClose={e=>setSnackbarOpen(false)} message={snackbarMessage}/>
      <SecurityDialog securityId={securitySelected} setDialogOpen={setSecurityDialogOpen} dialogOpen={securityDialogOpen}/>
      <Backdrop
        sx={{ color: '#fff', zIndex: 999}}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {returnDialog()}
      <div>
      <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'specifications'} onChange={e=> setExpanded('specifications')}>
      
      {expanded != 'specifications' && <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Specifications</Typography>
      </AccordionSummary>}
      <AccordionDetails sx={{margin:0,padding:0,minHeight:'80vh'}}>
      <Box sx={{ width:'100%',position:'absolute' }}>{loading&& <LinearProgress color='primary' sx={{marginTop:'-2px'}}/>}</Box>
      <Stack direction='column' alignItems='stretch' justifyContent='space-between' sx={{minHeight:'80vh'}}>
      <div>
      <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(e,v)=>setActiveTab(v)} aria-label="lab API tabs example" indicatorColor='primary' centered>
              <IconButton onClick={e=>setActiveTab(prevTab => Math.max(parseInt(prevTab)-1,1).toString())} aria-label="back">
                <ArrowBackIosIcon />
              </IconButton>
              <Tab label="Universe" value="1" disabled={customUniverse}/>
              <Tab label="Weighting" value="2" />
              <Tab label="Specifications" value="3"/>
              <IconButton onClick={e=>setActiveTab(prevTab => Math.min(parseInt(prevTab)+1,3).toString())} aria-label="forward">
                <ArrowForwardIosIcon />
              </IconButton>
            </TabList>

      </Box>
          <TabPanel value="1">
            {/*<LoadingButton loading={loading} onClick={e=>fetchResult()}>run</LoadingButton>*/}
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  sm: 8
                }}>
                <Stack direction='column' sx={{width:'100%'}} spacing={2}>
                  <Typography>Design an index universe using filters, or upload a custom universe</Typography>
                  <FormControlLabel control={<Switch checked={customUniverse} onChange={e=>{setCustomUniverse(e.target.checked);setActiveTab("2")}}/>} label="Use Custom Universe" />
                  <FilterList
                    filters={filters}
                    setFilters={setFilters}
                    attributes={attributes}
                    attributeConfig={attributeConfig}
                    universeLength={universeLength}
                  />
                </Stack>
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 4
                }}>
                <SecuritiesList
                  securities={securities}
                  removeSecurity={removeSecurity}
                  openSecurityDialog={openSecurityDialog}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Weighting
              teamId={teamId}
              weightingFixed={weightingFixed}
              setWeightingFixed={setWeightingFixed}
              attributeConfig={attributeConfig}
              weighting={weighting}
              universeFilters={filters}
              weightingObjective={weightingObjective}
              setWeightingObjective={setWeightingObjective}
              weightingConstraints={weightingConstraints}
              setWeightingConstraints={setWeightingConstraints}
              setWeightingDefinedHistorical={setWeightingDefinedHistorical}
              setWeightingHistoricalTab={setWeightingHistoricalTab}
              weightingHistoricalTab={weightingHistoricalTab}
              weightingDefinedHistorical={weightingDefinedHistorical}
              setUniverseDefinedHistorical={setUniverseDefinedHistorical}
              universeDefinedHistorical={universeDefinedHistorical}
              customUniverse={customUniverse}
              setCustomUniverse={setCustomUniverse}
              weightingFixedUnweighted={weightingFixedUnweighted}
              setWeightingFixedUnweighted={setWeightingFixedUnweighted}
              weightingType={weightingType}
              setWeightingType={setWeightingType}
              openDialog={openDialog}
            />
          </TabPanel>
          <TabPanel value="3">
            <IndexSpecifications
              spec={indexSpecifications}
              setSpec={setIndexSpecifications}
              schedule={schedule}
            />
          </TabPanel>
        </TabContext>
        </div>
      <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' sx={{margin:'1em'}}>
        {/*<Tooltip title='Save draft' placement='bottom'>
          <IconButton size='large' fontSize='large' onClick={e=>alert('save draft')} aria-label="delete">
            <SaveIcon  color='neutral' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Reset specifications' placement='bottom'>
          <IconButton size='large' fontSize='large' onClick={e=>alert('reset')} aria-label="delete">
            <DeleteOutlineIcon  color='secondary' />
          </IconButton>
        </Tooltip>
        */}
        <Tooltip title="Create Backtest"><span><Button variant='contained' onClick={e=>websocketConnect()} endIcon={<DoubleArrowIcon/>}>Create Backtest</Button></span></Tooltip>
      </Stack>
      </Stack>
      </AccordionDetails>
      
    </Accordion>
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'results'} onChange={e=> setExpanded('results')}>
      {expanded != 'results' && <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Results</Typography>
      </AccordionSummary>}
      <AccordionDetails>
        <Stack direction='column' spacing={3}>
      <Stack direction='row' justifyContent='space-between' sx={{width:'100%'}}>
      <Stack direction='row'>
      {Object.entries(indexRef).map(([key, value]) =>
        <FormControlLabel control={<Switch checked={value.selected} onChange={e=>setIndexRef(p=>({...p,[key]:{...p[key],selected:!value.selected}}))}/>} label={key} />
      )}
      </Stack>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <Grid><Tooltip title="Download"><IconButton onClick={e=>{setDialogOpen(true);setDialogTitle('Download Data');setDialogContentName('download_data')}}><DownloadIcon fontSize='small'/></IconButton></Tooltip></Grid>
        <Grid><Tooltip title="Go Live"><IconButton onClick={e=>{setDialogOpen(true);setDialogTitle('Launch Index');setDialogContentName('launch_index')}}><DoubleArrowIcon fontSize='small'/></IconButton></Tooltip></Grid>
      </Grid>
      </Stack>
      <Typography>Index Performance</Typography>
      <div style={{height:'600px',width:'100%'}}><SimpleChart data={indexValues} refData={indexRef}/></div>
      <Typography>Rolling Volatility</Typography>
      <div style={{height:'300px',width:'100%'}}><VolatilityChart data={volatility} refData={indexRef}/></div>
      <Typography>Drawdown</Typography>
      <div style={{height:'300px',width:'100%'}}><DrawdownChart data={drawdown} refData={indexRef}/></div>
      <Typography>Total Turnover</Typography>
      <div style={{height:'300px',width:'100%'}}><TotalTurnoverChart data={totalTurnover} refData={indexRef}/></div>
      
      <Stack direction='row' justifyContent='space-evenly' spacing={2}>
      {/*Object.entries(contribution).map(([key, value]) =>
        <Stack direction='column' alignItems='center'>
        <Typography>{key}</Typography>
        <div style={{height:`${Math.max(value.length*15,100)}px`,width:'400px'}}><ContributionChart data={value}/></div>
        </Stack>
      )*/}
      </Stack>

      <Stack direction='row' justifyContent='space-evenly' spacing={2}>
      {/*Object.entries(weightingResult).map(([key, value]) =>
        <Stack direction='column' alignItems='center'>
        <Typography>{key}</Typography>
        <div style={{height:"400px",width:'400px'}}><WeightingPie data={value}/></div>
        </Stack>
      )*/}
      </Stack>
      {/*tabs for time based stuff*/}
      <Typography>Rebalance Statistics</Typography>
      <Stack alignItems='center' justifyContent='center' direction='row' sx={{width:'100%'}}>
      <Tabs
        value={resultTabSelected}
        onChange={(e,v)=>setResultTabSelected(v)}
        sx={{maxWidth:'80vw'}}
        variant="scrollable"
        scrollButtons="auto"
      >
        {Object.keys(rebalanceResults).map((time)=>
          <Tab label={time} value={time}/>
        )}
      </Tabs>
      </Stack>
      {rebalanceResults.hasOwnProperty(resultTabSelected)&&
        <Grid container direction='row' alignItems='flex-start' justifyContent='center' spacing={2}>
          {rebalanceResults[resultTabSelected].turnover &&
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 3
              }}>
              <Typography variant="body1Heavy">Turnover</Typography>
                <div style={{height:'400px'}}>
                <TurnoverChart data={rebalanceResults[resultTabSelected].turnover}/>
                </div>
            </Grid>
          }
          {rebalanceResults[resultTabSelected].weighting &&
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 3
            }}>
            <Typography variant="body1Heavy">Constituents: {rebalanceResults[resultTabSelected].weighting.length}</Typography>
            <br/>
            <Typography variant="body1Heavy">Total Weight: {(rebalanceResults[resultTabSelected].weighting.map(o => o.value).reduce((a, b) => a + b, 0)*100).toFixed(2)}%</Typography>
              <div style={{height:'400px'}}>
              <WeightingPie data={rebalanceResults[resultTabSelected].weighting}/>
              </div>
            </Grid>
          }
          {rebalanceResults[resultTabSelected].performance_contribution &&
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 3
              }}>
              <Typography variant="body1Heavy">Performance Contribution</Typography>
              <div style={{height:'400px'}}>
                <ContributionChart data={rebalanceResults[resultTabSelected].performance_contribution}/>
              </div>
              </Grid>
          }
          {rebalanceResults[resultTabSelected].weighting &&
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 3
              }}>
              <Typography variant="body1Heavy">Full Weighting</Typography>
                <div style={{height:'400px'}}>
                  <WeightingDataGrid data={rebalanceResults[resultTabSelected].weighting}/>
                </div>
            </Grid>
          }
        </Grid>
        }
      <div>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Warnings</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
              <TableBody>
                {warnings.map((row,idx) => (
                  <TableRow
                    key={idx}
                  >
                    <TableCell component="th" scope="row">
                      {row}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
      </Stack>
      </AccordionDetails>
    </Accordion>
    </div>
    </Container>)
  );
}