import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Route, BrowserRouter, Routes, Navigate, useLocation, Outlet } from 'react-router-dom'
import ReactGA from 'react-ga4'
import './index.css'
import { styled, useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import {themeSpec,theme} from './theme'

import Home from './components/pages/frontend/Home'
import Solutions from './components/pages/frontend/Solutions'
import UseCases from './components/pages/frontend/UseCases'
import Resources from './components/pages/frontend/Resources'
import ResourcesId from './components/pages/frontend/ResourcesId'
import About from './components/pages/frontend/About'
import Pricing from './components/pages/frontend/Pricing'
import Dashboard from './components/pages/console/Dashboard'
import Files from './components/pages/console/Files'
import Billing from './components/pages/console/Billing'
import Login from './components/pages/frontend/Login'
import Contact from './components/pages/frontend/Contact'
import Terms from './components/pages/frontend/Terms'
import Register from './components/pages/frontend/Register'
import RegisterConfirm from './components/pages/frontend/RegisterConfirm'
import BacktestResults from './components/pages/console/BacktestResults'
import ResetPassword from './components/pages/frontend/ResetPassword'
import IndexBuilder from './components/pages/console/IndexBuilder';
import IndexCreate from './components/pages/console/IndexCreate';
import ImportValues from './components/pages/console/ImportValues';
import Indices from './components/pages/frontend/Indices';
import TeamOverview from './components/pages/console/TeamOverview';
import TeamApiKeys from './components/pages/console/TeamApiKeys';
import Support from './components/pages/console/Support';
import Streams from './components/pages/console/Streams';
import SecurityId from './components/pages/console/SecurityId';
import TeamIndices from './components/pages/console/TeamIndices';
import IndexOverview from './components/pages/frontend/IndexOverview';
import Documentation from './components/pages/frontend/Documentation';
import IndexEdit from './components/pages/console/IndexEdit';
//import IndexEvents from './components/pages/console/IndexEvents';
import IndexTrack from './components/pages/console/IndexTrack';
//import IndexEventId from './components/pages/console/IndexEventId';
import IndexEventCreate from './components/pages/console/IndexEventCreate';
import Careers from './components/pages/frontend/Careers'
import Pillar from './components/pages/frontend/Pillar'
import Integrations from './components/pages/frontend/Integrations'
import NotFound from './components/pages/frontend/NotFound'
import Services from './components/pages/frontend/Services'
import DirectIndexingPillar from './components/pages/frontend/DirectIndexingPillar'
import ESGPillar from './components/pages/frontend/ESGPillar'
import IndexFundsPillar from './components/pages/frontend/IndexFundsPillar'
import Rebalancing from './components/pages/frontend/Rebalancing'

//admin
import Users from './components/pages/admin/Users';

import Footer from './components/elements/layoutcomponents/Footer'
import TopMenu from './components/elements/layoutcomponents/TopMenu'
import { makeStyles } from '@mui/styles';


import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import Snackbar from '@mui/material/Snackbar'


//embed components
import WeightingChart from './components/elements/WeightingChart';
import StatsTable from './components/elements/StatsTable';
import IndexChart from './components/elements/IndexChart';
import PriceWidget from './components/elements/PriceWidget';


import { NotificationContext } from './components/elements/NotificationContext';
//import { useNotification } from './components/elements/NotificationContext';

//other
import CookieConsent from "react-cookie-consent";

const drawerWidth = 240;


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
  return null;
}

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  darklink: {
    color: '#353b41',
    fontWeight: 400
  },
  content: {
    flexGrow: 1
    //padding: theme.spacing(3),
  },
  RoutesWrapper: {
    position: 'absolute'
  }
}))


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: '#343a40 !important',
  color: 'white !important',
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#343a40 !important',
  overflowX: 'hidden',
  color: 'white !important',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex:99,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: 100,
  position:'fixed',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    color: 'white',
    zIndex: 99,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const customEqual = (prev,curr) => {
  return true
}
export default function App(props) {
  //const { createNotification } = useContext(NotificationContext)
  const dispatch = useDispatch()
  const authenticated = useSelector((state) => state.main.authenticated)
  //const authenticating = useSelector(state => state.main.authenticating)
  const snackbarMessage = useSelector(state => state.snackbarMessage,shallowEqual)
  const snackbarOpen = useSelector(state => state.snackbarOpen,shallowEqual)
  const userData = useSelector(state => state.main.userData ?? {},customEqual)


  useEffect(() => {(async () => {
    ReactGA.initialize('G-T48E8DYXZD')
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  })() },[])


  /*
  const logOut = () => {
    alert("LOGGING OUT")
    localStorage.clear()
    dispatch({ type: 'LOGOUT' })
    dispatch({ type: 'AUTHENTICATING', payload: { result: false } })
  }
  */


  /*
  useEffect(() => {
    (async () => {
      ReactGA.initialize('G-T48E8DYXZD')
      ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      ReactGA.event({
        category: "testevent",
        action: "testevent",
        label: "testevent", // optional
        value: 1, // optional, must be a number
      });
      ReactGA.event("testevent",{})
      //verify login status
      if (!authenticated) {
        const authToken = localStorage.getItem('authToken')
        if (authToken !== null) {
          dispatch({ type: 'AUTHENTICATE', payload: { result: true } })
        }
        const refreshToken = localStorage.getItem('refreshToken')
        if (refreshToken !== null) {
          const response = await refreshAuth(refreshToken)
          if (response !== null) {
            localStorage.setItem("authToken", response)
            dispatch({ type: 'AUTHENTICATE', payload: { result: true } })
            let userData = JSON.parse(localStorage.getItem('userData'))
            if (userData == null) {
              userData = await getSelf()
              console.log(userData)
              console.log("USERDATA")
              localStorage.setItem("userData", JSON.stringify(userData))
            }
            if (userData !== null) {
              dispatch({ type: 'STORE_USER', payload: userData })
              if (userData.primary_team !== undefined) {
                dispatch({ type: 'SET_ACTIVE_TEAM_ID', payload: { id: userData.primary_team } })
                localStorage.setItem("activeTeamId", userData.primary_team)
                const teamData = await getTeam(userData.primary_team)
                dispatch({ type: 'SET_ACTIVE_TEAM_DATA', payload: { data: teamData } })
                //dispatch({ type: 'SET_ACTIVE_TEAM_NAME', payload: { name: teamData.name } })

              }
            }
          } else {
            //refresh token failed, logging out
            logOut()
          }
        }
      }
      if (authenticating){
        dispatch({ type: 'AUTHENTICATING', payload: { result: false } })
      }


    })()
  }, [])
  */

  /*
  useEffect(() => {(async () => {
      //ReactGA.initialize('G-T48E8DYXZD')
      //ReactGA.send({ hitType: "pageview", page: window.location.pathname })
      //ReactGA.event({
      //  category: "testevent",
      //  action: "testevent",
      //  label: "testevent", // optional
      //  value: 1, // optional, must be a number
      //});
      //ReactGA.event("testevent",{})
      //verify login status
      if (!authenticated) {
      }
      if (authenticating){
        dispatch(logOut())
      }
  
   })() },[])
   */

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      var refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken !== null) {
        refreshAuth(refreshToken).then(response => {
          if (response === null){
            logOut()
          } else{
            localStorage.setItem("authToken", response)
          }
        })
      }
    }, 600000)
    return () => clearInterval(interval)
  }, [])
  */


  function AuthRoute() {
    if(authenticated){
      return(<Outlet/>)
    } else {
      return(<Navigate to={{pathname: "/login"}}/>)
    }
  }

  function AdminRoute() {
    if(userData.admin === true){
      return(<Outlet/>)
    } else {
      return(<Navigate to={{pathname: "/login"}}/>)
    }
  }



  function ReturnRoutes() {
    return (
      <Routes>
        {/* UNAUTHENTICATED PAGES */}
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/solutions' element={<Solutions/>} />
        <Route exact path='/terms' element={<Terms/>} />
        <Route exact path='/resources' element={<Resources/>} />
        <Route exact path='/resourceid/:resourceId' element={<ResourcesId/>} />
        <Route exact path='/pricing' element={<Pricing/>} />
        <Route exact path='/about' element={<About/>} />
        <Route exact path='/contact' element={<Contact/>} />
        <Route exact path='/login' element={<Login/>} />
        <Route exact path='/register' element={<Register/>} />
        <Route exact path='/registerconfirm' element={<RegisterConfirm/>} />
        <Route exact path='/resetpassword' element={<ResetPassword/>} />
        <Route exact path='/indices' element={<Indices/>} />
        <Route exact path='/backtestresults' element={<BacktestResults/>} />
        <Route exact path='/usecase/:solution' element={<UseCases/>} />
        <Route exact path='/index/:indexId/overview' element={<IndexOverview/>} />
        <Route exact path='/docs/api' element={<Documentation/>} />
        <Route exact path='/careers' element={<Careers/>} />
        <Route exact path='/pillar' element={<Navigate to={{pathname: "/customindexing"}}/>}/>
        <Route exact path='/customindexing' element={<Pillar/>} />
        <Route exact path='/integrations' element={<Integrations/>} />
        <Route exact path='/services' element={<Services/>} />
        <Route exact path='/directindexing' element={<DirectIndexingPillar/>} />
        <Route exact path='/esg' element={<ESGPillar/>} />
        <Route exact path='/indexfunds' element={<IndexFundsPillar/>} />
        <Route exact path='/Rebalancing' element={<Rebalancing/>} />

        {/* EMBED PAGES */}
        <Route exact path='/embed/index/:indexId/weighting' element={<WeightingChart/>} />
        <Route exact path='/embed/index/:indexId/stats' element={<StatsTable/>} />
        <Route exact path='/embed/index/:indexId/chart' element={<IndexChart/>} />
        <Route exact path='/embed/index/:indexId/pricewidget' element={<PriceWidget/>} />
        
        {/* AUTHENTICATED PAGES */}
        <Route element={<AuthRoute/>}>
          <Route exact path='/dashboard' element={<Dashboard/>} />
          <Route exact path='/files' element={<Files/>} />
          <Route exact path='/indexbuilder' element={<IndexBuilder/>} />
          <Route exact path='/indexcreate' element={<IndexCreate/>} />
          <Route exact path='/billing' element={<Billing/>} />
          <Route exact path='/team' element={<TeamOverview/>} />
          <Route exact path='/teamkeys' element={<TeamApiKeys/>} />
          <Route exact path='/support' element={<Support/>} />
          <Route exact path='/streams' element={<Streams/>} />
          <Route exact path='/teamindices' element={<TeamIndices/>} />
          <Route path='/index/:id'>
            <Route exact path='/index/:id/edit' element={<IndexEdit/>} />
            <Route exact path='/index/:id/importvalues' element={<ImportValues/>} />
            <Route exact path='/index/:id/track' element={<IndexTrack/>} />
            <Route exact path='/index/:id/newevent' element={<IndexEventCreate/>} />
          </Route>
          <Route exact path='/securities/:id/overview' element={<SecurityId/>} />
        </Route>
          {/* ADMIN PAGES */}
        <Route element={<AdminRoute/>}>
          <Route exact path='/admin/users' element={<Users/>} />
        </Route>
          {/* 404 */}
        <Route path='*' element={<NotFound/>} />
      </Routes>
    )
  }

 
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Snackbar open={snackbarOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={6000} onClose={e=>dispatch({ type: 'SNACKBAR', payload: { open: false } })} message={snackbarMessage}/>
        <div style={{display:'flex'}}>
          {
            String(window.location.pathname).startsWith('/embed') ?
              <ReturnRoutes/>
              //authenticating ?
              //    ""
              //  :
              //    <ReturnRoutes/>
            : String(window.location.pathname).startsWith('/docs') ?
              <ReturnRoutes/>
            : <>
              <TopMenu/>
              <Container disableGutters maxWidth={false} sx={{ minHeight: '130vh', display: 'flex', flexDirection: 'column', backgroundColor:'#fafafa' }}>
                {/*<Notifier />*/}
                {/* below div is to place content below appbar */}
                <div style={{ height: 40, marginBottom: 0 }} />
                {/*!authenticating && <ReturnRoutes/>*/}
                <ReturnRoutes/>
                <div style={{ flexGrow: 1, backgroundColor:'#fafafa' }} />
                <Footer />
              </Container>
            </>
          }
          {!String(window.location.pathname).startsWith('/embed') && <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>}
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )
}