import {store} from '../../store/store'
import { indexOneApi } from '../../store/indexOneApi'

const uploadFile = async (
        fileData,
        fileName,
        fileExtension,
        filePath="",
        teamId,
        metaData={},
        table='team',
        bucket='file_bucket'
    ) => {
    var response = {}

    //set content type header
    var contentType = 'text/csv'
    if(fileExtension === 'csv'){contentType = 'text/csv'}
    else if (fileExtension === 'py'){contentType = 'application/x-python-code'}

    var finalName = `${fileName}.${fileExtension}`
    var filePath = `${teamId}/${filePath}${finalName}`

    var body = {
      bucket:bucket,
      file_name:filePath,
      file_type:contentType,
      owner_id:teamId,
      table:table,
      metadata:metaData
    }
    const options = {
      headers: {
        'Content-Type': contentType
      }
    }

    var textFileAsBlob = new Blob([fileData], {type: contentType})
    var file = new File([textFileAsBlob], finalName)

    try{
      const importResponse = await store.dispatch(indexOneApi.endpoints.fileUpload.initiate(body))
      console.log("IMPORTRESP")
      console.log(importResponse)
      response = response.data
      fetch(importResponse.data.url,{
        method:"PUT",
        headers:{'Content-Type':contentType},
        body:file
      })
    } catch (error){
      console.log(error)
      response = {"status":400}
    }

    
    /*
    const importResponse = await store.dispatch(indexOneApi.endpoints.fileUpload.initiate({body}))
    alert(JSON.stringify(importResponse))
    var textFileAsBlob = new Blob([fileData], {type: contentType})
    var file = new File([textFileAsBlob], finalName)
      api.post(`file/import`,body).then(apiResponse => {
        console.log(apiResponse)
        response = response.data
        store.dispatch({ type: 'APPEND_FILES', payload: apiResponse.data.team_data.files })
        //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Successful'}})
        fetch(apiResponse.data.url,{
          method:"PUT",
          headers:{'Content-Type':contentType},
          body:file
        })
        //axios.put(apiResponse.data.url, file,options).then((s3Response) => {
        //  console.log(s3Response)
        //});
      }, error => {
        console.log(error)
        //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Failed'}})
        response = {"status":400}
      })
    */
    return response
  }

const downloadFile = async (url) => {
    const result = await fetch(url,{
      method:"GET"
    })
    const fil =  await result.text()
    return fil
  }

const deleteFile = async (fileName) => {
  const state = store.getState();
  const teamId = state.main.teamData.id;
  try{
    const response = await store.dispatch(indexOneApi.endpoints.fileDelete.initiate({id:teamId,file_name:fileName}))
    return response
  } catch (error){
    console.log(error)
    return error
  }
}

const parseUniverseFile = (fileData,fileFormat) => {
  //parses a csv file in raw format into symbol array and datagrid formats
  const thisRows = fileData.split("\n")
  const securities = new Set();
  const headerSpec = ["has_time","has_security","has_weight"]
  const hasHeaders = fileFormat.has_headers ?? false
  const identifier = fileFormat.identifier ?? false
  const weightFormat = fileFormat.weight_format ?? "decimal"

  var columns = []

  for (let header of headerSpec) {
    if(fileFormat[header]){
      columns.push(header.split("_")[1])
    }
  }
  const rowsFormatted = []
    for (let i = 0; i < thisRows.length; i++) {
      if (hasHeaders === true && i === 0){continue}
      if (thisRows[i].length === 0){continue}
      const row = thisRows[i].trim().split(',');
      const rowData = {id:i};
      for (let j = 0; j < columns.length; j++) {
        if(columns[j] === "security"){securities.add(row[j])}
        rowData[columns[j]] = row[j]
      }
      rowsFormatted.push(rowData);
    }

  return({
    data:fileData,
    rows:rowsFormatted,
    columns:columns,
    has_headers:hasHeaders,
    securities:Array.from(securities),
    identifier:identifier
  })
}

export {
    uploadFile,
    downloadFile,
    parseUniverseFile,
    deleteFile
}