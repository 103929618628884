import React, { useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import { Grow } from '@mui/material'
import { FixedSizeList } from 'react-window';

function SecuritiesListMemo(props) {
  function returnListRow({data,index,style}) {
    const item = data[index]
    return(
      <Grow
        in={true}
        timeout={Math.min(index*100,1000)}
        style={style}
        key={item.figi}
        >
        <ListItem
          sx={{cursor:'pointer'}}
          alignItems="flex-start"
          secondaryAction={
            <IconButton size='small' edge="end" aria-label="delete" onClick={e=>props.removeSecurity(item.figi)}>
              <ClearIcon fontSize='small' />
            </IconButton>
          }
        >
          <ListItemAvatar onClick={e=>props.openSecurityDialog(item.figi)}>
            <Avatar alt={item.symbol} src={`https://storage.googleapis.com/iex/api/logos/${item.symbol}.png`} />
          </ListItemAvatar>
          <ListItemText
            onClick={e=>props.openSecurityDialog(item.figi)}
            primary={item.symbol}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.company_name}
                </Typography>              
              </React.Fragment>
            }
          />
        </ListItem>
        </Grow>
    )}

  return (
    <FixedSizeList
    height={window.innerHeight*0.7}
    width={'100%'}
    maxWidth={560}
    itemSize={60}
    itemCount={props.securities.length}
    overscanCount={1}
    itemData={props.securities}
  >
    {returnListRow}
        {/*<List sx={{ width: '100%', bgcolor: 'background.paper',maxHeight: '80vh',overflow:'auto' }}>
      {props.securities.map((item, index) => (
      <Grow
        in={true}
        timeout={Math.min(index*1000,10000)}
      >
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={item.iex_symbol} src={`https://storage.googleapis.com/iex/api/logos/${item.iex_symbol}.png`} />
        </ListItemAvatar>
        <ListItemText
          primary={item.iex_symbol}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.company_name}
              </Typography>              
            </React.Fragment>
          }
        />
      </ListItem>
      </Grow>
      ))}
     
        </List>*/}
  </FixedSizeList>
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
 if (prevProps.securities == nextProps.securities){
   return true
 } else {
   return false
 }
}

const SecuritiesList = React.memo(SecuritiesListMemo,areEqual);
export default SecuritiesList