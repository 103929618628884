import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ContactForm from '../../elements/ContactForm'

export default function Documentation(props) {
  document.title = `Index One | About`
  return (
    <Container component="main" disableGutters maxWidth={false}>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'40vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
        <Typography style={{color:'#fafafa', margin:'1rem'}} variant="h3">About Us</Typography>
    </Box>
    <Container maxWidth='md' style={{padding:'50px 1rem'}}>

    <Typography variant='h4'>Solutions</Typography>
    <Typography>
      Index One provides scalable index calculation, design and distribution solutions for index users globally.
      Our cloud based indexing infrastructure enables all levels of customization while ensuring complete accuracy and reliability. Whether clients have specific requirements, or just an initial idea, our extremely flexible platform allows us to deliver any type of indexing.
    </Typography>
    <br/><br/><br/>
    <Typography variant='h4'>Our Philosophy</Typography>
    <Typography>
      We aim to bring better performance, transparency, and cost savings to the index industry through use
      of cutting edge technology, and a dedication to automation.
    </Typography>

    </Container>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
        <ContactForm/>
      </Box>
    </Container>
  );
}