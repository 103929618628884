
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
//import * as serviceWorker from '../public/serviceWorker'
import { Provider } from 'react-redux'
import { NotificationProvider } from './components/elements/NotificationContext';
import { store } from './store/store';

ReactDOM.render(<Provider store={store}><NotificationProvider><App /></NotificationProvider></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
