import React, { useState, useEffect } from 'react';
import { useStore, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { useIndexQuery,useIndexValuesQuery, useIndicesQuery } from '../../../store/indexOneApi';
import config from '../../../config'

import { makeStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';

import awsdataexchange from '../../../images/awsdataexchange.png'
import battlefin from '../../../images/battlefin.png'
import datarade from '../../../images/datarade.png'
import eaglealpha from '../../../images/eaglealpha.png'
import lseg from '../../../images/lseg.png'
import gristmill from '../../../images/gristmill.png'
import neudata from '../../../images/neudata.png'
import kitces from '../../../images/kitces.png'
import edi from '../../../images/edi.png'
import cbi from '../../../images/cbi.png'
import kanataq from '../../../images/kanataq.png'

import ContactForm from '../../elements/ContactForm'
import HorizontalTabs from '../../elements/HorizontalTabs'
import VerticalStepper from '../../elements/VerticalStepper'

import Ticker from 'react-ticker'

//stat icons
//import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';



//table imports
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary, { getAccordionSummaryUtilityClass } from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { CardActionArea } from '@mui/material';

//homecarousel
import HomeCarousel from '../../elements/HomeCarousel.js'

//howitworksstepper
import HowItWorksStepper from '../../elements/HowItWorksStepper.js'




const useStyles = makeStyles({
  hometickers: {
    padding: '50px',
    paddingBottom: '30px',
  },
  fullScreenLander: {
    height: window.innerHeight,
    backgroundColor: '#343a40',
    position: 'relative',
  },
  landerFill: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: "1"
  },
  landertext: {
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  diagonal: {
    backgroundImage: 'linear-gradient(to top left, #fafafa calc(50% - 1px), #343a40 50%)',
    height: '20vh',
    paddingTop: '-1px',
    marginTop: '-1px'
  },
  logos: {
    maxWidth: '200px'
  }
})

const stats = [
  {
    value: '3,000,000',
    text: 'Index values calculated daily',
    unit: '+',
    icon: CandlestickChartOutlinedIcon
  },
  {
    value: '250,000',
    text: 'Securities covered',
    unit: '+',
    icon: InsertChartOutlinedIcon
  },
  {
    value: 4,
    text: 'Years of live calculation',
    unit: '+',
    icon: CalendarTodayOutlinedIcon
  },
  {
    value: 100,
    text: 'Live indices',
    unit: '+',
    icon: CellTowerOutlinedIcon
  },
  {
    value: 99.99,
    text: 'Accuracy rate',
    unit: '%',
    icon: CheckBoxOutlinedIcon
  },
  {
    value: 3,
    text: 'Average time to build an index',
    unit: 'min',
    icon: AccessTimeOutlinedIcon
  },
  {
    value: 100,
    text: 'Corporate actions handled daily',
    unit: '+',
    icon: LineAxisOutlinedIcon
  },
  {
    value: 180,
    text: 'Exchanges covered',
    unit: '+',
    icon: CompareArrowsOutlinedIcon
  }
]


const tableRows = [
  {
    title: 'Low-cost provider',
    text: 'We offer the most cost-effective calculation solution in the industry, in order to drive higher accessibility and index ownership.'
  },
  {
    title: 'Custom indexing',
    text: 'A generic index does not fit every investment objective. We provide a fully flexible and customizable indexing platform, enabling custom index design with the ability to define universes, weightings, and more.'
  },
  {
    title: 'ESG investing',
    text: 'There is a lack of standards in the ESG investing space as most index providers prescribe their own ESG ratings. We offer the ability to import external ratings on demand.'
  },
  {
    title: 'Flexible rebalancing',
    text: 'Index One offers fully automated rebalancing based on predefined rules at any frequency and schedule, supporting a wide range of methodologies.'
  },
  {
    title: 'Zero licensing fees',
    text: 'Index One only charges users for calculation, without any AUM-based licensing fees. Users own the indices they build.'
  },
  {
    title: 'Easy accessibility',
    text: 'Index One supports multiple integration and dissemination options. Users retain full control over internal or external index distribution.'
  }
]

const returnStat = (value, text, unit, Icon) => {
  return (
    <Stack direction='column' alignItems='center' justifyContent='center'>
      <Icon sx={{ fontSize: 50, color: '#4ecdc4' }} />
      <Typography variant='h4'>{value}{" "}{unit}</Typography>
      <Typography>{text}</Typography>
    </Stack>
  )
}

const CustomLabel = (data) => {
  return (<Typography variant='h3' style={{ color: 'white' }}>{JSON.stringify(data)}</Typography>)
}

export default function Home(props) {
  document.title = `Index One | Home`
  const store = useStore().getState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState([])
  const {data:landerIndexValues = []} = useIndexValuesQuery({id:config.landerIndex,limit:400})
  const {data:indicesResponse = []} = useIndicesQuery({duration:172800,increment:"1d",featured_performance:true,attributes:"limited",visibility:"featured"})
  const [indices, setIndices] = useState([
    //{'name':'i1 US All Cap'},
    //{'name':'i1 US Large Cap'},
    //{'name':'i1 US Mid Cap'},
    //{'name':'i1 US Small Cap'}
  ])
  const handleClick = (id) => {
    navigate(`index/${id}/overview`)
  }
  const isoToUnix = (x) => { return Math.round(new Date(x['time']).getTime() / 1000) }

  useEffect(() => {(async () => {
      dispatch({ type: 'SET_PAGE_TITLE', payload: '' })
      try {
          var idxlist = []
          for (const index of indicesResponse) {
              if (index.data.length > 1) {
                var r = (((index.data[index.data.length - 1].value / index.data[index.data.length - 2].value) - 1) * 100).toFixed(2)
              } else {
                var r = (0).toFixed(2)
              }
              idxlist.push({
                name: index.name,
                id: index.id.concat('-', index.version),
                ret: r
              })
          }
          setIndices(idxlist)
      } catch (e) {
        console.log(e)
      }
  })()}, [indicesResponse])

  return (
    <Container disableGutters maxWidth={false} style={{ marginTop: '-46px' }}>
      <CssBaseline />
      <div className={classes.fullScreenLander} >
        <div className={classes.landerFill}>
          <Container className={classes.landertext} maxWidth='sm'>
            <Typography style={{ color: '#fafafa', textShadow: '0px 0px 5px #353b41 , 0px 0px 5px #353b41 , 0px 0px 5px #353b41' }} variant="h2"><b style={{ fontWeight: 300 }}>index</b>one</Typography>
            <Typography style={{ color: '#fafafa', "backgroundColor": "rgba(53, 59, 65, 0.8)" }} variant="h5">The modern custom indexing platform.
            </Typography>
            <br/>

            <HomeCarousel/>

            <br/>
            <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
              <Button sx={{ width: '150px' }} color='secondary' onClick={e => navigate(`/indexbuilder`)} variant="outlined">Index Builder</Button>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/indices`)} variant="outlined">View all indices</Button>
            </Stack>
          </Container>
          <br/>
         
          <div style={{ bottom: 0, position: 'absolute', width: '100%', opacity: `${indices.length > 0 ? 1 : 0}`, transition: 'opacity 1.5s' }}>
            {indices.length > 0 &&
              <Ticker speed={8}>
                {({ index }) => (
                  <ButtonBase key={index % indices.length} onClick={e => handleClick(indices[index % indices.length].id)} style={{ padding: 50 }}>
                    <Typography variant='body1' style={{ color: 'white' }} >{indices[index % indices.length].name}&nbsp;</Typography>
                    <Chip
                      size='small'
                      color={indices[index % indices.length]['ret'] > 0 ? 'primary' : 'secondary'}
                      icon={indices[index % indices.length]['ret'] > 0 ? <KeyboardArrowUpIcon style={{ fontSize: '30px' }} /> : <KeyboardArrowDownIcon style={{ fontSize: '30px' }} />}
                      label={indices[index % indices.length]['ret'].concat(' %')}
                    />
                  </ButtonBase>
                )}
              </Ticker>
            }
          </div>
        </div>
        {landerIndexValues.length > 0 &&
          <ResponsiveContainer fill="#343a40" height='100%' width='100%'>
            <LineChart
              fill="#343a40"
              data={landerIndexValues}
              margin={{ top: 100, right: 0, left: 0, bottom: 100 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="30%" stopColor="#4ecdc4" />
                  <stop offset="100%" stopColor="#fc4798" />
                </linearGradient>
              </defs>
              <Line dot={false} animationDuration={3000} animationEasing='linear' type="monotone" dataKey="value" stroke="url(#colorUv)" strokeWidth={2} fillOpacity={1} fill="#343a40" />
              {/*showLine &&
                <ReferenceLine y={data[data.length - 1].value} label={{ position: 'insideBottomRight', value: `i1 Global ${data[data.length - 1].value && data[data.length - 1].value.toFixed(2)}`, fill: '#fafafa', fontSize: 17 }} stroke="#fc4798" strokeDasharray="10 10" />
              */}
              <XAxis hide={true} scale='auto' dataKey={isoToUnix} domain={['auto', 'auto']} />
              <YAxis hide={true} dataKey="value" domain={['auto', 'auto']} />
            </LineChart>

          </ResponsiveContainer>
        }
      </div>

      <Container disableGutters maxWidth={false} sx={{ backgroundColor: '#fafafa' }}>
        <div className={classes.diagonal} />
        <Container disableGutters maxWidth='md' sx={{ padding: '30px 0 30px 0' }}>
        <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Build any Index.</Typography>

        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ marginTop: '10px'}}>
          <Typography variant='h4' style={{ textAlign: 'left', margin: '1rem' }}>
            <b style={{ fontWeight: 300 }}>index</b>one provides a trusted holistic index calculation platform delivering fast, flexible and scalable index solutions.
          </Typography>
          
          
          <iframe width="1500" height="300" src="https://www.youtube.com/embed/5vyqKyJge9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Stack>

          <br/>
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
          <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Get started</Button>
          <Button sx={{ width: '150px' }} onClick={e => navigate(`/indices`)} variant="outlined">View all indices</Button>
          </Stack>
        </Container>

     
        <br/>

        <Container disableGutters maxWidth='md' sx={{ padding: '30px 0 30px 0' }}>
    <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>How it works</Typography>
    <br />
    <HowItWorksStepper sx={{ width: '100%' }} /> 
    <br/>
    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Get started</Button>
    </Stack>
</Container>


        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Stats at a Glance</Typography>
            <Grid container spacing={4}>
              {stats.map((stat) => (
                <Grid size={{xs:6,md:3}}>
                  {returnStat(stat.value, stat.text, stat.unit, stat.icon)}
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>




        <Container disableGutters maxWidth={false} sx={{ backgroundColor: '#fafafa'}}>
        <Container disableGutters maxWidth='md' sx={{ padding: '30px 0 30px 0' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Why <b style={{ fontWeight: 300 }}>index</b>one?</Typography>
            <br/>

            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><b>Zero licensing fees</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Index One only charges users for calculation, without any AUM-based licensing fees. Users own the indices they build. 
              </Typography>
            </AccordionDetails>
          </Accordion>

          
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >

              <Typography><b>Custom indexing and benchmarks</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              A generic index does not fit every investment objective. We provide a fully flexible and customizable indexing platform, with the ability to define universes, weightings, and more.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"

            >

              <Typography><b>Fully flexible rebalancing</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Index One offers fully automated rebalancing based on predefined rules at any frequency and schedule, supporting a wide range of methodologies. 

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography><b>ESG investing</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              There is a lack of standards in the ESG investing space as most index providers prescribe their own ESG ratings. We offer the ability to import external ratings on demand. 

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography><b>Self-service</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              The Index One platform is a very simple and straightforward platform to use- this allows advisors to build indices on their own, with minimal manual help from our team, although we are always ready to help you if any questions arise. 
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography><b>Easy accessibility</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Index One supports multiple integration and dissemination options. Users retain full control over internal or external index distribution. 
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography><b>Lowest cost in the industry</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              We offer the most cost-effective calculation solution in the industry, in order to drive higher accessibility and index ownership.
              </Typography>
            </AccordionDetails>
          </Accordion>

<br/>
<Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
          <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
          <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Get started</Button>
          </Stack>

          </Container>
        </Container>
        </Container>


        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
      
          <Container alignItems="center" justifyContent="center">
            <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '1rem' }}>Key Features</Typography>
            <VerticalStepper />
          </Container>
          
        </div>


        <Container disableGutters maxWidth={false} sx={{ backgroundColor: '#fafafa'}}>
        <Container disableGutters maxWidth='lg' sx={{ padding: '30px 0 30px 0' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Testimonials</Typography>
            <Typography variant='h4' style={{ textAlign: 'center', margin: '1rem' }}>
            Hear what our clients have to say about <b style={{ fontWeight: 300 }}>index</b>one.
            </Typography>

                <br/>
            <Grid container justifyContent='space-evenly' alignItems='center' spacing={1}>

            <Grid>
            <iframe width="350" height="315" src="https://www.youtube.com/embed/vDT2NUZUQ4s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Grid>

  <Grid>
  <iframe width="350" height="315" src="https://www.youtube.com/embed/DnsvF_wNwn8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Grid>

  <Grid>
  <iframe width="350" height="315" src="https://www.youtube.com/embed/ccLgpkan8W8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </Grid>

  </Grid>

<br/><br/>
<Typography variant='h4' style={{ textAlign: 'center', margin: '1rem' }}>Register to Access Our Latest Webinar</Typography>
<Container maxWidth="md" sx={{height:"600px"}}>
<div style={{width:"100%", height:"0px", position:"relative", paddingBottom:"56.25%"}}><iframe src="https://streamyard.com/watch/6CtzfFnVNsfk?embed=true" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" style={{width:"100%", height:"100%", position:"absolute",left:"0px", top:"0px", overflow:"hidden"}}></iframe></div>
<br/><br/>
<Stack alignItems="center" justifyContent="center">
           <Button onClick={e => navigate(`/resources`)} variant="outlined" size="large">Access case studies, whitepapers and more</Button>
           </Stack>
</Container>

        
          </Container>
          </Container>
        </Container>
        


        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='md'>
        <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>About <b style={{ fontWeight: 300 }}>index</b>one</Typography>
        <Typography variant='h5'  style={{ textAlign: 'center', margin: '1rem' }}>
           <br/><b style={{ fontWeight: 300 }}>index</b>one is an index calculation platform that that enables seamless backtesting, calculation, and maintenance of indices, allowing users to transform any strategy into a fully customizable index. 
            <br/>
            <Typography variant='h5'  style={{ textAlign: 'center', margin: '1rem' }}>
            <br/>Designed for simplicity, speed, and efficiency, <b style={{ fontWeight: 300 }}>index</b>one  offers a hands-off approach to index construction.
            </Typography>
            <Typography variant='h5' style={{ textAlign: 'center', margin: '1rem' }}>
            <br/><b style={{ fontWeight: 300 }}>index</b>one's comprehensive self-service platform provides high flexibility and customization for constructing, calculating, and maintaining indices in a cost-effective manner.
           </Typography>
          </Typography>
          <br/>
             <Stack alignItems="center" justifyContent="center">
           <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button>
           </Stack>
          </Container>
          </div>
 
  

          <Container disableGutters maxWidth={false} sx={{ backgroundColor: '#fafafa'}}>
        <Container disableGutters maxWidth='md' sx={{ padding: '30px 0 30px 0' }}>
<Container maxWidth={"sx"}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>Data Venues</Typography>
        <br/>
        <Grid container justifyContent='space-evenly' alignItems='center' spacing="10px">
          <Grid><img className={classes.logos} src={battlefin} /></Grid>
          <Grid><img className={classes.logos} src={awsdataexchange} /></Grid>
          <Grid><img className={classes.logos} src={datarade} /></Grid>
          <Grid><img className={classes.logos} src={eaglealpha} /></Grid>
          <Grid><img className={classes.logos} src={lseg} /></Grid>
          <Grid><img className={classes.logos} src={gristmill} /></Grid>
          <Grid><img className={classes.logos} src={neudata} /></Grid>
          <Grid><img className={classes.logos} src={kitces} /></Grid>
          <Grid><img className={classes.logos} src={edi} /></Grid>
          <Grid><img className={classes.logos} src={cbi} /></Grid>
          <Grid><img className={classes.logos} src={kanataq} /></Grid>
        </Grid>
        </Container>
        </Container>
          </Container>


      </Container>



      <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='md'>
      <Typography variant='h4' maxWidth='md' style={{ textAlign: 'center', margin: '1rem' }}>
      <br/><b style={{ fontWeight: 300 }}>index</b>one is democratizing the world of indexing through fast and flexible index calculation tools.
      <br/>
      <Typography variant='h5' maxWidth='md' style={{ textAlign: 'center', margin: '1rem' }}>
      <br/>Find out more about the <b style={{ fontWeight: 300 }}>index</b>one backtesting and index calculation platform for your business. 
      </Typography>
      </Typography>
        <ContactForm />
     
    </Container>
    </div>


    
    <Typography variant='h4' style={{ textAlign: 'center', margin: '1rem' }}>Learn</Typography>
  <Container maxWidth={"lg"}>
   

    <Grid container spacing={1} alignItems = "stretch">

    <Grid size={3}>
    <Card sx={{height:'100%'}}>
          <CardActionArea sx={{height:'100%'}}>
        <CardMedia
          height="140"
        />
         
         <CardContent onClick={e => navigate(`/pillar`)}>
          <Typography gutterBottom variant="h5" component="div">
          The Ultimate Guide to Direct Indexing
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Learn everything you need to know about direct indexing,tax-loss harvesting, and why investors are increasingly considering direct indexing strategies.
          </Typography>
        </CardContent>
      </CardActionArea>
      </Card>
      </Grid>


      <Grid size={3}>
      <Card sx={{height:'100%'}}>
          <CardActionArea sx={{height:'100%'}}>
        <CardMedia
          height="140"
        />
         
         <CardContent onClick={e => navigate(`/rebalancing`)}>
          <Typography gutterBottom variant="h5" component="div">
          Rebalancing and Corporate Actions
          </Typography>
          <Typography variant="body2" color="text.secondary">
          We explore rebalancing an index and the importance of corporate action treatments.
          </Typography>
        </CardContent>
      </CardActionArea>
      </Card></Grid>


      <Grid size={3}>
        <Card sx={{height:'100%'}}>
          <CardActionArea sx={{height:'100%'}}>
        <CardMedia
          height="140"
        />
         
         <CardContent onClick={e => navigate(`/esg`)}>
          <Typography gutterBottom variant="h5" component="div">
          ESG and Sustainable Indices
          </Typography>
          <Typography variant="body2" color="text.secondary">
          In this comprehensive guide, we'll explore the core concepts of ESG indices, sustainable investing, custom indexing in ESG, and how to build an ESG index.
          </Typography>
        </CardContent>
      </CardActionArea>
      </Card></Grid>



      <Grid size={3}>
        <Card sx={{height:'100%'}}><CardActionArea sx={{height:'100%'}}>
        <CardMedia
          height="140"
        />
         
         <CardContent onClick={e => navigate(`/indexfunds`)}>
          <Typography gutterBottom variant="h5" component="div">
          Low-cost index funds
          </Typography>
          <Typography variant="body2" color="text.secondary">
          We explore the fundamental concept of index funds, explaining how they work and their unique investment approach.
          </Typography>
        </CardContent>
      
      </CardActionArea>
      </Card>
      </Grid>

</Grid>
<br/><br/>
    
    </Container>
    


    </Container>
  );
}