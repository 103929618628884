function csvToArray(str, delimiter = ",") {

    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    console.log(headers)

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      let values = row.split(delimiter);

      //convert numbers to numbers
      for (let i = 0; i < values.length; i++) {
        if(!isNaN(values[i])){
          values[i] = parseFloat(values[i])
        }
      }
      
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

function csvWeightsToArray(str, delimiter = ",") {

    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let res = {}
    for (let i = 0; i < headers.length; i++) {
      if(headers[i] !== ''){
        res[headers[i]] = {}
      }
    }
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    console.log(headers)
    console.log(res)
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values.length <= 1){continue}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(!isNaN(v)){
          v = parseFloat(values[ii])
          if(v > 0){
            res[headers[ii]][values[0]] = v
          }
        }
      }
    }
    return res;
  }
  
function weightsToArray(str,delimiter=","){
    console.log(str)
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let headerNames = ["ticker","id","weight","symbol"]
    let res = []
    let startIndex = 0
    var containsAny = headerNames.some(i => headers.includes(i))
    if(containsAny){
      startIndex = 1
    } else {
      startIndex = 0
      headers = ['id','weight']
    }

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    console.log(str)
    let rows = []
    if(startIndex == 0){
      rows = str.split("\n");  
    } else {
      rows = str.slice(str.indexOf("\n") + 1).split("\n");
    }
    console.log(headers)
    console.log(res)
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values.length <= 0){continue}
      let thisRow = {}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(!isNaN(v)){
          v = parseFloat(values[ii])
        }
        thisRow[headers[ii]] = v
      }
      res.push(thisRow)
    }
    return res;
}

export {csvToArray,csvWeightsToArray,weightsToArray}