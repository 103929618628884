import React from "react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import colors from '../../helpers/colors'
import CustomizedAxisTick from './CustomAxis'

function VolatilityChartf(props) {

  return (
    <ResponsiveContainer width="100%" height="100%">
        <LineChart
        width='100%'
        height='100%'
        data={props.data}
        syncId="anyId"
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={60} dataKey='time' tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        {Object.keys(props.refData).filter(x=>props.refData[x].selected == true).map((security,idx) => (
            <Line type="monotone" dataKey={security} stroke={colors[idx]} dot={false}/>
        ))}
        </LineChart>
    </ResponsiveContainer>
  );
}

const VolatilityChart = React.memo(VolatilityChartf);
export default VolatilityChart