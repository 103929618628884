import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Typography, Fab, Stack, Divider } from '@mui/material'
import Grid from "@mui/material/Grid2"

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mathOperators = [
  ['Equals','='],
  ['Larger Than','>'],
  ['Smaller Than','<'],
  ['Larger Than / Equal','>='],
  ['Smaller Than / Equal','<='],
  ['Not Equals','<>'],
  ['Between','between'],
  ['Not Between','not_between']
]
const arrayOperators = [
  ['In','in'],
  ['Not In','not_in'],
  ['Limit Ascending','limit_ascending'],
  ['Limit Descending','limit_descending'],
  ['Cumulative Percentile']
]

export default function OperatorDialog(props) {
  const [activeTab,setActiveTab] = useState("0")
  
  
  const returnAttributeTab = () => {
    return (
      (<Grid container>
        {props.attributes.map((item, index) => (
          <Grid>{JSON.stringify(item)}</Grid>
        ))}
      </Grid>)
    );
  }

  const updateOperator = (o) => {
    if(['in','not_in'].includes(o)){
      if(!Array.isArray(props.currentFilter.value)){
        props.updateFilter(props.currentIndex,'value',[])
      }
    } else if(o == 'between'){
      props.updateFilter(props.currentIndex,'value',[0,1])
    }
    props.updateFilter(props.currentIndex,'operator',o)
    props.setOpen(false)
  }
  return (
    (<Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={e=>props.setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Stack direction='column' alignItems='flex-start' justifyContent='flex-start' spacing={3}>
          <Divider sx={{width:'100%'}}/>
          <Grid container spacing={3}>
            <Grid>Mathematical</Grid>
            {mathOperators.map((item, index) => (
              <Grid>
                {/*<Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
                  <Fab sx={{fontSize:20}} color="primary" aria-label="add" onClick={e=>updateOperator(item[1])}>
                    {item[1]}
                  </Fab>
                  {item[0]}
                </Stack>*/}
                <Button color='neutral' size='small' variant='outlined' onClick={e=>updateOperator(item[1])}>{item[0]}</Button>
            </Grid>
            ))}
          </Grid>
          <Divider sx={{width:'100%'}}/>
          <Grid container spacing={3}>
            <Grid>Array Operators</Grid>
            {arrayOperators.map((item, index) => (
              <Grid>
                {/*<Stack direction='column' alignItems='center' justifyContent='center'>
                  <Fab sx={{fontSize:20}} color="primary" aria-label="add" onClick={e=>updateOperator(item[1])}>
                    {item[1]}
                  </Fab>
                  {item[0]}
                </Stack>*/}
                <Button color='neutral' size='small' variant='outlined' onClick={e=>updateOperator(item[1])}>{item[0]}</Button>
            </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>)
  );
}