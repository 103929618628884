import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {mapping,categories} from './FilterConfig'
import snakeToTitleCase from '../../helpers/snakeToTitleCase';

export default function FilterAccordion(props) {

  function toggleFilter(e){
    var checked = e.target.checked
    var id = e.target.id
    if(checked){
      props.setFilters([...props.filters,e.target.id])
    } else {
      props.setFilters(props.filters.filter(item => item !== id))
    }
  }

  function returnFilterGroup(category){
    return(
      <FormGroup>
        {categories[category].map((attr)=>(
          <FormControlLabel control={<Checkbox id={`${category}:${attr}`} onChange={toggleFilter} checked={props.filters.includes(`${category}:${attr}`)} />} label={mapping[attr] || snakeToTitleCase(attr)} />
        ))}
      </FormGroup>
    )
  }

  function returnFilterGroupTwo(category,options){
    return(
      <FormGroup>
        {options.map((attr)=>(
          <FormControlLabel control={<Checkbox id={`${category}:${attr}`} onChange={toggleFilter} checked={props.filters.includes(`${category}:${attr}`)} />} label={mapping[attr] || snakeToTitleCase(attr)} />
        ))}
      </FormGroup>
    )
  }

  useEffect(() => {(async()=>{
    //runs on any change to filters
    //filters props.data using the logic (attr1=val OR attr1=val) AND (att2=val OR att2=val)
    var newData = JSON.parse(JSON.stringify(props.data))
    var keepData = {}

    //group filters into categories
    var filtersGrouped = {}
    for (let i = 0; i < props.filters.length; i++) {
      var filter = props.filters[i].split(":")
      var attr = filter[0]
      var val = filter[1]
      if(val === "true"){val = true}
      if(filtersGrouped.hasOwnProperty(attr)){
        filtersGrouped[attr].push(val)
      } else {
        filtersGrouped[attr] = [val]
      }
    }

    for (const [attr, value] of Object.entries(filtersGrouped)) {
      var newDataKeys = Object.keys(newData)
      for (let i = 0; i < newDataKeys.length; i++) {
        var id = newDataKeys[i]
        var item = newData[id]
        if(item.tags){
          if(item.tags[attr]){
            if(Array.isArray(item.tags[attr])){
              if(!item.tags[attr].some(r=> value.includes(r))){
                delete newData[id]
              }
            }else{
              if(!value.includes(item.tags[attr])){
                console.log('delstr')
                delete newData[id]
              }
            }
          }else{
            delete newData[id]
          }
        }else{
          delete newData[id]
        }
      }
    }

    if(props.filters.length == 0){
      props.setData(props.data)
    } else {
      props.setData(newData)
    }
  })()},[props.filters,props.tags])

  return (
    <>
      {/*Object.keys(categories).map((category)=>(
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{mapping[category] || category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {returnFilterGroup(category)}
          </AccordionDetails>
        </Accordion>
      ))*/}
      {Object.entries(props.tags).map(([category,options])=>(
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{mapping[category] || snakeToTitleCase(category)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {returnFilterGroupTwo(category,options)}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}