import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns = [
  { field: 'name', headerName: 'ID', flex: 1 },
  { field: 'value', headerName: 'Weight', flex: 1 }
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function DataGridDemo(props) {
    console.log(props.data)
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        getRowId={(row) => row.name} 
        autoPageSize
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        slots={{
            toolbar: CustomToolbar,
        }}
        rowHeight={25}
        headerHeight={25}
      />
    </Box>
  );
}