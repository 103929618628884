import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'


//icons
import CloseIcon from '@mui/icons-material/Close';
//dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  codeBox: {
    background:'whitesmoke !important',
    padding:'1em'
  }
})
export default function ShareDialog(props) {
  const classes = useStyles()
  const [shareDialogTab, setShareDialogTab] = useState(0)

  return (
    <Dialog scroll='paper' open={props.show} onClose={e => props.setShow(false)} maxWidth='md' fullWidth>
    <DialogTitle onClose={e => props.setShow(false)}>
      Share Index
      <IconButton style={{float:'right'}} aria-label="close" size="small" onClick={e => props.setShow(false)}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers={true}>
    <Tabs value={shareDialogTab} onChange={(e,v) => setShareDialogTab(v)} aria-label="basic tabs example">
      <Tab label="Embed"/>
      <Tab label="URL" disabled />
      <Tab label="Social" disabled />
    </Tabs>
{
shareDialogTab == 0 ?
  <Stack spacing={3} sx={{marginTop:'1em'}}>
    <div>
    <Typography variant='h5'>Ticker</Typography>
    <Paper elevation={4} className={classes.codeBox}>
    <code>
    &#x3C;iframe width=&#x22;100%&#x22; src=&#x22;https://indexone.io/embed/index/{props.indexId}/pricewidget&#x22; frameborder=&#x22;0&#x22; allowfullscreen&#x3E; &#x3C;/iframe&#x3E;
    </code>
    </Paper>
    </div>
    <div>
    <Typography variant='h5'>Performance Chart</Typography>
    <Paper elevation={4} className={classes.codeBox}>
    <code>
        &#x3C;iframe width=&#x22;100%&#x22; height=&#x22;500&#x22; src=&#x22;https://indexone.io/embed/index/{props.indexId}/chart&#x22; frameborder=&#x22;0&#x22; allowfullscreen&#x3E; &#x3C;/iframe&#x3E;
    </code>
    </Paper>
    </div>
    <div>
    <Typography variant='h5'>Weighting</Typography>
    <Paper elevation={4} className={classes.codeBox}>
    <code>
      &#x3C;iframe width=&#x22;100%&#x22; height=&#x22;500&#x22; src=&#x22;https://indexone.io/embed/index/{props.indexId}/weighting?category=symbol&#x22; frameborder=&#x22;0&#x22; allowfullscreen&#x3E; &#x3C;/iframe&#x3E;
    </code>
    </Paper>
    </div>
    <div>
    <Typography variant='h5'>Stats Table</Typography>
    <Paper elevation={4} className={classes.codeBox}>
    <code>
      &#x3C;iframe width=&#x22;100%&#x22; src=&#x22;https://indexone.io/embed/index/{props.indexId}/stats?returns=30d,90d,120d,ytd,1y,itd&#x26;sharpe=30d,90d,120d,ytd,1y,itd&#x26;volatility=30d,90d,120d,ytd,1y,itd&#x22; frameborder=&#x22;0&#x22; allowfullscreen&#x3E; &#x3C;/iframe&#x3E;
    </code>
    </Paper>
    </div>
  </Stack>
: shareDialogTab == 1 ?
  <div>one</div>
:
  <div></div>
}
    </DialogContent>
  </Dialog>
  )
}