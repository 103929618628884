
import React, { PureComponent } from "react";

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
//parseInt(num, 10)
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {months[parseInt(payload.value.substr(5,2),10)-1]} {payload.value.substr(2,2)}
        </text>
      </g>
    );
  }
}

export default CustomizedAxisTick