import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from "react-router-dom";

//icons
import CloseIcon from '@mui/icons-material/Close';
//dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Typography, Button } from '@mui/material';

export default function ShareDialog(props) {
  let navigate = useNavigate()
  return (
    <Dialog scroll='paper' open={props.show} onClose={e => props.setShow(false)} maxWidth='md' fullWidth>
    <DialogTitle onClose={e => props.setShow(false)}>

      <IconButton style={{float:'right'}} aria-label="close" size="small" onClick={e => props.setShow(false)}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers={false}>
      <Typography>
        You are currently on a free-tier license, some features of our platform are therefore unavailable. Please get in touch with our team to upgrade.
      </Typography>
    </DialogContent>
    <DialogActions>
    <Button variant='outlined' onClick={e=>props.setShow(false)} color="primary">
      I understand
    </Button>
    <Button variant='outlined' onClick={e=>{props.setShow(false); navigate('/billing')}} autoFocus>
      License Options
    </Button>
  </DialogActions>
  </Dialog>
  )
}