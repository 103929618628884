import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import { FormControlLabel, Slider, Switch, TextField, MenuItem, Chip, Stack, Typography } from '@mui/material'
import Grid from "@mui/material/Grid2"
import { FixedSizeList } from 'react-window';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

//custom
import SecuritySearch from './SecuritySearch'


import attributeConfig from './attributeConfig.json'
import attributeMapping from './attributeMapping.json'

export default function FilterValue(props) {
  const [value,setValue] = useState(props.filter.value)
  const [betweenValue,setBetweenValue] = useState([0,1])
  const [dialogShow,setDialogShow] = useState(false)
  const [securitySearchShow,setSecuritySearchShow] = useState(false)
  const [searchValue,setSearchValue] = useState('')

  const returnListRow = ({data,index,style}) => {
    const value = data[index]
    return(
    <ListItem
    style={style}
    key={value}
    disablePadding
  >
    <ListItemButton role={undefined} onClick={e=>handleToggle(value)} dense>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={props.filter.value.includes(value)}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': value }}
        />
      </ListItemIcon>
      <ListItemText id={value} primary={value} />
    </ListItemButton>
  </ListItem>
  )}

  const returnAttributeType = (a) => {
    if(props.attributeConfig && a in props.attributeConfig){
      return props.attributeConfig[a]
    } else if (a in attributeConfig){
      return attributeConfig[a]
    } else {
      return {'type':'unknown'}
    }
  }

  const intToString = (value) => {
    var suffixes = ["", "k", "m", "b","t"];
    var suffixNum = Math.floor((""+value).length/3);
    var shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(2));
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue+suffixes[suffixNum];
  }

  const handleToggle = (value) => {
    const currentIndex = props.filter.value.indexOf(value);
    const newChecked = [...props.filter.value];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    props.updateFilter(props.filterIndex,'value',newChecked)
  };

  let attr = returnAttributeType(props.filter.attribute)
  if (attr.hasOwnProperty('options')){
    let options = attr.options.filter(x => x.toLowerCase().includes(searchValue.toLowerCase()))
  }

  if (props.filter.operator === undefined){
    return(null)
  } else if(attr.type == 'boolean'){
    return(
      <FormControlLabel control={<Switch checked={props.filter.value} onChange={e=>props.updateFilter(props.filterIndex,'value',e.target.checked)}/>} label={typeof props.filter.value == 'boolean' ? props.filter.value.toString() : ''} />
    )
  } else if(['limit_descending','limit_ascending'].includes(props.filter.operator)){
    return(
      <TextField
        id="outlined-select"
        type="number"
        size='small'
        value={props.filter.value}
        onChange={(e,v) => props.updateFilter(props.filterIndex,'value',parseInt(e.target.value))}
      />
    )
  } else if(attr.type == 'numeric'){
    if(['>','<','=','<=','>='].includes(props.filter.operator)){
      return(
        <>
        <TextField
          size='small'
          value={props.filter.value}
          onChange={e=>props.updateFilter(props.filterIndex,'value',parseInt(e.target.value))}
        />
        <Slider
          value={value}
          onChange={(e,v) => setValue(v)}
          onChangeCommitted={(e,v) => props.updateFilter(props.filterIndex,'value',v)}
          min={attr.min ? attr.min : undefined}
          max={attr.max ? attr.max : undefined}
          valueLabelDisplay="auto"
        />
        </>
      )
    } else if (props.filter.operator == 'between'){
      return(
        <>
        <Typography>{intToString(props.filter.value[0])}</Typography>
        <Slider
          value={betweenValue}
          onChange={(e,v) => setBetweenValue(v)}
          onChangeCommitted={(e,v) => props.updateFilter(props.filterIndex,'value',v)}
          min={attr.min ? attr.min : undefined}
          max={attr.max ? attr.max : undefined}
          valueLabelDisplay="auto"
        />
        <Typography>{intToString(props.filter.value[1])}</Typography>
        </>
      )
    } 
  } else if (['iex_symbol','symbol','id'].includes(props.filter.attribute)){
    //symbol picker
    return (<>
      <IconButton onClick={e=>setSecuritySearchShow(true)} aria-label="select">
      <MoreHorizIcon color='inherit' />
      </IconButton>
      <Dialog sx={{padding:'1em'}} open={securitySearchShow} onClose={e=>setSecuritySearchShow(false)}>
      <SecuritySearch
        setShow={setSecuritySearchShow}
        show={securitySearchShow}
        selected={props.filter.value}
        selectFunction={e => handleToggle(e[1])}
      />
      </Dialog>
      <Grid container spacing={1}>
      {Array.isArray(props.filter.value) && props.filter.value.map((option) => (
        <Grid>
        <Chip
          label={option}
          onDelete={e=> props.updateFilter(props.filterIndex,'value',props.filter.value.filter(el => el !== option))}
        />
        </Grid>
      ))}
      </Grid>
    </>);
  } else if (attr.type == 'categorical'){

      if(['in','not_in'].includes(props.filter.operator)){

        if (attr.hasOwnProperty('options')){
          var options = attr.options.filter(x => x.toLowerCase().includes(searchValue.toLowerCase()))
        }else{
          var options = []
        }
        return (
          (<Stack direction='row'>
            <IconButton onClick={e=>setDialogShow(true)} aria-label="select">
            <MoreHorizIcon color='inherit' />
            </IconButton>
            <Grid container spacing={1}>
            {Array.isArray(props.filter.value) && props.filter.value.map((option) => (
                  <Grid>
                  <Chip
                    label={option}
                    onDelete={e=> props.updateFilter(props.filterIndex,'value',props.filter.value.filter(el => el !== option))}
                  />
                  </Grid>
            ))}
            </Grid>
            <Dialog onClose={e=>setDialogShow(false)} open={dialogShow}>
              {Array.isArray(options) &&
                <Stack direction='column'>
                  <TextField onChange={e=> setSearchValue(e.target.value)} label="Start typing to search..." value={searchValue} sx={{margin:'1em'}}/>
                  <FixedSizeList
                    height={600}
                    width={560}
                    itemSize={46}
                    itemCount={options.length}
                    overscanCount={1}
                    itemData={options}
                  >
                    {returnListRow}
                  </FixedSizeList>
                </Stack>
                }
            </Dialog>
          </Stack>)
        );
      } else if(['=','<>'].includes(props.filter.operator)){
          if(attr.options){
            return(
              <TextField
                id="outlined-select"
                select
                size='small'
                value={props.filter.value}
                onChange={(e,v) => props.updateFilter(props.filterIndex,'value',e.target.value)}
              >
                {
                  attributeMapping.hasOwnProperty(props.filter.attribute) ?
                  attr.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {attributeMapping[props.filter.attribute].hasOwnProperty(option) ? attributeMapping[props.filter.attribute][option] : option}
                    </MenuItem>
                  ))
                  :
                  attr.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                }
              </TextField>
            )
          } else {
            return(
            <TextField
              id="outlined-name"
              label="Name"
              size='small'
              value={props.filter.value}
              onChange={(e,v) => props.updateFilter(props.filterIndex,'value',e.target.value)}
            />
            )
          }
      }
  }  else{
  return (
    <TextField
      id="outlined-name"
      label="value"
      size='small'
      value={props.filter.value}
      onChange={(e,v) => props.updateFilter(props.filterIndex,'value',e.target.value)}
    />
  )}
  
  //default return
  return (
    <TextField
      id="outlined-name"
      label="value"
      size='small'
      value={props.filter.value}
      onChange={(e,v) => props.updateFilter(props.filterIndex,'value',e.target.value)}
    />
  )
}
