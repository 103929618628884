import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container,Box,LinearProgress,Stack,TextField,Button,MenuItem } from '@mui/material';
import WeightingTable from "./WeightingTable"
import { LoadingButton } from '@mui/lab';
import { PropaneSharp } from '@mui/icons-material';
import { useBacktestPreviewQuery, useLazyBacktestPreviewQuery } from '../../../store/indexOneApi';


export default function IndexPreview(props) {
    const teamBacktests = useSelector(state=>state.main.teamData.backtests ?? {})
    const [previewResults,setPreviewResults] = useState({})
    const [loading,setLoading] = useState(false)
    const [confirmationRequested,setConfirmationRequested] = useState(false)
    const [hasErrors,setHasErrors] = useState(false)
    const latestApiRequest = useRef()
    const latestPendingRequest = useRef()

    //const { data , isLoading } = useBacktestPreviewQuery(props.indexSpecifications)

    const [fetchTrigger, { data, error, isLoading, isFetching } , lastPromiseInfo] = useLazyBacktestPreviewQuery()

    useEffect(() => {(async()=>{
      latestPendingRequest.current = props.indexSpecifications
      if(isFetching === false){
        if(!validateRequest()){return}
        while (JSON.stringify(latestPendingRequest.current) !== latestApiRequest.current){
          latestApiRequest.current = JSON.stringify(latestPendingRequest.current)
          await fetchTrigger(latestPendingRequest.current,true)
        }
      }
    })()},[props.indexSpecifications])
    

    const validateRequest = () => {
      // validate preview request
      return true
    }
    /*
    useEffect(() => {
        //console.log(loading)
        //console.log(latestApiRequest.current)

        console.log(props.indexSpecifications.reconstitution[0].universe[3].value)
        if(isFetching === false){
          let currentState = props.indexSpecifications
          if (JSON.stringify(currentState) !== latestApiRequest.current){
            console.log("running based on loading")
            latestApiRequest.current = JSON.stringify(props.indexSpecifications)
            //runFilters()
            console.log("STARTING")
            console.log(props.indexSpecifications.reconstitution[0].universe[3].value)
            fetchTrigger(props.indexSpecifications)
            console.log("ENDING")
            console.log(props.indexSpecifications.reconstitution[0].universe[3].value)
          } else {
            console.log("current EQ")
          }
        }else{
          console.log("loading True")
        }
      }, [props.indexSpecifications,isFetching]);
    */

  const backtest = () => {
    var raiseError = false
    var raiseWarning = false
    //validate errors or misformatting
    if (hasErrors){
      setHasErrors(true)
      return
    }
    //validate warnings
    if (teamBacktests.hasOwnProperty(props.indexSpecifications.name)){raiseWarning = true}
    raiseWarning = true
    if (raiseWarning && !confirmationRequested){
      setConfirmationRequested(true)
      return
    }
    props.runBacktest()
    setConfirmationRequested(false)
  }

  return (
    <Box sx={{width:'100%',height:'100%'}}>
      <Box sx={{width:'100%',height:'0px'}}>{isFetching && <LinearProgress sx={{width:'100%'}}/>}</Box>
      <Stack sx={{height:'100%',padding:'1em'}} directon='column' spacing={2} justifyContent='space-between'>
        {data?.schedule && 
          <TextField onChange={e => props.setIndexSpecifications(p => ({ ...p, "time": e.target.value }))} value={props.indexSpecifications.time} select variant="standard" size='small' id="time-select" label="Evaluation Time">
            {Object.keys(data.schedule).map(time=>(
              <MenuItem key={time} value={time}>{time}</MenuItem>
            ))}
          </TextField>
        }
        <WeightingTable rowData={data?.universe ?? []}/>
        <TextField onChange={e => props.handleChange({"reconstitution_mode": e.target.value })} value={props.indexSpecifications.reconstitution_mode} select variant="outlined" size='small' id="universe-select" label="Universe">
          <MenuItem key="file" value="file">file</MenuItem>
          <MenuItem key="code" value="code">code</MenuItem>
          <MenuItem key="default" value="default">default</MenuItem>
        </TextField>
        <TextField onChange={e => props.setIndexSpecifications(p => ({ ...p, "rebalancing_mode": e.target.value }))} value={props.indexSpecifications.rebalancing_mode} select variant="filled" size='small' id="weighting-select" label="Weighting">
        <MenuItem key="file" value="file">file</MenuItem>
          <MenuItem key="code" value="code">code</MenuItem>
          <MenuItem key="default" value="default">default</MenuItem>
        </TextField>
        <TextField onChange={e => props.setIndexSpecifications(p => ({ ...p, "schedule_mode": e.target.value }))} value={props.indexSpecifications.schedule_mode} select variant="standard" size='small' id="rebal-select" label="Rebalance Frequency">
        <MenuItem key="file" value="file">file</MenuItem>
          <MenuItem key="code" value="code">code</MenuItem>
          <MenuItem key="default" value="default">default</MenuItem>
        </TextField>
        <TextField onChange={e => props.setIndexSpecifications(p => ({ ...p, "name": e.target.value }))} value={props.indexSpecifications.name} size='small'/>
        <div style={{flexGrow:1}}/>
        <Stack direction='row' spacing={1}>
          {confirmationRequested &&
            <Button color="error" variant="outlined" onClick={e=>setConfirmationRequested(false)}>X</Button>
          }
          <LoadingButton variant='outlined' disabled={hasErrors} fullWidth onClick={e=>backtest()}>Backtest</LoadingButton>
          <LoadingButton variant='outlined' disabled={hasErrors} fullWidth onClick={e=>backtest()}>Go Live</LoadingButton>
        </Stack>
        {/*JSON.stringify(previewResults)*/}
      </Stack>
    </Box>
  );
}