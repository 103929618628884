import React, { useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Stack, Box, TextField, RootRef, ListItem,IconButton, Button, Grow, Collapse, Paper, Slider } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import attributeConfig from './attributeConfig.json'

//icons
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

//custom
import OperatorDialog from "./OperatorDialog";
import AttributeDialog from "./AttributeDialog";
import FilterValue from "./FilterValue";
import HorizontalBar from './HorizontalBar'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const blankFilter = {
    attribute:undefined,
    operator:undefined,
    value:undefined
}


export default function FilterList(props) {
  const [state, setState] = useState(props.filters)
  const [newFilter,setNewFilter] = useState(blankFilter)
  const [attributeDialogOpen,setAttributeDialogOpen] = useState(false)
  const [dialogOpen,setDialogOpen] = useState(false)
  const [filterSelected,setFilterSelected] = useState(null)

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      props.filters,
      result.source.index,
      result.destination.index
    );

    props.setFilters(quotes);
  }

  const updateFilter = (idx,attr,value) => {
    let newFilters = Array.from(props.filters)
    newFilters[idx][attr] = value
    props.setFilters(newFilters)
  }

  const pushFilter = (idx=props.filters.length,filter=blankFilter) => {
    let newFilters = Array.from(props.filters)
    let thisFilter = JSON.parse(JSON.stringify(blankFilter));
    let thisId = Math.max.apply(Math, props.filters.map(function(o) { return parseInt(o.id) }))+1
    thisId = thisId.toString()
    thisFilter.id = thisId
    newFilters.splice(idx,0,thisFilter)
    props.setFilters(newFilters)
    setAttributeDialogOpen(true)
    setFilterSelected(idx)
  }

  const deleteFilter = (idx) => {
    let newFilters = Array.from(props.filters)
    newFilters.splice(idx,1)
    props.setFilters(newFilters)
  }

  const openOperatorDialog = (i) => {
    setFilterSelected(i)
    setDialogOpen(true)
  }

  const returnAttributeType = (a) => {
    if (['market_cap'].includes(a)){
      return 'numeric'
    } else {
      return 'categorical'
    }
  }

  function returnAttributeName(attribute){
    if (attributeConfig.hasOwnProperty(attribute)) {
      return attributeConfig[attribute].name
    } else {
      return attribute
    }
  }

  const returnFilterValue = (item,index) => {
    var attrType = returnAttributeType(item.attribute)
    var operator = item.operator
    if(attrType == 'numeric'){
      if(operator == 'between'){
        return(
          <Slider
            getAriaLabel={() => 'Temperature range'}
            valueLabelDisplay="auto"
            value={[1500000,3000000000]}
            min={0}
            max={5000000000}
          />
        )
      }
    } else {
      return(<div>{JSON.stringify(item)}</div>)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} sx={{width:'100%'}}>
      <AttributeDialog setOpen={setAttributeDialogOpen} open={attributeDialogOpen} updateFilter={updateFilter} currentIndex={filterSelected} currentFilter={props.filters[filterSelected]} attributes={props.attributes} attributeConfig={props.attributeConfig}/>
      <OperatorDialog setOpen={setDialogOpen} open={dialogOpen} updateFilter={updateFilter} currentIndex={filterSelected} currentFilter={props.filters[filterSelected]} attributes={props.attributes} attributeConfig={props.attributeConfig}/>
      <Droppable droppableId="list">
        {(provided,snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{width:'100%'}}>
              <Stack spacing={3} direction='column' sx={{width:'100%'}}>
              {props.filters.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      
                      sx={{
                        opacity: snapshot.isDragging ? '0.5' : '1'
                      }}
                      elevation={6}
                      >
                      <Box sx={{ width: '100%',color:'#3D5A80' }}><LinearProgress color='inherit' variant="determinate" value={props.universeLength[index+1]/props.universeLength[1]*100} /></Box>
                      <Stack
                        direction='row'
                        alignItems="center"
                        justifyContent='space-between'
                        sx={{
                            backgroundColor: snapshot.isDragging ? 'white' : 'white',
                            marginTop:'1em',
                            marginBottom:'1em',
                            margin:'1em'
                        }}
                        spacing={3}
                        >
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}} {...provided.dragHandleProps}><DragIndicatorIcon/></div>
                            <Stack direction='column'>
                              <Typography variant='body2'>Attribute</Typography>
                              <Tooltip title='Select an attribute to filter by' placement="bottom">
                                <Button sx={{minWidth:200, height:'40px'}} color='neutral' size='small' variant='outlined' onClick={e=>{setAttributeDialogOpen(true);setFilterSelected(index)}}>{props.filters[index].attribute ? returnAttributeName(props.filters[index].attribute) : 'attribute'}</Button>
                              </Tooltip>
                            </Stack>
                            <Stack direction='column'>
                              <Typography variant='body2'>Filter</Typography>
                              <Tooltip title='Select a filter operator' placement="bottom">
                                <Button sx={{minWidth:150, height:'40px'}} size='small' variant='outlined' color={!props.filters[index].operator ? 'error' : 'neutral'} startIcon={!props.filters[index].operator && <WarningAmberIcon/>} onClick={e=>openOperatorDialog(index)}>{props.filters[index].operator ? props.filters[index].operator : 'operator'}</Button>
                              </Tooltip>
                            </Stack>
                            <Stack direction='column'>
                              <Typography variant='body2'>Value</Typography>
                              <FilterValue sx={{maxWidth:'100px'}} style={{maxWidth:'100px'}} filter={item} filterIndex={index} updateFilter={updateFilter} attributeConfig={props.attributeConfig}/>
                            </Stack>
                            <div style={{flexGrow:1}}/>
                            {props.universeLength[index+1]}
                            <Tooltip title='Delete filter' placement='bottom'>
                            <IconButton size='small' onClick={e=>deleteFilter(index)} aria-label="delete">
                              <DeleteOutlineIcon color='secondary' />
                            </IconButton>
                            </Tooltip>
                        </Stack>
                    </Paper>
                    )}
                  </Draggable>
              ))}
              </Stack>
              <Stack direction='row' alignItems='center' sx={{marginTop:'1em'}}>
              <IconButton size='large' aria-label="add" onClick={e=>pushFilter()} variant='outlined'>
                <AddIcon />
              </IconButton>
              <Typography>Add new filter</Typography>
              </Stack>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}