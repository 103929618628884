import React, {useCallback} from 'react'
import { styled } from '@mui/material/styles';
import {useDropzone} from 'react-dropzone'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, Stack, IconButton } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export default function FileUpload(props) {

  const returnSampleCsv = (type) => {
    if(type == 'basket'){
      return(
        <Table size='small'>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>2015-01-01</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>2022-01-01</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>AAPL</TableCell>
          <TableCell>0.5</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>0.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>TSLA</TableCell>
          <TableCell>0.5</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>0.5</TableCell>
        </TableRow>
      </Table>
      )
    }else if(type == 'basket_unweighted'){
      return(
        <Table size='small'>
        <TableRow>
          <TableCell>2015-01-01</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>2022-01-01</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>AAPL</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>TSLA</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>AMZN</TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell>MSFT</TableCell>
        </TableRow>
      </Table>
      )
    } else if(type == 'fixed'){
      return(
        <Table size='small'>
        <TableRow>
          <TableCell>AAPL</TableCell>
          <TableCell>0.25</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>TSLA</TableCell>
          <TableCell>0.75</TableCell>
        </TableRow>
      </Table>
      )
    } else if(type == 'fixed_unweighted'){
      return(
        <Table size='small'>
        <TableRow>
          <TableCell>AAPL</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>TSLA</TableCell>
        </TableRow>
      </Table>
      )
    } else if(type == 'panel'){
      return(
        <Table size='small'>
        <TableRow>
          <TableCell>2015-01-01</TableCell>
          <TableCell>AAPL</TableCell>
          <TableCell>0.25</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2016-01-01</TableCell>
          <TableCell>TSLA</TableCell>
          <TableCell>0.25</TableCell>
        </TableRow>
      </Table>
      )
    }
  }

  const csvWeightsToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let res = {}
    for (let i = 0; i < headers.length; i++) {
      if(headers[i] !== ''){
        res[headers[i].substring(0,10)] = {}
      }
    }
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values.length <= 1){continue}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(!isNaN(v)){
          v = parseFloat(values[ii])
          if(v !== 0){
            res[headers[ii].substring(0,10)][values[0]] = v
          }
        }
      }
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvPanelToObj = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let res = {}
    const rows = str.split("\n");
    for (let i = 0; i < rows.length; i++) {
      let v = rows[i].replace('\r','')
      v = v.split(delimiter);
      if(v.length <= 2){continue}
      let time = v[0]
      let ticker = v[1]
      let weight = v[2]
      if(!isNaN(weight)){
        weight = parseFloat(weight)
        if(weight !== 0){
          if(!res.hasOwnProperty(time)){
            res[time] = {[ticker]:weight}
          } else {
            res[time][ticker] = weight
          }
        }
      }
    }
    props.setWeighting(props.type,res)
  }

  const csvUniverseToArray = (str, delimiter = ",") => {
    console.log(str)
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let res = {}
    for (let i = 0; i < headers.length; i++) {
      if(headers[i] !== ''){
        res[headers[i].substring(0,10)] = []
      }
    }
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    console.log(headers)
    console.log(res)
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      console.log(values)
      if(values.length <= 1){continue}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(v.length !== 0){
          res[headers[ii].substring(0,10)].push(v)
        }
      }
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvFixedToArray = (str, delimiter = ",") => {
    console.log(str)
    let res = []
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.split("\n");
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      res.push(rows[i].replace('\r',''))
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvFixedToObj = (str, delimiter = ",") => {
    console.log(str)
    let res = {}
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.split("\n");
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values[1] == 0){continue}
      res[values[0]] = parseFloat(values[1].replace('\r',''))
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }
  
  const onDrop = useCallback((acceptedFiles) => {
    if(props.setLoading){props.setLoading(true)}
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        if(!file.name.endsWith(".csv")){
          alert('File type must be .csv')
          return
        }
        let data = reader.result
        console.log(props)
        if(props.type == 'basket'){
          csvWeightsToArray(data)
        } else if (props.type == 'basket_unweighted'){
          csvUniverseToArray(data)
        } else if (props.type == 'fixed'){
          csvFixedToObj(data)
        } else if (props.type == 'fixed_unweighted'){
          csvFixedToArray(data)
        } else if (props.type == 'panel'){
          csvPanelToObj(data)
        }
        console.log(data)
        //props.returnData(data)
      }
      reader.readAsText(file)
    })
    if(props.setLoading){props.setLoading(false)}
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <Paper sx={{padding:'1em', cursor:'pointer', height:'100%'}} elevation={5} >
        <div {...getRootProps()}>
        <input {...getInputProps()} />
          <Stack direction='row' justifyContent='space-between' sx={{paddingBottom:'1em'}}>
            <Typography variant='body2'>
              {
                props.type == 'basket' ?
                  'Time-series universe & weights.'
                : props.type == 'basket_unweighted' ?
                  'Time-series universe using regular weighting criteria.'
                : props.type == 'fixed' ?
                  'Fixed universe & weights using a regular rebalancing schedule.'
                : props.type == 'fixed_unweighted' ?
                  'Fixed universe using regular weighting criteria and rebalancing schedule.'
                : props.type == 'panel' ?
                  'Panel data of time and weights'
                :
                  ''
              }
            </Typography>
            <IconButton size='small' variant='contained' sx={{height:'30px'}}><FileUploadIcon/></IconButton>
            </Stack>
            {returnSampleCsv(props.type)}
        </div>
    </Paper>
  )
}