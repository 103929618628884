import React, {useState, useEffect, useRef} from 'react';
import config from '../../config'
import getParams from '../helpers/paramHelper'
import { makeStyles } from '@mui/styles';
import { useSecurityQuery } from '../../store/indexOneApi';

import { Typography } from '@mui/material';
import Grid from "@mui/material/Grid2"
import colors from '../helpers/colors'
import { Dialog, DialogTitle, DialogContent, IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { CSVLink, CSVDownload } from "react-csv";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//chart
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const rows = {
  'name':'Name',
  'id':'FIGI',
  'iex_sector':'Sector',
  'iex_industry':'Industry',
  'symbol':'Symbol',
  'country':'Domicile',
  'exchange_name':'Exchange Name',
  'mic':'Exchange Code',
  'region':'Exchange Country'
}

export default function SecurityDialog(props) {
    //const [securityData,setSecurityData] = useState({'values':[]})
    //const [loading,setLoading] = useState(true)
    const {data={'values':[]},isLoading} = useSecurityQuery({id:props.securityId,return_values:true})

    /*
    const getSecurityData = (security_id) => {
        setLoading(true)
        try {
            api.get(`securities/${security_id}?return_values=True`)
              .then(res => {
                console.log(res)
                var values = res.data
                console.log(values)
                setSecurityData(values)
                setLoading(false)
              })
          } catch (err) {
            console.error(err)
            console.log(err.body)
            setLoading(false)
          }
    }

    function isoToUnix(x){
      var d = new Date(x['time'].replace(/ /g,"T")+"Z")
      return (Math.round(d.getTime()/1000) - d.getTimezoneOffset()*60)
    }

    useEffect(() => {
        if(props.dialogOpen){
          getSecurityData(props.securityId)
        }
        return () => {
            console.log('unmounting')
            // Anything in here is fired on component unmount.
        }
    }, [props.securityId])
    */

    return(
        <Dialog
          onClose={e=>props.setDialogOpen(false)}
          open={props.dialogOpen}
          maxWidth={'sm'}
          fullWidth
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle>
            {data.name}
            <IconButton
            aria-label="close"
            onClick={e=>props.setDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack direction='column' spacing={2}>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={data.values}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <XAxis dataKey="time" /> 
                <YAxis type="number" domain={["auto","auto"]} />
                <Tooltip />
                <Area type="monotone" dataKey="close" stroke="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>
            {/*<CSVLink style={{textDecoration:'none'}} filename={"indexone.csv"} data={securityData.values}><LoadingButton variant='outlined' sx={{textDecoration:'none'}}>download values</LoadingButton></CSVLink>*/}
            <TableContainer component={Paper}>
          <Table aria-label="a dense table" size='small'>
            <TableBody>
            {Object.entries(rows).map(([key, value]) =>
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <b>{value}</b>
                </TableCell>
                <TableCell align="right">{data[key]}</TableCell>
              </TableRow>
            )}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography>{data.description}</Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      )
  }