import React, { useEffect , useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useSelfQuery } from '../../../store/indexOneApi';
import { useNavigate, useParams } from "react-router-dom";
import LicenseDialog from '../../elements/LicenseDialog'

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack';

//icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add';
import SupportIcon from '@mui/icons-material/Support';

//button
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';

const boxes = [
  {
    title:'Indices',
    description:'Access publicly available and propriatery indices running on the platform. Some of our indices are available license-free for commercial and non-commercial use cases.',
    icon:TrendingUpIcon,
    buttonText:'View Indices',
    destination:'/indices'
  },
  {
    title:'Build an Index',
    description:'Use our platform to design an calculate a new index based on a customized strategy or a pre-defined basket. Indices created here can be deployed straight into production.',
    icon:AddIcon,
    buttonText:'Build an Index',
    destination:'/indexbuilder'
  },
  {
    title:'Resources',
    description:'Access resources to help you get started with our platform, and read the latest news and developments regarding our platforms features.',
    icon:MenuBookIcon,
    buttonText:'Access Resources',
    destination:'/resources'
  },
  {
    title:'Team Indices',
    description:'Manage live and draft indices belonging to your team. Our platform allows you to view full index data and manage your index on an ongoing basis.',
    icon:GroupWorkOutlinedIcon,
    buttonText:'Team Indices',
    destination:'/teamindices'
  },
  {
    title:'Streams',
    description:'Set up data delivery rules, including websockets, webhooks, email notifications, and tracker files.',
    icon:SettingsEthernetIcon,
    buttonText:'Manage Streams',
    destination:'/streams'
  },
  {
    title:'Documentation',
    description:'View our full API documentation, covering all available data manipulation and retrieval methods.',
    icon:CodeIcon,
    buttonText:'View Documentation',
    destination:'/docs/api'
  },
  {
    title:'Support',
    description:'Get in touch with our experts, if you have any questions about how the platform works.',
    icon:SupportIcon,
    buttonText:'Get in touch',
    destination:'/support'
  }
]

function Dashboard(props) {
  const navigate = useNavigate()
  const userData = useSelector((state) => state.main.userData)
  const teamData = useSelector((state) => state.main.teamData)
  const [licenseDialogOpen, setLicenseDialogOpen] = useState(false)
  //const { data, userDataError, userDataisLoading }  = useSelfQuery()
  const dispatch = useDispatch()

  useEffect(() => {(async()=>{
    //dispatch({type:'SET_PAGE_TITLE',payload:'dashboard'})
    document.title = `Index One | Dashboard`
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true
    document.head.appendChild(script)
    if (teamData){
      if (teamData.subscription === 'free'){
        setLicenseDialogOpen(true)
      }
    }
    return () => {
      document.head.removeChild(script)
    }
  })()},[])

  const CustomButton = (title,description,Icon,buttonText,destination) => {
    return(
      <ButtonBase
        component={Paper}
        onClick={e=> {
          if(destination === '/docs/api'){
            const win = window.open(destination, "_blank");
            win.focus();
          }else{
            navigate(destination)
          }
        }}
        elevation={2}
        square={false}
        sx={{
          padding:'1em',
          width:'100%',
          backgroundColor:'white'
        }}
      >
      <Stack justifyContent='center' alignItems='flex-start' spacing={2} sx={{width:'100%'}}>
        <Stack direction='row' spacing={2}>
          <Icon fontSize='large'/>
          <Typography variant='h6'>{title}</Typography>
        </Stack>
        <Typography>{description}</Typography>
        <Button variant='outlined' endIcon={<KeyboardArrowRightIcon/>}>{buttonText}</Button>
      </Stack>
        
      </ButtonBase>
    )}

  return (
    (<Container maxWidth={false} disableGutters sx={{padding:'2em', backgroundColor:'#fafafa'}}>
      <LicenseDialog setShow={setLicenseDialogOpen} show={licenseDialogOpen} />
      <Typography variant='h3'>Welcome, {userData && userData.firstname}</Typography>
      <br/>
      <Grid container spacing={4}>
        <Grid size={9}>
          <div>
          <Grid container spacing={4} sx={{width:'100%'}}>
          {boxes.map((box)=>(
            <Grid size={6}>
              {CustomButton(box.title,box.description,box.icon,box.buttonText,box.destination)}
            </Grid>
          ))}
          </Grid>
          </div>
        </Grid>
        <Grid size={3}>
          <Paper elevation={2}><a class="twitter-timeline" data-height="800" href="https://twitter.com/index_one1?ref_src=twsrc%5Etfw">Tweets by index_one1</a></Paper>
        </Grid>
      </Grid>
    </Container>)
  );
}
export default Dashboard