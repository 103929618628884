import React, {useEffect, useState} from 'react';
import ContactForm from '../../elements/ContactForm'
import { useNavigate,useParams } from "react-router-dom";


import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';



const toText = (node) => {
  let tag = document.createElement("div");
  tag.innerHTML = node;
  node = tag.innerText;
  return node;
}

const toHtml = (i) => {

}

export default function ResourcesId(props) {
  const [posts,setPosts] = useState([])
  const params = useParams()

  useEffect(() => {(async()=>{
    try{
      const dat = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@indexone")
      const dat2 = await dat.json()
      const post = dat2.items.filter(p => (p.link.includes(params.resourceId)));
      setPosts(post)
    }catch (error){
      console.log(error)
    }
  })()},[])

  return (
    (<Container disableGutters maxWidth={false} sx={{backgroundColor:'#fafafa'}}>
      <Stack direction='column' justifyContent="center" alignItems="center" spacing={5} sx={{minHeight:'60vh'}}>
      {posts.map((post) => (
        <Stack direction='column' sx={{padding:'2em', width:'100%'}} maxWidth='md'>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h4'>{post.title}</Typography>
            <Typography>{post.pubDate.substring(0,10)}</Typography>
          </Stack>
          <br/>
          <Grid container spacing={3}>
          {post.thumbnail.startsWith("https://cdn") &&
            <Grid size={3}>
            <img width='100%' src={post.thumbnail}/>
            </Grid>
          }
          <Grid sx={{overflow:'hidden'}} size="grow">
          {toText(post.content)}
          </Grid>
          </Grid>
        </Stack>
      ))}
      </Stack>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
      <ContactForm/>
      </Box>
    </Container>)
  );
}