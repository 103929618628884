import React, {useState, useEffect} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"

import {
    Container,
    Typography,
    Stack,
    IconButton,
    Tooltip
} from '@mui/material';

import Grid from "@mui/material/Grid2"

import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import IndexChart from '../IndexChart'
import StatsTable from '../StatsTable'


export default function IndexOverviewPanel(props){
    let history = useNavigate()
    const [indexData,setIndexData] = useState({})
    const [indexId,setIndexId] = useState(undefined)
    useEffect(() => {(async()=>{
        setIndexData(props.indexData)
        setIndexId(props.indexId)
    })()},[props])

    return (
        (<Container maxWidth={false} disableGutters={true} style={{margin:'0px', padding:'1em'}}>
            <Grid container justifyContent='space-between'>
                <Grid size="auto"><Typography variant='h6'>{indexData.name}</Typography></Grid>
                <div style={{flexGrow:1}}/>
                <Grid size="auto"><Tooltip title="Index Overview"><IconButton color="primary" aria-label="indexoverview" onClick={e => window.open(`/index/${props.indexId}/overview`, "_blank")}><OpenInNewIcon /></IconButton></Tooltip></Grid>
                <Grid size="auto"><IconButton aria-label="closepanel" onClick={e => props.toggleIndexOverviewPanel(undefined)}><CloseIcon /></IconButton></Grid>
            </Grid>
            <Typography>{indexData.description}</Typography>
            <Stack direction='row'>
                
            </Stack>
            <Typography>Index Performance</Typography>
            <IndexChart key={indexId} minHeight="200" indices={indexId ? [indexId]:[]}/>
            <br/>
            <Typography>Performance Stats</Typography>
            <StatsTable indexId={props.indexId} volatility="7d,30d,ytd,itd" sharpe="7d,30d,ytd,itd" returns="7d,30d,ytd,itd"/>
        </Container>)
    );
}