import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux'
import { createNotification } from '../../../store/notifier';
import {theme,themeSpec} from '../../../theme'
import { useNavigate, useParams } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { v4 as uuidv4 } from 'uuid';
import { useBacktestMutation } from '../../../store/indexOneApi';
import LoadingSnackbar from '../../elements/indexcreate/LoadingSnackbar';
import DataViewer from "../../elements/indexcreate/DataViewer"
import IndexConfiguration from '../../elements/indexcreate/IndexConfiguration';
//external components
import { CSVLink, CSVDownload } from "react-csv";

import Container from '@mui/material/Container';
import config from '../../../config'


import timezones from '../../helpers/timezones'
import { backtestAllowed,goLiveAllowed } from '../../helpers/authHelper'

import Chip from '@mui/material/Chip';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab'
import { Typography, Stack,  Tooltip, IconButton, Button, Paper } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { TextField, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CodeInterface from '../../elements/CodeInterface';
import SecurityDialog from '../../elements/SecurityDialog'
import IndexSpecifications from '../../elements/indexwizard/IndexSpecifications'
import FilterList from '../../elements/indexwizard/FilterList'
import SecuritiesList from '../../elements/indexwizard/SecuritiesList'
import Weighting from '../../elements/indexwizard/Weighting'
import SimpleChart from '../../elements/indexwizard/SimpleChart'
import VolatilityChart from '../../elements/indexwizard/VolatilityChart'
import DrawdownChart from '../../elements/indexwizard/DrawdownChart'
import ContributionChart from '../../elements/indexwizard/ContributionChart'
import TotalTurnoverChart from '../../elements/indexwizard/TotalTurnoverChart'
import TurnoverChart from '../../elements/indexwizard/TurnoverChart'
import WeightingPie from '../../elements/indexwizard/WeightingPie'
import WeightingDataGrid from '../../elements/indexwizard/WeightingDataGrid'
import IntroDialog from '../../elements/indexwizard/IntroDialog'
import BasketUpload from '../../elements/indexwizard/BasketUpload'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';


const darkTheme = () => {
  themeSpec.palette.mode = 'dark'
  const darkTheme = createTheme(themeSpec)
  return darkTheme
};



const weightingColumns = [
  { field: 'id', headerName: 'id',width:120},
  { field: 'weight', headerName: 'weight', flex:2}
];

const basePayload = {

}

/*
const initFilters = [
  {
    id:"0",
    attribute:'iex_sector',
    operator:'=',
    value:'Information'
  },
  {
    id:"1",
    attribute:'issue_type',
    operator:'=',
    value:'cs'
  },
  {
    id:"2",
    attribute:'region',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'country',
    operator:'=',
    value:'US'
  },
  {
    id:"3",
    attribute:'mic',
    operator:'in',
    value:['XNYS','XNAS']
  },
  {
    id:"4",
    attribute:'market_cap',
    operator:'limit_descending',
    value:100
  }
]
*/
/*
const initFilters = [
    {
      "id": "0",
      "attribute": "iex_symbol",
      "operator": "in",
      "value": [
        "GS",
        "MA",
        "VZ"
      ]
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": ">",
      "value": 100000
    },
    {
      "id": "1",
      "attribute": "market_cap",
      "operator": "limit_descending",
      "value": 5
    }
]
*/

const initFilters = [
  {
    "id": "0",
    "attribute": "issue_type",
    "operator": "=",
    "value": "cs"
  },
  {
    "id": "1",
    "attribute": "exchange_name",
    "operator": "in",
    "value": [
      "Nasdaq All Markets",
      "New York Stock Exchange Inc"
    ]
  },
  {
    "id": "2",
    "attribute": "sector",
    "operator": "in",
    "value": [
      "Information",
      "Technology Services",
      "Finance and Insurance"
    ]
  },
  {
    "id": "3",
    "attribute": "market_cap",
    "operator": "limit_descending",
    "value": 100
  }
]


export default function IndexCreate(props) {
	const websocketId = useRef(null)
  const resultsRef = useRef(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  //const teamName = useSelector(state => state.activeTeamName)
  //const teamSubscription = useSelector(state => state.activeTeam.subscription)
  const teamData = useSelector(state => state.main.teamData)
  //const [pending,setPending] = useState({"k1":{},"k2":{}})
  const [pending,setPending] = useState({})
  const [completedBacktest,setCompletedBacktest] = useState(null)
  const [backtestTrigger] = useBacktestMutation()

  useEffect(() => {
		websocketConnect()
    dispatch({type:'SET_PAGE_TITLE',payload:'Index Builder'})
    document.title = `Index One | Index Builder`
  }, []);

  const runBacktest = (indexSpecifications) => {
		//alert(websocketId.current)
    const thisId = uuidv4()
		if(websocketId.current === null){
			websocketConnect()
		}
		var payload = JSON.parse(JSON.stringify(indexSpecifications))
		payload.live = false
    payload.id = thisId
		payload.full_backtest = true
		payload.websocket_id = websocketId.current
    payload.team_id = teamData.id
    payload.backtest_mode = "v2"
		backtestTrigger(payload)
    setPending(prevPending=>({...prevPending,[thisId]:{"id":thisId,status:"loading"}}))
  }

  const scrollToResults = () => {
    resultsRef.current.scrollIntoView({
      behavior: "smooth"
    })
  }

  const websocketConnect = async () => {
    console.log('connecting websocket')
    var ws = new WebSocket(`${config.wsUrl}`)
    ws.onopen = () => {
      console.log('websocket connected')
      console.log(ws)
    }
    ws.onmessage = async e => {
      //if message contains connection id, request backtest with connection id
      const message = JSON.parse(e.data)
      if(message.hasOwnProperty('status')){
        if(message.status === 'connected'){
          if(message.hasOwnProperty('websocket_id')){
						websocketId.current = message.websocket_id
            //runBacktest(false,message.websocket_id)
          }
        } else if (message.status === 'completed'){
          if(message.hasOwnProperty('url')){
						setPending(prevState=>({...prevState,[message.id]: message}))
            setCompletedBacktest(message)
            //setLoadingStage("Parsing backtest data...")
            //var data = await getS3OLD(message.url)
            //console.log(data)
            //setData(data)
            //setBackdropOpen(false)
            //setDialogOpen(false)
            //ws.close()
          }
        } else if (message.status === 'loading'){
          if(message.hasOwnProperty('loading_progress')){
						setPending(prevState=>({...prevState,[message.id]: message}))
            //setLoadingProgress(message.loading_progress)
          }
          if(message.hasOwnProperty('loading_stage')){
						setPending(prevState=>({...prevState,[message.id]: message}))
            //setLoadingStage(message.loading_stage)
          }
        }
      }
      console.log(message)
    }
    ws.onclose = e => {
      console.log('websocket closed')
      websocketConnect()
    }
    ws.onerror = err => {
      console.error("Socket encountered error: ", err.message, "Closing socket")
      ws.close()
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline enableColorScheme/>
    <Container maxWidth={false} style={{ padding: '1em', backgroundColor:'#121212' }} disableGutters>
        <LoadingSnackbar pending={pending} setPending={setPending} scrollToResults={scrollToResults}/>
        <IndexConfiguration runBacktest={runBacktest}/>
        <Paper ref={resultsRef} sx={{margin:"1em", overflow:'hidden'}}>
          <DataViewer completedBacktest={completedBacktest}/>
        </Paper>
    </Container>
    </ThemeProvider>
  );
}