import React, {useState, useEffect, useRef} from 'react';
import { useSearchParams,useParams } from "react-router-dom"
import { useLazyIndexValuesQuery,useLazySecurityQuery } from '../../store/indexOneApi'
import config from '../../config'
import colors from '../helpers/colors'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer,Tooltip, ReferenceLine, Label} from 'recharts';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoadingScreen from './LoadingScreen'
import GetParamsJoined from '../helpers/paramHelper'

import MuiTooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import defaultColors from '../helpers/colors'

import { Typography } from '@mui/material';


export default function IndexChart(props) {
    const [indexValuesTrigger,indexValuesData] = useLazyIndexValuesQuery()
    const [securityTrigger,securityData] = useLazySecurityQuery()
    const [searchParams, setSearchParams] = useSearchParams();
    var params = GetParamsJoined(['indexId','minWidth','minHeight','lineColors','limit','duration','increment','securities','indexTicker','createdAt'],props,searchParams,useParams())
    console.log("PARAMS")
    console.log(params)
    for(const p of ['minWidth','minHeight']){
        if(!params[p]){
            params[p] = 600
        }
    }
    if(!params.lineColors){
        params.lineColors = defaultColors
    }
    // defaults to limit of 100 most recent values...
    if(!params.limit && !params.duration && !params.increment){
        params.limit = 300
    }
    /* const indices = {
        '123-abc':[{'time':'2020-01-01 00:00:00','value':1100},{'time':'2020-01-02 00:00:00','value':900}],
        '321-cba':[{'time':'2020-01-01 00:00:00','value':1000},{'time':'2020-01-02 00:00:00','value':1200}]
    } */
    const [data, setData] = useState({})
    const [websockets, setWebsockets] = useState({})
    const [chartUpdating, setChartUpdating] = useState(true)
    const [startTime, setStartTime] = useState('auto')
    const [baseId,setBaseId] = useState(null)
    const [refData,setRefData] = useState({})
    const [duration,setDuration] = useState(null)
    const [menuOpen,setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const stateRef = useRef()
    stateRef.current = [data,websockets,baseId]
    
    /*
    for (const index_id in props['indices']){
        indices[index_id] = []
    }
    */
    const addData = (object) => {
        let datacopy = stateRef.current[0][object['id']]
        if (datacopy === undefined || datacopy.length === 0){
            datacopy = [object]
        } else {
            for (var i = datacopy.length - 1; i >= 0; i--){
                if (object.time > datacopy[i].time){
                    datacopy.splice(i+1,0,object)
                    break
                } else if (object.time === datacopy[i].time) {
                    datacopy.splice(i,1,object)
                    break
                }
            }
        }
        // setData(prevData => ({ ...prevData, [object['id']]: [...prevData[object['id']],object] }))
        setData(prevData => ({ ...prevData, [object['id']]: datacopy }))
    }

    function isoToUnix(x){
        var d = new Date(x['time'].replace(/ /g,"T")+"Z")
        return (Math.round(d.getTime()/1000) - d.getTimezoneOffset()*60)
    }

    function returnDurationString(fr,to){
        if(duration == null){
            var frSec = isoToUnix({time:fr})
            var toSec = isoToUnix({time:to})
            var durSec = toSec-frSec
            if (durSec >= 31536000){
                return (`${Math.round(durSec/60/60/24/365)} years`)
            } else if (durSec >= 2630000){
                return (`${Math.round(durSec/60/60/24/30)} months`)
            } else if (durSec >= 86400){
                return (`${Math.round(durSec/60/60/24)} days`)
            } else if (durSec >= 3600) {
                return (`${Math.round(durSec/60/60)} hours`)
            } else if (durSec >= 60) {
                return (`${Math.round(durSec/60)} minutes`)
            }
        } else {
            return(duration)
        }
    }

    const formatXAxis = (x) => {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ]
        const d = new Date(x * 1000)
        if (x % 60) {
            // does not end with 0
            return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`
        } else {
            //return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
            //return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth()+1).toString().padStart(2, '0')}/${d.getFullYear().toString().substr(-2)}`
            return `${(months[d.getMonth()])} ${d.getFullYear().toString().slice(-2)}`
        }
    }

    const formatYAxis = (x) => {
        return x.toFixed(2)
    }

    const renderTooltip = (t) => {
        const d = new Date(t.label * 1000)
        const label = `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`
        //return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
        var value = ''
        try{value = t.payload[0].value.toFixed(2)}catch(e){}
        return(
            <Paper style={{padding:'1em'}}>
                {label}
                <br/>
                {value}
            </Paper>
        )
    }

    const websocketConnect = (index_id) => {
        console.log('connecting websocket')
        var ws = new WebSocket(`${config.wsUrl}?index_id=${index_id}&table=values`);
        setWebsockets({...websockets,[index_id]:ws})
        ws.onopen = () => {
            console.log('websocket connected')
        }
        ws.onmessage = e => {
            const message = JSON.parse(e.data)
            addData(message)
        }
        ws.onclose = e => { console.log('websocket closed')}
        ws.onerror = err => {
            console.error("Socket encountered error: ",err.message,"Closing socket")
            ws.close()
        }
    }

    const updateDuration = async (duration) => {
        setChartUpdating(true)
        var indices = []
        if (props.indices){indices.push(...props.indices)}
        if (params.indexId){indices.push(params.indexId)}
        var securities = []
        if (params.securities){securities.push(...params.securities)}
        var combined = indices.concat(securities)
        for (const idx in combined){
            let id = combined[idx]
            //setData(prevData => ({ ...prevData,[id]:[]}))
        }
        await getChartData(combined,duration)
        Object.keys(data).forEach((key, index) => {
            if(!combined.includes(key)){
                console.log(`removing ${key}`)
                setData(prevData => ({ ...prevData,[key]:[]}))
            }
        });
        setChartUpdating(false)
    }

    const getChartData = async (id_list,duration) => {
        console.log('getting index data')
        var requests = []
        //first id of list is primary, values get rebased to this
        if(duration){
            var p = {duration:duration}
            if(duration !== 'intraday'){
                p.increment = 'eod'
            }
        } else{
            var p = params
        }
        for (let i = 0; i < id_list.length; i++) {
            let id = id_list[i]
            let request = null
            if(id.includes("-")){
                //its an index
                request = indexValuesTrigger({...p,...{"id":id}},true)
                //request = api.get(`index/${id}/values`,{'params':p})
            } else {
                //its a security
                p.id = id
                request = securityTrigger({...p,...{"id":id,"return_values":true}},true)
                //request = api.get(`securities/${id}?return_values=True`,{'params':p})
            }
            requests.push(request)
        }
        console.log(requests)
        try {
        await Promise.all(requests).then(responses => {
            console.log('responses returned')
            console.log(responses)
            var type = null
            var res = null
            var index_id = null
            for (let i = 0; i < responses.length; i++) {
                index_id = id_list[i]
                res = responses[i]
                if(index_id.includes("-")){
                    type = 'index'
                    if(i == 0 && params.indexTicker){
                        setRefData(prevData => ({ ...prevData,[index_id]:params.indexTicker}))
                    } else {
                        setRefData(prevData => ({ ...prevData,[index_id]:"INDEX"}))
                    }
                } else {
                    type = 'security'
                    setRefData(prevData => ({ ...prevData,[index_id]:res.data.symbol}))
                }
                console.log(baseId)
                let idBase = stateRef.current[2]
                console.log(idBase)
                if(idBase && index_id != idBase){
                    var baseData = stateRef.current[0][idBase]
                    //reformat security fetch
                    if(type == 'security'){
                        let vals = res.data.values
                        const newVals = res.data.values.map(({close: value, time})=>({time, value}));
                        console.log('NEWVALS')
                        res.data = newVals
                    }
                    console.log('rebasing result')
                    var thisStart = res.data[0]
                    var thisEnd = res.data[res.data.length-1]
                    var baseStart = thisStart
                    console.log(baseId)
                    baseStart = baseData[0]
                    setData(prevData => ({ ...prevData,[index_id]:[]}))
                    if (!baseStart){
                        continue
                    }
                    if(thisEnd.time <= baseStart.time){
                        //no overlapping values
                        continue
                    }
                    if(baseStart && thisStart){
                        console.log('rebasetrue')
                        //cut off older times
                        if(baseStart.time > thisStart.time){
                            console.log('cutoff')
                            for (let i = 0; i < res.data.length; i++) {
                                if(res.data[i].time >= baseStart.time){
                                    console.log(res.data.length)
                                    res.data = res.data.slice(i,res.data.length)
                                    console.log(i)
                                    console.log(res.data.length)
                                    break
                                }
                            }
                        }
                        //rebase to first matching time of base data (in case benchmark has shorter history)
                        thisStart = res.data[0]
                        for (let i = 0; i < baseData;i++){
                            if(baseData[i].time >= thisStart.time){
                                baseStart = baseData[i]
                                break
                            }
                        }
                        //apply adjustment ratio
                        let timeOfDay = baseData[baseData.length-1].time.slice(-9)
                        let ratio = baseStart.value/thisStart.value
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].value = res.data[i].value*ratio
                            if(type == 'security'){
                                if(res.data[i].time.length == 10){
                                    res.data[i].time += timeOfDay
                                } else {
                                    res.data[i].time = res.data[i].time.slice(0,10).concat(timeOfDay)
                                }
                            }
                        }
                    }
                }
                if (res.data){
                    setData(prevData => ({...prevData,[index_id]:res.data}))
                } else {
                    setData(prevData => ({...prevData,[index_id]:[]}))
                }
            }
        })
        } catch (err) {
            console.error(err)
            console.log(err.body)
            return(`completed`)
        }
    }

    useEffect(() => {(async()=>{
        var indices = []
        if (props.indices){indices.push(...props.indices)}
        if (params.indexId){indices.push(params.indexId)}
        var securities = []
        if (params.securities){securities.push(...params.securities)}
        var combined = indices.concat(securities)
        combined = [...new Set(combined)]
        for(const idx in indices){
            let id = indices[idx]
            if(idx == 0){setBaseId(id)}
        }
        for (const idx in combined){
            let id = combined[idx]
            setData(prevData => ({ ...prevData,[id]:[]}))
        }
        console.log("COMB")
        console.log(combined)
        
        if (params.duration){
            getChartData(combined,params.duration)
        }else{
            getChartData(combined,"1y")
        }

        //remove non existing indices or benchmarks
        Object.keys(data).forEach((key, index) => {
            if(!combined.includes(key)){
                console.log(`removing ${key}`)
                setData(prevData => ({ ...prevData,[key]:[]}))
            }
            console.log(data)
        });

        //connect to websockets
        for(const idx in indices){
            let id = indices[idx]
            if(idx == 0){setBaseId(id)}
            if ((id in websockets) && (websockets.id != null)){
                console.log('exists')
            } else {
                websocketConnect(id)
            }
        }

        setChartUpdating(false)

        return () => {
            for(const idx in indices){
                console.log('unmounting')
                websocketDisconnect(indices[idx])
            }
            // Anything in here is fired on component unmount.
        }
        })()},[props.indices,props.securities])

        
    const websocketDisconnect = (index_id) => {
        try{
            stateRef.current[1][index_id].close()
            setWebsockets({...websockets,[index_id]:null})
        }catch{
            console.log('failed to close ws')
        }
    }

    return (
        (<div style={{minHeight:`${params.minHeight}px`,width:'100%',position:'relative'}}>
            <Grid justifyContent='space-between' container sx={{position:'absolute', top:0, left:0 , right:0, zIndex:99}}>
                <Grid>
                    {Object.entries(data).map(([index_id, values], idx) =>{
                        var ret = 0
                        var fr = '2020-01-01 00:00:00'
                        var to = '2020-01-01 00:00:00'
                        if(values && values.length > 0){
                            ret = ((values[values.length -1].value / values[0].value - 1)*100).toFixed(2)
                            fr = values[0].time
                            to = values[values.length-1].time
                        } else {
                            return(null)
                        }
                        return (
                            <Stack sx={{position:'relative', left:5, zIndex:99, fontWeight:300, fontSize:'16px',marginBottom:-1,padding:0}} spacing={0} direction='row' alignItems='center' justifyContent='flex-start'>
                                <Typography style={{fontSize:'12px',fontWeight:500, minWidth:'50px', color:params.lineColors[idx]}}>{refData[index_id] && refData[index_id]}&nbsp;</Typography>
                                <Typography style={{fontSize:'12px',fontWeight:500}}>{ret} %</Typography>
                                { ret > 0 ?
                                    <ArrowDropUpIcon sx={{fontSize:'30px', color:colors[0]}}/>
                                    :
                                    <ArrowDropDownIcon sx={{fontSize:'30px', color:colors[1]}}/>
                                }
                                <Typography style={{fontSize:'12px',fontWeight:500}}>{returnDurationString(fr,to)}</Typography>
                            </Stack>
                        )
                    })}
                </Grid>
                <Grid sx={{display:{xs:'none',sm:'block'}}}>
                    <ButtonGroup style={{position:'relative',right:0,zIndex:99}} variant="text" size="small">
                        {/*<MuiTooltip title="Compare"><IconButton disabled={false} onClick={e=>setCompareDialogOpen(true)}><StackedLineChartIcon fontSize='medium'/></IconButton></MuiTooltip>*/}
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('intraday')}>1d</Button>
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('1mo')}>1mo</Button>
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('qtd')}>qtd</Button>
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('ytd')}>ytd</Button>
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('1y')}>1y</Button>
                        <Button sx={{color:'black',fontSize:'12px'}} onClick={e=> updateDuration('itd')}>itd</Button>
                    </ButtonGroup>
                </Grid>
                <Grid sx={{display:{xs:'block',sm:'none'}}}>
                <Button
                    size='small'
                    id="basic-button"
                    aria-controls={menuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={e=>{setMenuOpen(true);setAnchorEl(e.currentTarget)}}
                    endIcon={menuOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}
                    sx={{color:'black'}}
                    variant='outlined'
                >
                    {duration ? 'duration' : 'duration'}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={e=>setMenuOpen(false)}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        ['intraday','1mo','qtd','ytd','1y','itd'].map((i) => (
                            <MenuItem onClick={e=>{setMenuOpen(false);updateDuration(i)}}>{i}</MenuItem>
                        ))
                    }
                </Menu>
                </Grid>
            </Grid>
            {chartUpdating && <LinearProgress style={{width:"100%",position:'absolute',zIndex:99}}/>}
            <div
               style={{
               width: '100%',
               height: '100%',
               position: 'absolute',
               top: 0,
               left: 0,
               zIndex:98
               }}
           >
           <ResponsiveContainer /* </div>minWidth={parseInt(params.minWidth)} minHeight={parseInt(params.minHeight)} */>
               <AreaChart margin={{ top: 1, right: 1, bottom: 1, left: 1 }}>
               <defs>
                   {Object.entries(data).map(([index_id, values], idx) =>(
                       <linearGradient id={index_id} x1="0" y1="0" x2="0" y2="1">
                           <stop offset="5%" stopColor={params.lineColors ? params.lineColors[idx] : params.lineColors[0]} stopOpacity={0.3}/>
                           <stop offset="95%" stopColor={params.lineColors ? params.lineColors[idx] : params.lineColors[0]} stopOpacity={0}/>
                       </linearGradient>
                   ))}
               </defs>
                   {Object.entries(data).map(([index_id, values], idx) =>{
                       if(values.length > 0){
                       return <Area 
                           name={index_id.substring(0,4)}
                           connectNulls
                           type='linear'
                           strokeWidth={2}
                           animationDuration={3000}
                           isAnimationActive={false}
                           data={values}
                           stroke={params.lineColors ? params.lineColors[idx] : params.lineColors[0]}
                           dot={false}
                           dataKey="value"
                           fillOpacity={1}
                           fill={`url(#${index_id})`}
                       />
                       }
                   })}
                   {<XAxis minTickGap={30} scale="time" axisLine={true} dataKey={isoToUnix} type="number" allowDataOverflow={false} tickFormatter={formatXAxis} domain={['auto','auto']}/>}
                   {<YAxis hide={true} width={70} axisLine={true} dataKey="value" allowDataOverflow={false} domain={['auto','auto']} tickFormatter={formatYAxis}/>}
                   {params.createdAt && <ReferenceLine x={isoToUnix({time:params.createdAt})} stroke="lightgray" strokeDasharray="3 3" strokeWidth={2}><Label angle={-90} value="Live" position="insideBottom" offset={20} /></ReferenceLine>}
                   {/*<CartesianGrid strokeDasharray="3 3" />*/}
                   {<Tooltip content={renderTooltip} />}
                   {/* Object.keys(data).length > 1 && <Legend verticalAlign="top" height={36}/> */}
               </AreaChart>
           </ResponsiveContainer>
           </div>
        </div>)
    );
  }