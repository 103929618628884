import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIndicesQuery,useTeamIndicesQuery } from '../../../store/indexOneApi'


import { Container, TextField, Stack, Snackbar, IconButton, Tooltip ,
Dialog, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell,
TableRow, Button, Typography, InputAdornment
} from '@mui/material'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';


import Paper from '@mui/material/Paper';

import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import SecuritySearch from '../indexwizard/SecuritySearch'

import { TableContainer } from '@mui/material'

    
export default function ComparablesDialog(props) {
  const teamData = useSelector(state => state.main.teamData)
  const [activeTab,setActiveTab] = useState("1")

  const {data:publicIndices = [],isLoading:indicesIsLoading} = useIndicesQuery()
  const {data:teamIndices = [],isLoading:teamIndicesIsLoading} = useTeamIndicesQuery({id:teamData.id})

  /*
  useEffect(() => {(async()=>{
    try {
      Promise.all([
        //api.get(`index/${index_id}/values?duration=31536000&increment=d`),
        api.get(`indices`),
        api.get(`teams/${teamId}/indices`)
      ]).then(([r1,r2]) => {
        setPublicIndices(r1.data)
        setTeamIndices(r2.data)
      })
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  })()},[])
  */

  const handleSecurity = (figi) => {
    if(props.selectedSecurities.includes(figi)){
      props.removeSecurity(figi)
    }else{
      props.addSecurity(figi)
    }
  }

  const handleTest = (figi) => () => {
    console.log(figi)
  }

  const returnTable = (data) => {
    return(
    <TableContainer component={Paper}>
      <Table aria-label="simple table" dense>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const thisId = row.id.concat('-',row.version.toString())
            return(
            <TableRow key={row.id}>
              <TableCell sx={{padding:"5px"}}>{row.name}</TableCell>
              <TableCell sx={{padding:"5px"}} align="right">
                <IconButton
                    size="small"
                  color={props.selected.includes(thisId) ? "secondary" : "primary"}
                  aria-label="open drawer"
                  onClick={e => props.selected.includes(thisId) ?
                    props.removeIndex(thisId)
                    :
                    props.addIndex(thisId)
                  }
                >
                  {props.selected.includes(thisId) ? <CloseIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                </IconButton>
              </TableCell>
            </TableRow>
            )
        })}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }

  const returnBacktestTable = (data) => {
    return(
    <TableContainer component={Paper}>
      <Table aria-label="simple table" dense>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map(([thisId,backtestObj]) => {
            return(
            <TableRow key={thisId}>
              <TableCell sx={{padding:"5px"}}>{backtestObj.name}</TableCell>
              <TableCell sx={{padding:"5px"}}>{backtestObj.created_at}</TableCell>
              <TableCell sx={{padding:"5px"}} align="right">
                <IconButton
                    size="small"
                  color={props.selected.includes(thisId) ? "secondary" : "primary"}
                  aria-label="open drawer"
                  onClick={e => props.selected.includes(thisId) ?
                    props.removeData(thisId)
                    :
                    props.loadData(thisId,backtestObj.url,false,"backtest",backtestObj.name)
                  }
                >
                  {props.selected.includes(thisId) ? <CloseIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                </IconButton>
              </TableCell>
            </TableRow>
            )
        })}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }


  return (
    <Dialog
        onClose={e=>props.setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={props.dialogOpen}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Comparables
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={e=>props.setDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          <DialogContent>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e,v)=>setActiveTab(v)} aria-label="lab API tabs example">
            <Tab label="Backtests" value="0" />
            <Tab label="Public Indices" value="1" />
            <Tab label="Team Indices" value="2" />
            <Tab label="Listed Securities" value="3" />
          </TabList>
        </Box>
        <TabPanel value="0">{returnBacktestTable(teamData?.backtests ?? {})}</TabPanel>
        <TabPanel value="1">{indicesIsLoading ? <CircularProgress/> : returnTable(publicIndices)}</TabPanel>
        <TabPanel value="2">{teamIndicesIsLoading ? <CircularProgress/> : returnTable(teamIndices)}</TabPanel>
        <TabPanel value="3">
          <SecuritySearch
            show={true}
            selected={props.selected}
            selectFunction={e=> handleSecurity(e[2])}
          />
        </TabPanel>
      </TabContext>
      </DialogContent>
      </Dialog>
  )
}