import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Placeholder images
import step1_1 from '../../../src/images/step1_1.gif';
import step1_2 from '../../../src/images/step1_2.gif';
import step2_1 from '../../../src/images/step2_1.gif';
import step2_2 from '../../../src/images/step2_2.gif';
import step3_1 from '../../../src/images/step3_1.gif';
import step3_2 from '../../../src/images/step3_2.gif';
import step4_1 from '../../../src/images/step4_1.gif';
import step4_2 from '../../../src/images/step4_2.gif';

const steps = [
  {
    label: 'Upload',
    description: 'Use rules-based filters, upload a custom basket or upload a Python code file.',
    images: [step1_1, step1_2],
  },
  {
    label: 'Test',
    description: 'View backtest results.',
    images: [step2_1, step2_2],
  },
  {
    label: 'Launch',
    description: 'Launch a live index and automate calculation, rebalancing and corporate action treatment.',
    images: [step3_1, step3_2],
  },
  {
    label: 'Distribute',
    description: 'Generate fact sheets, integrate to other platforms and deliver tracking files to clients.',
    images: [step4_1, step4_2],
  },
];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageIndex, setImageIndex] = React.useState(0);

  // Time to wait before switching between images (in milliseconds)
  const imageSwitchInterval = 5000; // Switch every 5 seconds

  // Automatically switch between the two images of the active step
  React.useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % steps[activeStep].images.length);
    }, imageSwitchInterval);

    // Clear the interval when the component is unmounted or when the activeStep changes
    return () => clearInterval(interval);
  }, [activeStep]); // Re-run the effect whenever the activeStep changes

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
    setImageIndex(0); // Reset to the first image of the next step
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + steps.length) % steps.length);
    setImageIndex(0); // Reset to the first image of the previous step
  };

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
    setImageIndex(0); // Reset to the first image when the step is manually selected
  };

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      width: '100%',
      justifyContent: 'center',
    },
    image: {
      maxHeight: '340px', // Increased size
      maxWidth: '100%',
      borderRadius: '10px',
      margin: '20px 0',
    },
  }));

  const classes = useStyles();

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '900px' }}>
        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          sx={{
            '& .MuiStepLabel-label': {
              fontSize: '1.5rem', // Increased font size for step labels
              lineHeight: '2rem', // Adjust line height if necessary
            },
            padding: '1rem',
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label} onClick={() => handleStepClick(index)} style={{ cursor: 'pointer' }}>
              <StepLabel>
                <Typography variant="h6">{step.label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
          <IconButton onClick={handleBack} disabled={activeStep === 0}>
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ marginX: '20px', textAlign: 'center' }}>
            <img
              className={classes.image}
              src={steps[activeStep].images[imageIndex]}
              alt={`Step ${activeStep + 1} - Image ${imageIndex + 1}`}
            />
            <Typography>{steps[activeStep].description}</Typography>
          </Box>
          <IconButton onClick={handleNext}>
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
