import React, {useState, useEffect} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import ContactForm from '../../elements/ContactForm'


import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button'

//card
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';




const files = [
  {
    title:'The SEC Marketing Rule',
    description:'An overview of how the new SEC marketing guidance affects RIAs',
    img_url:'https://drive.google.com/thumbnail?id=1yRmHwXjqP4PlUhf63MRkAd1HgZzWnU9v',
    url:'https://drive.google.com/file/d/1OOPMudyKPmfRCaEq1r8vpdRb5W6iZ--u/view?usp=sharing'
  },
  {
    title:'Index Front-running',
    description:'How index front-running can be mitigated through smart rebalancing',
    img_url:'https://drive.google.com/thumbnail?id=1d1TNzzhXs3oelzNXZOkzjDuMTECGPLeN',
    url:'https://drive.google.com/file/d/1ONL51D1iwshXJGuB1-iiXHBrMgcthKa2/view?usp=sharing'
  },
  {
    title:'Index One Overview',
    description:'An overview of our value proposition and core functionality',
    img_url:'https://drive.google.com/thumbnail?id=1xRgRJRWzrFvsYjsvsMLx9jF-f-fE6fc2',
    url:'https://drive.google.com/file/d/1xnxxNk5HlYvd2Ulp2IVDEQJi2a1RyiAB/view?usp=sharing'
  }
]

const pressreleases = [
  {
    title:'BrandLoyalties confirms Brand Luminosity Global Excess Returns',
    description:'Brand Loyalties launch an index series using proprietary metrics',
    img_url:'https://drive.google.com/thumbnail?id=1Ns7ybMFh1svVTdUi0aupLIcBufq5g9dj',
    url:'https://drive.google.com/file/d/1g5r3jc0tiNqmlAYHPpjA4H1VDPRh0Ely/view?usp=sharing'
  },
  {
    title:'Stock Card x Index One',
    description:'Index One launches a financial influencer index with Stock Card',
    img_url:'https://lh3.googleusercontent.com/d/11Qor5ECO9NoSsiZ7AqrmgFujDGdVm32E',
    url:'https://drive.google.com/file/d/1glP3C9giPQQBGBXhRBorCKz41p-4vSvB/view?usp=sharing'
  },
  {
    title:'MoveUp Financial x Index One',
    description:'MoveUp Financial launches an index with Index One',
    img_url:'https://drive.google.com/thumbnail?id=1ao9q9SCD3MtG4f7UrzuJpE2l0-tCNsAK',
    url:'https://drive.google.com/file/d/1Yc2m0NuSX-ZVZdtJTGOdDavX3f-k4OH9/view?usp=sharing'
  },
  {
    title:'African American Consumer Growth Index x Index One',
    description:'Index One helps launch an index targeting the US wealth gap',
    img_url:'https://drive.google.com/thumbnail?id=1pCFDxtSPHIcluDEY6Ni0nRQ_Nzmfr--f',
    url:'https://drive.google.com/file/d/1yq1FNqKOhg0QRoVQjXDfh-r_cgFiDpYg/view?usp=sharing'
  },
  {
    title:'One 6 Research x Index One',
    description:'One 6 Research launches an index with Index One',
    img_url:'https://drive.google.com/thumbnail?id=whmRWguOSO2EcPcSOd7R',
    url:'https://drive.google.com/file/d/10dlTbax6XyC_ScooKvzU4o8gThCByqXj/view?usp=sharing'
  },
  {
    title:'Brompton Funds x Index One',
    description:' The Index One Split Preferred Share Index acts as a benchmark for the first Canadian split corp. preferred share ETF, the Brompton Split Corp. Preferred Share ETF (SPLT CN Equity).',
    img_url:'https://drive.google.com/thumbnail?id=1cOrPCsl229V-Vl6XrWTy26fXWpxUzYBQ',
    url:'https://drive.google.com/file/d/11H7SmKNpfqrnJZfCt3sycU0u_HFIjR1Z-0_nE3oqYwg/view?usp=sharing'
  },
  {
    title:'Drawbridge Strategies x Index One',
    description:'Index One is proud to announce the launch of the Drawbridge US Equity Focus 10 Index with Drawbridge Strategies.',
    img_url:'https://drive.google.com/thumbnail?id=1o-11bUbi9oMavFWxqOLOTbu7-XpNfp6p',
    url:'https://drive.google.com/file/d/1pIQ--ed7areJW4iCrpRSsDngPc8ylIKX/view?usp=sharing'
  },
  {
    title:'phaseinvest x Index One',
    description:'Index One is proud to announce the launch of the phaseinvest US indices with phaseinvest.',
    img_url:'https://lh3.googleusercontent.com/d/1Zacn4Vc3xTa3JrQK1eJksPqAOp6fnXp4',
    url:'https://drive.google.com/file/d/1pIQ--ed7areJW4iCrpRSsDngPc8ylIKX/view?usp=sharing'
  },
  {
    title:'The Access Control Executive Brief Focus x Index One',
    description:'Index One is proud to announce the launch of the The Access Control Executive Brief Focus 10 Index.',
    img_url:'https://lh3.googleusercontent.com/d/1TDVRsUZR_hGrcIOikeliNe7xxjBnYD-Q',
    url:'https://drive.google.com/file/d/1HnxEAUx1R1gyJBpLZnVaC3lQ8-HSn8Dl/view?usp=sharing'
  },
  {
    title:'SPLT surpasses $100 million AUM in 6 months',
    description:'Index One is proud to act as the index calculation agent for Brompton Funds SPLT ETF, which has surpassed C$100 million AUM in just over 6 months.',
    img_url:'https://lh3.googleusercontent.com/d/1cOrPCsl229V-Vl6XrWTy26fXWpxUzYBQ',
    url:'https://drive.google.com/file/d/1zwfbVI8-7zuF9rV-bGLsEShpAN312iID/view?usp=sharing'
  },
  {
    title:'World’s First Police and Public Safety Index now on Quorus',
    description:'One 6 Research’s Police & Public Safety Index, calculated by Index One, can now be discovered on Quorus.',
    img_url:'https://lh3.googleusercontent.com/d/1xbIUzNBBW5EKl3zgwQSM90TZUL5C_ZDY',
    url:'https://drive.google.com/file/d/1ylRLl9_QUo39faj2L5_y-0TPBjDkjqfP/view?usp=sharing'
  },
  {
    title:'World’s First Police and Public Safety Index now on Smartleaf Asset Management',
    description:'One 6 Research’s Police & Public Safety Index, calculated by Index One, can now be discovered on Smartleaf Asset Management.',
    img_url:'https://lh3.googleusercontent.com/d/1Gy27ulp_mD2VFNgGjvt4VRKxemrOjPYC',
    url:'https://drive.google.com/file/d/12G9dEVGhPq58XTapSdzqGxk_K-VoVNdZ/view?usp=sharing'
  },
  {
    title:'World’s First Police and Public Safety Index now on C8 Technologies',
    description:'One 6 Research’s Police & Public Safety Index, calculated by Index One, can now be discovered on C8 Technologies.',
    img_url:'https://lh3.googleusercontent.com/d/1ZgXDRclfLCdTo7cSa368zKdtYzsW7ys9',
    url:'https://drive.google.com/file/d/19vJ3scVpInwMiPAMbe5rJqtq2WWV205h/view?usp=sharing'
  },
  {
    title:'Index One and BX Partners: Direct Indexing Strategies for Financial Advisors',
    description:'Index One is proud to announce the new partnership with BX Partners, a direct indexing platform that provides access to a broad range of asset classes and market sectors, allowing for a well-diversified investment portfolio.',
    img_url:'https://lh3.googleusercontent.com/d/1oOkvFSTc3ynvx8SK6jGN71zVB63CQW1R',
    url:'https://drive.google.com/file/d/1WlvCV6xA0mcImC3vdW0vufzLCZw08lC/view?usp=sharing'
  },
  {
    title:'PMV Downside Defender Indices',
    description:'Index One provides the indexing tools to backtest, launch and calculate the PMV Downside Defender Indices for PMV Capital.',
    img_url:'https://lh3.googleusercontent.com/d/1QvqB2wEJgV7T_CYjXwYwkqbjZR9zznXT',
    url:'https://drive.google.com/file/d/1mRVOXHyqxUj9mrfOIxNOu785JBd2iET0/view?usp=sharing'
  },
  {
    title:'Brompton Index One Canadian Cash Flow Kings Index and Brompton Index One U.S. Cash Flow Kings Index',
    description:'The Brompton Index One Canadian Cash Flow Kings and Brompton Index One U.S. Cash Flow Kings indices act as benchmarks for Brompton Canadian Cash Flow Kings ETF (KNGC) and Brompton U.S. Cash Flow Kings ETF (KNGU).',
    img_url:'https://lh3.googleusercontent.com/d/1cOrPCsl229V-Vl6XrWTy26fXWpxUzYBQ',
    url:'https://drive.google.com/file/d/1ewJje5BA3XeJl7U0BykE8jgOpEUfkeB-/view?usp=sharing'
  },
  {
    title:'Brompton Index One International Cash Flow Kings',
    description:'The Brompton Index One International Cash Flow Kings Act as Benchmarks for the Brompton International Cash Flow Kings ETF (KNGX).',
    img_url:'https://lh3.googleusercontent.com/d/1cOrPCsl229V-Vl6XrWTy26fXWpxUzYBQ',
    url:'https://drive.google.com/file/d/1hKwS1GgYhXwqahpE8iBpm5fQ73mR6a4o/view?usp=sharing'
  },
  {
    title:'DMAI Alpha 1 Index',
    description:'Deep Markets AI has launched the DMAI Alpha 1 Index, independently calculated by Index One.',
    img_url:'https://lh3.googleusercontent.com/d/1cOrPCsl229V-Vl6XrWTy26fXWpxUzYBQ',
    url:'https://drive.google.com/file/d/1Fc_v8ule63POHEiHmParAxoFXqrH7pxR/view?usp=sharing'
  }
]

const casestudies = [
  {
    title:'Brand Luminosity Metrics',
    description:'Index One supports Brand Loyalties in launching a series of indices',
    img_url:'https://lh3.googleusercontent.com/d/1btTlBvgZmsE4wef9k39hnXlPDQdB8BHE',
    url:'https://drive.google.com/file/d/1jzDBj9dntjSWfUBroXDPe6gZuhivbsey/view?usp=sharing'
  },
  {
    title:'Stock Card Indices',
    description:'Index One supports Stock Card in launching a series of indices',
    img_url:'https://drive.google.com/thumbnail?id=1yvQiOgk_nwKy01BJ5p-MGWUsY5vHYP57',
    url:'https://drive.google.com/file/d/1_N3sbuH5bowJZPPK5hdbnm9GcZjupR44/view?usp=sharing'
  },
  {
    title:'ValuEngine Backtesting',
    description:'Index One supports ValuEngine in backtesting strategies',
    img_url:'https://drive.google.com/thumbnail?id=1HAwE1_ypwp3ysl_ujvDlRR6kIRBB1xGo',
    url:'https://drive.google.com/file/d/1Ak4qehjn2nfPBQlZvZ2uiIERCSlofnzB/view?usp=sharing'
  },
  {
    title:'MoveUp Financial',
    description:'MoveUp Financial launches an index with Index One',
    img_url:'https://lh3.googleusercontent.com/d/1bkgNskQU0KU_tn6yGpdEpuAsUXgSDZkl',
    url:'https://drive.google.com/file/d/1ZbMJR-k0oKk0M7cbR0xQT2f28JpMxOQd/view?usp=sharing'
  },
  {
    title:'FACTS® 200 Index',
    description:'Trust Across America launches an index with Index One',
    img_url:'https://drive.google.com/thumbnail?id=1XOSacU6FAj2xWZ6SA8-Mk2SUhEuhO2kY',
    url:'https://drive.google.com/file/d/1rZQWf2qX81WydyAnUD8YC6F_UnSm0q_l/view?usp=sharing'
  },
  {
    title:'S Factor Index Series',
    description:'Index One powered the creation of the S Factor index series based on social impact scores.',
    img_url:'https://drive.google.com/thumbnail?id=1ewTapmWjomM7Lnv3KEP5tmSsvy41aXNY',
    url:'https://drive.google.com/file/d/1zAuvDCP8gsw69JZmtin1ODWuwyCyznYa/view?usp=sharing'
  },
  {
    title:'African American Consumer Growth Index',
    description:'Index One helps launch an index targeting UN SDG 10',
    img_url:'https://drive.google.com/thumbnail?id=1XxcAthDGyb6G3QhyEZcdbEunsd-34Tiz',
    url:'https://drive.google.com/file/d/10SZKJ3VaNtWbq42Itwb1ni1_5xu9h79P/view?usp=sharing'
  },
  {
    title:'POL Index',
    description:'Index One helps launch a new thematic index.',
    img_url:'https://drive.google.com/thumbnail?id=1OAHcVVMqWvqjcvEPTfVo6ipmuLND3VPX',
    url:'https://drive.google.com/file/d/1UH9LZ-bHr3Z2nvo_7mCbnVqTECueCIvG/view?usp=sharing'
  },
  {
    title:'SPLT: Brompton Funds',
    description:'Index One Split Preferred Share Index for Brompton Funds’ SPLT ETF.',
    img_url:'https://drive.google.com/thumbnail?id=10Le_AF5R_FuMWyToAoCTw9w-sE_23KhU',
    url:'https://drive.google.com/file/d/1440v5qqOaJOF5uuoM2sA-HVW4rfh2ksR/view?usp=sharing'
  },
  {
    title:'Drawbridge US Equity Focus 10 Index',
    description:'Index One and Drawbridge Strategies launch the Drawbridge US Equity Focus 10 Index.',
    img_url:'https://drive.google.com/thumbnail?id=1W2rQ3w5wX3XzIec99ad5HvHkQw53Hn6S',
    url:'https://drive.google.com/file/d/1If2dhBmZJjmod-1blzY8kwbqBJKOBn-D/view?usp=sharing'
  },
  {
    title:'phaseinvest US indexes',
    description:'Index One and Phaseinvest built a US index series that offers a set of indexes that  extract factor risk premia using proprietary data analytics.',
    img_url:'https://drive.google.com/thumbnail?id=1Guc8GEq0o6DLn_NhSNx4oz-rU-ZiRc-u',
    url:'https://drive.google.com/file/d/1STbZwD0FOV51hTUrbdfTwNHl1cpQRMmH/view?usp=sharing'
  },
  {
    title:'BX Partners: Direct Indexing',
    description:'By utilising Index One’s custom indexing capabilities, BX Partners are able to build and provide a wide range of indices to financial advisors looking to provide direct indexing strategies to their own clients.',
    img_url:'https://drive.google.com/thumbnail?id=1z77e7KKM1MzFBM7kGuteqNgm_6h5SaGB',
    url:'https://drive.google.com/file/d/19LvTLVvKfysqphCs14G1espj22LLdpW5lDLriYvhEko/view?usp=sharing'
  },
  {
    title:'Nanyang Technological University: Sustainable Finance Course',
    description:'Index One provides NTU’s Sustainable Finance class with a backtesting tool that allows them to incorporate a backtest into their class projects.',
    img_url:'https://drive.google.com/thumbnail?id=1I-8N_DxMm5M1QOhDEnG7QrUQDFC8aTXf',
    url:'https://drive.google.com/file/d/1SAzXBOX9AsrCANopQv9Dg31LjvKpTNJp/view?usp=sharing'
  },
  { title:'University of London - Birkbeck College: MSc Environment and Sustainability Dissertation',
  description:'Index One provides Michael Reed with a backtesting tool to support his dissertation research.',
  img_url:'https://drive.google.com/thumbnail?id=1f7b3gnWSIKOTbGqGDg4Zsnnoy6kDLw60',
  url:'https://drive.google.com/file/d/1K3sqP1a3OYsq1e1_6jiSR0t7w3me9CM8/view?usp=sharing'
}
  
]

const press = [
  {
    title:'Index Provider Disruptor Launches',
    issuer:'ETF Stream',
    url:'https://www.etfstream.com/news/index-provider-disruptor-launches/',
    thumbnail:'https://images.ctfassets.net/lr0atmu04u9z/pLAjQWit25sJcivEWxuIv/63196dc73d8bcbc97515dfcb20e68721/alexander-berg.jpg?fm=avif&w=1024'
  },
  {
    title:'Index One Aims To Deliver Cost Effective Custom Indexing Solution',
    issuer:'RIA Channel',
    url:'https://www.riachannel.com/index-one-aims-to-deliver-cost-effective-custom-indexing-solution/',
    thumbnail:'https://www.riachannel.com/wp-content/uploads/2021/10/Index-One-Alexander-Berg-Featured.jpg'
  },
  {
    title:"Unbelievable: The Inverse Cramer ETF Is Beating the Market by 16 Percent Now",
    issuer:'Wccftech',
    url:'https://wccftech.com/unbelievable-the-inverse-cramer-etf-is-beating-the-market-by-16-percent-now/',
    thumbnail:'https://cdn.wccftech.com/wp-content/uploads/2022/09/image_2022-09-07_171025750-740x493.png'
  },
  {
    title:'New platform cuts index licensing fees to new lows',
    issuer:'AM Watch',
    url:'https://amwatch.com/article13846981.ece',
    thumbnail:'https://photos.watchmedier.dk/YUnfaNXmjrQO-KOuUGYvKcE56_zoIa-EYEt4oTP0mrw/resize:fill:960:0:0/plain/https%3A%2F%2Fphotos.watchmedier.dk%2FImages%2Farticle13851805.ece%2FALTERNATES%2Fschema-16_9%2Fdoc7k8jrioh2jb1jr5sfie1.jpg'
  },
  {
    title:"Aligning investments to Leo's dating strategy pays off",
    issuer:'Financial Standard',
    url:'https://www.financialstandard.com.au/news/aligning-investments-to-leo-s-dating-strategy-pays-off-179796575',
    thumbnail:'https://nucleuswealth.com/wp-content/uploads/2020/07/FS-Logos-Landscape-and-Portrait-Black-and-White-02.png'
  },
  {
    title:'Customized Indexing Enables Boutique Financial Advisors to Effectively Compete with Big Brand Wealth Managers',
    issuer:'BizTechReports',
    url:'https://www.biztechreports.com/news-archive/2022/8/4/customized-indexing-enables-boutique-financial-advisors-to-effectively-compete-with-big-brand-wealth-managers',
    thumbnail:'https://images.squarespace-cdn.com/content/v1/59f9e1d780bd5ed5ad860581/d6ffabef-e643-4ff0-8a52-ee7fd150e6ea/1517276987520.jpeg?format=500w'
  },
  {
    title:'Revolutionising Index Solutions: How Does This London-Based Startup Outshine Competitors?',
    issuer:'UKT News',
    url:'https://ukt.news/revolutionising-index-solutions-how-does-this-london-based-startup-outshine-competitors/',
    thumbnail:'https://i0.wp.com/ukt.news/wp-content/uploads/2024/01/benjamin-davies-Oja2ty_9ZLM-unsplash-4.jpg?w=1920&ssl=1'
  },
  {
    title:'ETF Idea: Police & Public Safety',
    issuer:'The ETF Shelf',
    url:'https://theetfshelf.substack.com/p/9-etf-charts-i-thought-youd-like',
    thumbnail:'https://drive.google.com/thumbnail?id=1ddiAeBNnjCCdBgZfTJ2yUy2Dm39eQbs4'
  },
  {
    title:'Infuencerzy inwestycyjni i ich realne kompetencje na przykładzie Jima Cramera',
    issuer:'FXMAG',
    url:'https://www.fxmag.pl/gielda/infuencerzy-inwestycyjni-i-ich-realne-kompetencje-na-przykladzie-jima-cramera-fajna-zabawa-ale-z-inwestowaniem-wiele-wspolnego-nie-ma',
    thumbnail:'https://drive.google.com/thumbnail?id=1dn0Y9wJ9VfX88NznaXS7pzBYOyavuaKR'
  },

]

const rss2json_key = 'er1jh7t8pxfnerocgfxekksrjfu8amxatm7eel4t'

const toText = (node) => {
  let tag = document.createElement("div");
  tag.innerHTML = node;
  node = tag.innerText;
  return node;
}

const toHtml = (i) => {

}

const returnImageCard = (img,title,description) => {
  return(
    <Card sx={{height:'100%'}}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="140"
        image={img}
        alt="img name"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  )}

export default function Resources(props) {
  const state = useSelector((state) => state.main)
  const [posts,setPosts] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {(async()=>{
      dispatch({type:'SET_PAGE_TITLE',payload:'Resources'})
      document.title = `Index One | Resources`
      try{
        const dat = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@indexone&api_key=${rss2json_key}&count=100`)
        const dat2 = await dat.json()
        setPosts(dat2.items)
      } catch (error){
        console.log(error)
      }


  })()},[])

  return (
    (<Container disableGutters maxWidth={false} sx={{backgroundColor:'#fafafa'}}>
      <Grid container spacing={5} sx={{padding:'2em'}}>
        <Grid
          size={{
            xs: 12,
            sm: 7
          }}>
          <Typography variant='h4' sx={{fontWeight:300}}>Blogs</Typography>
          <br/>
          <Stack spacing={3}>
            {posts.map((post) => (
              <Paper sx={{padding:'2em', width:'100%'}} /*onClick={e=> window.open(post.link, "_blank")}*/ onClick={e=> navigate(`../resourceid/${post.link.substring(post.link.indexOf("@indexone/") + 10, post.link.lastIndexOf("?source"))}`)}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='h4'>{post.title}</Typography>
                  <Typography>{post.pubDate.substring(0,10)}</Typography>
                </Stack>
                <br/>
                <Grid container spacing={3}>
                {post.thumbnail.startsWith("https://cdn") &&
                  <Grid size={3}>
                  <img width='100%' src={post.thumbnail}/>
                  </Grid>
                }
                <Grid sx={{overflow:'hidden'}} size="grow">
                {toText(post.content.substring(0,400))}
                ...
                </Grid>
                </Grid>
              </Paper>
            ))}
          </Stack>
        </Grid>

        
        
        <Grid
          size={{
            xs: 12,
            sm: 5
          }}>
          <Stack spacing={1}>
            <>
      <Stack direction='row' spacing={10} alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
       <a href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=6869268645617418240"><Button sx={{ width: '150px' }} variant="outlined">Subcribe to our Linkedin Newsletter</Button></a>
       <a href="https://indexone.us12.list-manage.com/subscribe?u=aeaaf852c592746248095a6de&id=5283a2ac3c"><Button sx={{ width: '150px' }} variant="outlined">Subcribe to our E-Mail Newsletter</Button></a>
       </Stack>
       <br/><br/>

       <Typography variant='h4' sx={{fontWeight:300}}>Webinars</Typography>
       <Grid container spacing={2} alignItems='stretch' direction='row'>
       <Grid container justifyContent='space-evenly' alignItems='center' spacing={1}>
       
          <Grid>
          <Typography variant='h5'>
          Building an Alpha-Seeking Portfolio: Asset Allocation & Risk Management
         </Typography>
          <iframe width="550" height="315" src="https://www.youtube.com/embed/Hu-HIviqt3c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         <Typography>
         Join Index One for a webinar with BX Partners, Artha, Libra Investment Services, and Trendrating to explore alpha strategies, market impacts, trend capture, and building your own alpha-seeking asset allocation.
         </Typography>
          </Grid>
<br/>
          <Grid>
          <Typography variant='h5'>
          How to Enter the European UCITS ETF Market | Index One, HANetf, Tidal
         </Typography>
          <iframe width="550" height="315" src="https://www.youtube.com/embed/vDT2NUZUQ4s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <Typography>
          Index One, HANetf and Tidal collaborates on a live webinar: How to Enter the European UCITS ETF Market
         </Typography>
          </Grid>
        </Grid>
      </Grid>

      <br/><br/>

              <Typography variant='h4' sx={{fontWeight:300}}>Whitepapers</Typography>
              <Grid container spacing={2} alignItems='stretch' direction='row'>
              {files.map((file) => (
                <Grid size={6}>
                  
                { state.authenticated ?
                <a href={file.url} target="_blank" style={{color:'black',textDecoration:'none'}}>
                  {returnImageCard(file.img_url,file.title,file.description)}
                </a>
                :
                <a href='/login' style={{color:'black',textDecoration:'none'}}>
                  {returnImageCard(file.img_url,file.title,file.description)}
                </a>
                }
                </Grid>
              ))}
              </Grid>
            </>
            <>
            <br/><br/>
              <Typography variant='h4' sx={{fontWeight:300}}>Press Releases</Typography>
              <Grid container spacing={2}>
              {pressreleases.map((file) => (
                <Grid
                  size={{
                    xs: 12,
                    sm: 4
                  }}>
                <a href={file.url} target="_blank" style={{color:'black',textDecoration:'none'}}>
                  {returnImageCard(file.img_url,file.title,file.description)}
                </a>
                </Grid>
              ))}
              </Grid>
            </>
            <>
            <br/><br/>
                <Typography variant='h4' sx={{fontWeight:300}}>Press Coverage</Typography>
                <Stack direction='column' spacing={2}>
                {press.map((p) => (
                  <Paper sx={{height:'100px',padding:'10px'}}>
                    <a href={p.url} target="_blank" style={{color:'black',textDecoration:'none'}}>
                  <Grid container spacing={3}>
                    <Grid size={3}>
                      <div style={{width:'100%',height:'80px',overflow:'hidden', objectFit:'contain'}}>
                        <img width='100%' src={p.thumbnail}/>
                      </div>
                    </Grid>
                    <Grid
                      direction='column'
                      alignItems='center'
                      justifyContent='center'
                      size="grow">
                      <Typography sx={{fontWeight:400}}>{p.issuer}</Typography>
                      <Typography>{p.title}</Typography>
                    </Grid>
                  </Grid>
                    </a>
                  </Paper>
                ))}
              </Stack>
            </>
            <>
            <br/><br/>
              <Typography variant='h4' sx={{fontWeight:300}}>Case Studies</Typography>
              <Grid container spacing={2}>
              {casestudies.map((file) => (
                <Grid size={4}>
                <a href={file.url} target="_blank" style={{color:'black',textDecoration:'none'}}>
                  {returnImageCard(file.img_url,file.title,file.description)}
                </a>
                </Grid>
              ))}
              </Grid>
            </>
          </Stack>
        </Grid>
      </Grid>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
      <ContactForm/>
      </Box>
    </Container>)
  );
}