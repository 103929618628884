import React, {useCallback,useState,useEffect} from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import Button from '@mui/material/Button'
import snakeToTitleCase from '../../helpers/snakeToTitleCase';
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, Stack,Paper, IconButton,Dialog,DialogContent,DialogTitle } from '@mui/material';
import { deleteFile } from '../../helpers/fileHelper';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTeamQuery } from '../../../store/indexOneApi';

//test
const defaultColumns = ["file_name","created_at"]

export default function FileDownloadTable(props) {
  const teamId = useSelector(state => state.main.teamData.id)
  const teamData = useTeamQuery({id:teamId})
  const [filtered,setFiltered] = useState([])
  const [columns,setColumns] = useState(defaultColumns)

  useEffect(() => {(async()=>{
    if(props.filter){
      const thisFiltered = Object.fromEntries(Object.entries(teamData?.data?.files ?? {}).filter(([_, value]) => value.file_type === props.filter))
      setFiltered(thisFiltered)
    }
    if(props.columns){
      setColumns(props.columns)
    }
  })()},[props.columns,props.filter,teamData])


  return (
      <TableContainer sx={{maxHeight:'100%',overflowY:'auto'}}>
        <Table  aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(col=>(<TableCell>{snakeToTitleCase(col)}</TableCell>))}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Object.entries(filtered).map(([key, value]) => (
            <TableRow>
              {columns.map(col=>(<TableCell>{value[col]}</TableCell>))}
              <TableCell>
                <Stack direction='row' spacing={1}>
                  <IconButton onClick={e=>props.handleDownload(value)}><DownloadIcon /></IconButton>
                  <IconButton onClick={e=>deleteFile(key)}><DeleteOutlineIcon /></IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}