
import React, { PureComponent } from "react";


import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import colors from '../../helpers/colors'
import CustomizedAxisTick from './CustomAxis'

function SimpleChartf(props) {
  return (
    <ResponsiveContainer width="100%" height="100%">
        
        <LineChart
        title="Chart of PU x UV"
        width='100%'
        height='100%'
        data={props.data}
        syncId="anyId"
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={60} dataKey='time' tick={<CustomizedAxisTick />} />
        <YAxis domain={['auto', 'auto']} />
        <Brush gap={1} dataKey='time' />
        <Tooltip />
        
        {Object.keys(props.refData).filter(x=>props.refData[x].selected == true).map((security,idx) => (
            <Line type="monotone" dataKey={security} stroke={colors[idx]} dot={false}/>
        ))}
        </LineChart>
    </ResponsiveContainer>
  );
}

const SimpleChart = React.memo(SimpleChartf);
export default SimpleChart