import React from 'react';
import { Stack,Snackbar,LinearProgress,IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


export default function LoadingSnackbar(props) {
    function returnMessage(params) {
        return(
            <Stack direction='column' spacing={1} sx={{width:"250px"}}>
            {Object.entries(params).map(([id,data])=>
                <Stack direction='column' sx={{width:'100%'}}>
                    <Stack direction='row' sx={{width:'100%'}}>
                        {/*JSON.stringify(data)*/}
                        {/*JSON.stringify(id)*/}
                        {id}
                        <div style={{flexGrow:1}}/>
                        {data.status == "completed" &&
                            <IconButton
                                size='small'
                                aria-label="close"
                                color="inherit"
                                onClick={e=>{
                                    props.setPending(prevState => ({ ...Object.fromEntries(Object.entries(prevState).filter(([key]) => key !== id)) }));
                                    props.scrollToResults()
                                }}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        }
                        <IconButton
                            size='small'
                            aria-label="close"
                            color="inherit"
                            onClick={e=>props.setPending(prevState => ({ ...Object.fromEntries(Object.entries(prevState).filter(([key]) => key !== id)) }))}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    {data.status == 'loading' &&
                        <>
                            {data.loading_progress ?
                                <LinearProgress sx={{width:'100%'}} variant="determinate" value={data.loading_progress}/>
                                :
                                <LinearProgress sx={{width:'100%'}}/>
                            }
                        </>
                    }
                </Stack>
            )}
            </Stack>
        )
    }
    return (
          <Snackbar
            open={Object.keys(props.pending).length}
            anchorOrigin={{vertical:"bottom",horizontal:"right"}}
            message={returnMessage(props.pending)}
        />
    );
  }