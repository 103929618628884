
import React, { PureComponent, useEffect } from "react";


import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import colors from '../../helpers/colors'
import CustomizedAxisTick from "../indexwizard/CustomAxis";


function isoToUnix(x){
  var d = new Date(x['time'].replace(/ /g,"T")+"Z")
  return (Math.round(d.getTime()/1000) - d.getTimezoneOffset()*60)
}


export default function PerformanceChart(props) {

  return (
    <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width='100%'
          height='100%'
          margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
          }}
        >
        <XAxis domain={['auto','auto']} height={60} dataKey={isoToUnix} type="number"/>
        <YAxis domain={['auto','auto']} />
        <Tooltip />
        
        {Object.entries(props.data).map(([id,data],idx) => {
          if(data.values) return(<Line connectNulls stroke={id === props.primaryId ? colors[0] : colors[idx+1]} type="monotone" dataKey="value" data={data.values} dot={false} name={props.idMapping[id] ?? id}/>)
        })}
        </LineChart>
    </ResponsiveContainer>
  );
}