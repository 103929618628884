import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIndicesQuery,useTeamIndicesQuery } from '../../store/indexOneApi'


import { Container, TextField, Stack, Snackbar, IconButton, Tooltip ,
Dialog, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell,
TableRow, Button, Typography, InputAdornment
} from '@mui/material'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';


import Paper from '@mui/material/Paper';

import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import SecuritySearch from './indexwizard/SecuritySearch'


import '../helpers/monokai.css'
import { TableContainer } from '@mui/material'

    
export default function IndexCompareInterface(props) {
  const teamId = useSelector(state => state.main.teamData.id)
  const [activeTab,setActiveTab] = useState("1")

  const {data:publicIndices = [],isLoading:indicesIsLoading} = useIndicesQuery()
  const {data:teamIndices = [],isLoading:teamIndicesIsLoading} = useTeamIndicesQuery({id:teamId})

  /*
  useEffect(() => {(async()=>{
    try {
      Promise.all([
        //api.get(`index/${index_id}/values?duration=31536000&increment=d`),
        api.get(`indices`),
        api.get(`teams/${teamId}/indices`)
      ]).then(([r1,r2]) => {
        setPublicIndices(r1.data)
        setTeamIndices(r2.data)
      })
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  })()},[])
  */

  const handleSecurity = (figi) => {
    if(props.selectedSecurities.includes(figi)){
      props.removeSecurity(figi)
    }else{
      props.addSecurity(figi)
    }
  }

  const handleTest = (figi) => () => {
    console.log(figi)
  }

  const returnTable = (data) => {
    return(
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const thisId = row.id.concat('-',row.version.toString())
            return(
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell align="right">
                <IconButton
                  color={props.selected.includes(thisId) ? "secondary" : "primary"}
                  aria-label="open drawer"
                  onClick={e => props.selected.includes(thisId) ?
                    props.removeIndex(thisId)
                    :
                    props.addIndex(thisId)
                  }
                >
                  {props.selected.includes(thisId) ? <CloseIcon /> : <AddIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
            )
        })}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }


  return (
    <Container maxWidth={false} disableGutters>
      <Alert severity="info">This feature is in beta, please contact us for any feedback</Alert>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e,v)=>setActiveTab(v)} aria-label="lab API tabs example">
            <Tab label="Public Indices" value="1" />
            <Tab label="Team Indices" value="2" />
            <Tab label="Listed Securities" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">{indicesIsLoading ? <CircularProgress/> : returnTable(publicIndices)}</TabPanel>
        <TabPanel value="2">{teamIndicesIsLoading ? <CircularProgress/> : returnTable(teamIndices)}</TabPanel>
        <TabPanel value="3">
          <SecuritySearch
            show={true}
            selected={props.selectedSecurities}
            selectFunction={e=> handleSecurity(e[2])}
          />
        </TabPanel>
      </TabContext>
    </Container>
  )
}