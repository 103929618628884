import React, {useState, useEffect} from 'react';
import { useSearchParams,useParams } from "react-router-dom"
import { useIndexWeightingQuery } from '../../store/indexOneApi';
import getParamsJoined from '../helpers/paramHelper'

import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ReturnLogo from './ReturnLogo'

export default function WeightingLogos(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    var params = getParamsJoined(['limit','indexId'],props,searchParams,useParams())
    const {data,isLoading,isError} = useIndexWeightingQuery({...params,...{id:params.indexId,category:'symbol'}})
    const [weightingData, setWeightingData] = useState([])

    useEffect(() => {(async()=>{
        try {
            let array_data = Object.entries(data).map((e)=>({label:e[0],value:e[1]}))
            array_data.sort((b,a) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
            console.log(array_data)
            array_data = array_data.slice(0,params.limit)
            setWeightingData(array_data)

          } catch (error) {
            console.log(error)
        }
    })()},[isLoading])

    return(
        <Stack direction='row' spacing={5} sx={{maxWidth:'100%',overflowX:'auto'}}>
            {weightingData.map((d) => (
                <Tooltip title={d.label}>
                    <Stack direction='column' alignItems='center' justifyContent='center' spacing={1}>
                        <ReturnLogo symbol={d.label} width='60px'/>
                        <Typography sx={{fontSize:'13px', fontWeight:400}}>{(d.value*100).toFixed(2)} %</Typography>
                    </Stack>
                </Tooltip>
            ))}
        </Stack>
    )
  }