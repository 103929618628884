import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

export default function BookDemo(props) {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    setLoading(false)
  }, [])

  const returnCalendly = () => {
    return(
      <div class="calendly-inline-widget" data-url="https://calendly.com/indexone/index-one-meeting?hide_event_type_details=1&hide_gdpr_banner=1" style={{minWidth:'320px',height:'630px'}}></div>
    )
  }


  return (
      <Container maxWidth='sm'>
        {isLoading ? <CircularProgress/> : returnCalendly()}
    </Container>
  );
}