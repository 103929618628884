import React from "react";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import colors from '../../helpers/colors'
import CustomizedAxisTick from './CustomAxis'

function DrawdownChartf(props) {

  return (
    <ResponsiveContainer width="100%" height="100%">
        <AreaChart
        width='100%'
        height='100%'
        data={props.data}
        syncId="anyId"
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={60} dataKey='time' tick={<CustomizedAxisTick />} />
        <YAxis type="number" tickFormatter={tick => `${tick}%` }/>
        <Tooltip />
        {Object.keys(props.refData).filter(x=>props.refData[x].selected == true).map((security,idx) => (
            <Area type="monotone" dataKey={security} stroke={colors[idx]} fill={colors[idx]} dot={false}/>
        ))}
        </AreaChart>
    </ResponsiveContainer>
  );
}

const DrawdownChart = React.memo(DrawdownChartf);
export default DrawdownChart