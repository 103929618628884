import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { useRegisterMutation } from '../../../store/indexOneApi';
import { useNavigate } from 'react-router-dom'

import ReCAPTCHA from "react-google-recaptcha";

import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'

import screen from '../../../images/monitor-filled-small.png'


const industries=[
  "Investment Advisor",
  "Fintech",
  "Asset Management",
  "Wealth Management",
  "ETF Issuer",
  "Banking",
  "Academia"
]

const useCases = [
  "Benchmarking",
  "Index Replication",
  "Research",
  "Software Development",
  "Trading",
  "Shadow Calculation"
]


export default function Register(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [password, setPassword] = useState('Password123!')
  const [industry, setIndustry] = useState('')
  const [orgName, setOrgName] = useState('')
  const [useCase, setUseCase] = useState('')
  const [registerTrigger, { isLoading }] = useRegisterMutation()

  const [enabled, setEnabled] = useState([true,false])
  document.title = `Index One | Register`
  const handleChange = (e) => {
    e.persist()
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value}))
  }
  async function handleSubmit() {
    let e = false
    if(!data.password){
      setErrors(p=>({...p,password:'Password Requried'}))
      e=true
    } else if(!data.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/)){
      setErrors(p=>({...p,password:"Password must be at least 8 characters long, and must contain uppercase letters, lowercase letters, special characters, and numbers"}))
      e=true
    } else{
      setErrors(p=>({...p,password:undefined}))
    }

    if(data.password !== data.confirm_password){
      setErrors(p=>({...p,confirm_password:'Passwords do not match'}))
      e=true
    } else{
      setErrors(p=>({...p,confirm_password:undefined}))
    }
    if(!data.username){
      setErrors(p=>({...p,username:'Email Required'}))
      e=true
    } else if(!data.username.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/g)){
      setErrors(p=>({...p,username:'Valid email address required'}))
      e=true
    } else{
      setErrors(p=>({...p, username:undefined}))
    }
    if(e===false){
      const userObject = {
        username:data.username,
        email:data.username,
        password:data.password,
        firstname:data.first_name,
        lastname:data.last_name,
        use_case:useCase,
        industry:industry,
        company:orgName,
        use_case:useCase
      }
      try{
        await registerTrigger(userObject)
        navigate('/registerconfirm',{state:{username:data.username,password:data.password}})
      }catch(e){
        console.log(e)
        if(e.response){
          alert(e.response.data.message)
        }
        
      }
    } else {
      
    }
  }

  const returnFreeSolo = (state, setFunc, label, options) => {
    return(
      <Autocomplete
        size='small'
        id={label}
        value={state}
        freeSolo
        fullWidth
        options={options}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(e,value)=>setFunc(value)}
        onInputChange={(event, newInputValue) => {
          setFunc(newInputValue);
        }}
        required
      />

    )
  }


  return (
    (<Container maxWidth={false} disableGutters style={{overflow:'hidden'}}>
      <Grid container spacing={3} justify='center' alignItems='center' style={{minHeight:'90vh'}}>
        <Grid
          style={{textAlign:'center'}}
          size={{
            sm: 12,
            md: 6
          }}>
          <Typography variant='h4'>Access the <b style={{fontWeight:300}}>index</b>one platform.</Typography>
          <Container maxWidth='xs'>
          <br/>
          <Typography>Index One provides a trusted, state-of-the-art index calculation platform delivering <b style={{fontWeight:400}}>rapid, flexible and scalable index solutions.</b> 
            <br/> <br/>Leverage our <b style={{fontWeight:400}}>revolutionary cloud-based indexing tools and premier client service</b> to utilize, build or own indices.</Typography>
            </Container>
          <img src={screen} style={{maxWidth:'40%', marginTop:'2em'}}/>
        </Grid>
        <Grid
          size={{
            sm: 12,
            md: 6
          }}>
        <Container maxWidth='xs'>
        <form noValidate>
          <Stack spacing={2}>
          <Stack direction='row' spacing={2}>
            <TextField
              size='small'
              error={errors.first_name}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              autoComplete="first_name"
              onChange={(e) => handleChange(e)}
              value={data.first_name}
              sx={{margin:0}}
            />
            <TextField
            size='small'
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="last_name"
              onChange={(e) => handleChange(e)}
              value={data.last_name}
              sx={{margin:0}}
            />
          </Stack>
          <TextField
            size='small'
            variant="outlined"
            margin="normal"
            required
            error={errors.username}
            helperText={errors.username}
            fullWidth
            id="email"
            label="Email"
            name="username"
            autoComplete="email"
            onChange={(e) => handleChange(e)}
            value={data.username}
          />
          <TextField
            size='small'
            variant="outlined"
            margin="normal"
            required
            error={errors.orgName}
            helperText={errors.orgName}
            fullWidth
            id="orgName"
            label="Organization Name"
            name="Organization Name"
            autoComplete="Organization Name"
            onChange={(e) => setOrgName(e.target.value)}
            value={orgName}
          />
            {returnFreeSolo(industry,setIndustry,'Industry',industries)}
            {returnFreeSolo(useCase,setUseCase,'Use Case',useCases)}
            <Stack direction='row' spacing={2}>
                <TextField
                size='small'
                variant="outlined"
                required
                margin="normal"
                error={errors.password}
                helperText={errors.password}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(e) => handleChange(e)}
                value={data.password}
                sx={{margin:0, padding:0}}
              />
                <TextField
                size='small'
                variant="outlined"
                error={errors.confirm_password}
                helperText={errors.confirm_password}
                required
                margin="normal"
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type="password"
                id="confirm_password"
                onChange={(e) => handleChange(e)}
                value={data.confirm_password}
                sx={{margin:0, padding:0}}
              />
            </Stack>
            <ReCAPTCHA
                  sitekey="6LcsnqsUAAAAAKKLr0rLvEENeHJJJM_Ze1U1l6Dh"
                  onChange={e => setEnabled(p => [...p.slice(0,0), true, ...p.slice(1)])}
            />
                  <FormControlLabel
          control={
            <Checkbox
              checked={enabled[1]}
              onChange={e => setEnabled(p => [...p.slice(0,1), !p[1]])}
              name="checkedB"
              color="primary"
            />
          }
          label="I agree to the terms & conditions"
        />
            <LoadingButton loading={isLoading} fullWidth disabled={!enabled.every(Boolean)} variant="contained" color="primary" size="large" onClick={()=>handleSubmit()}>Register</LoadingButton>
          </Stack>
          </form>
          </Container>
        </Grid>
      </Grid>
    </Container>)
  );
}