export const mapping = {
    "asset_class":"Asset Class",
    "region":"Region",
    "factor":"Factor",
    "equity":"Equity",
    "fixed_income":"Fixed Income",
    "volatility":"Volatility"
  }
  
export const categories = {
    "asset_class":["equity","fixed_income","crypto"],
    "region":["US","UK","GR"],
    "factor":["volatility","momentum","value","size","multifactor"],
    "strategy":["long_only"],
    "sector":["Energy"],
    "theme":["Metaverse"]
  }