import React, {useState, useEffect, useRef} from 'react';
import config from '../../config'
import getParamsJoined from '../helpers/paramHelper'
import { useSearchParams,useParams } from "react-router-dom"
import { useIndexValuesQuery } from '../../store/indexOneApi';

import { makeStyles } from '@mui/styles';

import { Typography } from '@mui/material';
import Grid from "@mui/material/Grid2"
import colors from '../helpers/colors'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const useStyles = makeStyles({
    "@keyframes myEffect": {
        "0%": {
        },
        "100%": {
          color:'black'
        }
      },
    nGreen:{
        color:colors[0],
        animation: '$myEffect 1000ms 0.75s ease-out forwards'
    },
    nRed:{
        color:colors[1],
        animation: '$myEffect 1000ms 0.75s ease-out forwards'
    }
  })

export default function PriceWidget(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = getParamsJoined(['indexId'],props,searchParams,useParams())
    var indexId = null
    if (props.indexId){indexId = props.indexId}
    if (params.indexId){indexId = params.indexId}
    const {data = []} = useIndexValuesQuery({id:indexId,duration:259200,increment:"1d"})
    const classes = useStyles()
    const [openPrice, setOpenPrice] = useState(1000)
    const [price, setPrice] = useState(1000)
    const [websocket, setWebsocket] = useState(null)
    const [flashGreen, setFlashGreen] = useState(true)
    const [latestTime, setLatestTime] = useState('')
    //stateref required because of websocket setup..
    const stateRef = useRef()
    stateRef.current = [websocket,price]

    const websocketConnect = (index_id) => {
        if(websocket){return}
        console.log('connecting websocket')
        var ws = new WebSocket(`${config.wsUrl}?index_id=${index_id}&table=values`);
        setWebsocket(ws)
        ws.onopen = () => {
            console.log('websocket connected')
        }
        ws.onmessage = e => {
            const message = JSON.parse(e.data)
            updatePrice(message.value,message.time)
        }
        ws.onclose = e => { console.log('websocket closed')}
        ws.onerror = err => {
            console.error("Socket encountered error: ",err.message,"Closing socket")
            ws.close()
        }
    }

    const updatePrice = (p,t) => {
        //let prevP = stateRef.current[1]
        let prevP = stateRef.current[1]
        if(prevP > p){
            setFlashGreen(false)
        }else{
            setFlashGreen(true)
        }
        if(!isNaN(p)){setPrice(p)}
        setLatestTime(t)
    }

    const getIndexData = (index_id) => {
        try {
                if (data.length == 1){
                    setOpenPrice(data[0].value)
                    updatePrice(data[0].value,data[0].time)
                }else{
                    setOpenPrice(data[data.length - 2].value)
                    updatePrice(data[data.length - 1].value, data[data.length - 1].time)
                }
          } catch (err) {
            console.error(err)
          }
    }

    useEffect(() => {
        getIndexData(indexId)
        websocketConnect(indexId)

        return () => {
            console.log('unmounting')
            websocketDisconnect()
            // Anything in here is fired on component unmount.
        }
    }, [data])
    const websocketDisconnect = () => {
        stateRef.current[0].close()
    }

    return (
        (<div>
            <div style={{position:'absolute',zIndex:"1"}}>
                <Typography variant='h6' style={{color:'gray',fontSize:'12px'}}>{latestTime} UTC</Typography>
            </div>
            <Grid container alignItems='center' justify='space-evenly' sx={{marginBottom:'-20px'}}>
                <Grid>
                    <Typography variant='h4' key={price} className={flashGreen ? classes.nGreen : classes.nRed} style={{fontWeight:300}}>{price.toFixed(2)}</Typography>
                </Grid>
                <Grid>
                    { ((price/openPrice)-1)*100 > 0 ?
                        <ArrowDropUpIcon style={{fontSize:'80px', color:colors[0]}}/>
                        :
                        <ArrowDropDownIcon style={{fontSize:'80px', color:colors[1]}}/>
                    }
                </Grid>
                <Grid>
                    <Typography variant='h4' style={{fontWeight:300}} >{(((price/openPrice)-1)*100).toFixed(2) + '%'}</Typography>
                </Grid>
            </Grid>
        </div>)
    );
  }