import React, { useEffect, useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';

import ContactForm from '../../elements/ContactForm'
import makeStyles from '@mui/styles/makeStyles'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box'

const useStyles = makeStyles({
  c1:{
    overflow:'hidden',
    padding: '20px 0px'
  },
  c2:{
    clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))',
    overflow:'hidden',
    padding: '100px 0px'
  },
  c3:{
    overflow:'hidden',
    padding: '20px 0px'
  },
  c4:{
    clipPath: 'polygon(0 100px, 100% 0, 100% calc(100% - 100px), 0% 100%)',
    overflow:'hidden',
    padding: '100px 0px'
  }
})

const rColor = () => {
  const c = ['#3d5a80','#3d5a80']
  return(c[Math.floor(Math.random() * (c.length))])
}

export default function UseCases(props) {
  const classes = useStyles()
  const params = useParams()
  const store = useStore().getState()
  const [indices,setIndices] = useState([])
  const dispatch = useDispatch()
  const solutionType = params.solution
  useEffect(() => {(async()=>{
    console.log(params.solution)
    document.title = `Index One | Use Cases`
  })()},[])

  const returnBannerText = () =>{
    return(
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'60vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
        {Object.keys(content).includes(solutionType) ?
        <>
          <Typography style={{color:'#fafafa'}} variant="h2">{content[solutionType].title}</Typography>
          <Typography style={{color:'#fafafa'}} variant="h5">{content[solutionType].subtitle}</Typography>
        </>
          :
        <>
          <Typography style={{color:'#fafafa'}} variant="h2">{parts[solutionType].title}</Typography>
          <Typography style={{color:'#fafafa'}} variant="h5">{parts[solutionType].subtitle}</Typography>
        </>
        }
      </Box>
    )
  }

  const returnParts = () => {
    var count = 0
    const c = ['c1','c2','c3','c4']
    var r = []
    content[solutionType].parts.forEach(function (p,i) {
      var bgcolo = i%2 == 0 ? '':rColor()
      var volo = i%2 == 0 ? '':'white'
      r.push(
        <Box className={classes[c[i % c.length]]} flexDirection='column' display='flex'justifyContent='center' alignItems='center'
          style={{
            //backgroundColor:clsx(i%2 == 0 ? '':rColor()),
            backgroundColor:bgcolo,
            color:volo
          }}
        >
          <Container maxWidth='lg' style={{margin:'25px 0px'}}>
            <Grid container spacing={6}>
              <Grid
                size={{
                  sm: 12,
                  md: 4
                }}>
                <Typography variant='h4' style={{marginBottom:'0rem'}}>{parts[p].subtitle}</Typography>
              </Grid>
              <Grid
                size={{
                  sm: 12,
                  md: 7
                }}>
                {parts[p].content.map(t=>
                  <Typography variant='h6' style={{marginBottom:'0em',fontWeight:300}}>{t}</Typography>)
                }
              </Grid>
              { parts[p].image &&
                <Grid
                  size={{
                    sm: 12,
                    md: 4
                  }}>
                  <img style={{color:'#353b41', boxShadow:'10px 10px 27px 0px', borderRadius:10, maxWidth:'80%'}} src={parts[p].image}/>
                </Grid>
              }
            </Grid>
          </Container>
        </Box>
      )
    })
    return(r)
  }

  const returnPart = () => {
    return (
      (<Box className={classes.c1} flexDirection='column' display='flex'justifyContent='center' alignItems='center'
            style={{
              backgroundColor:'white'
            }}
          >
        <Container maxWidth='lg' disableGutters style={{margin:'50px 0px'}}>
          <Grid container spacing={6}>
            <Grid
              size={{
                sm: 12,
                md: 4
              }}>
              <Typography variant='h4' style={{marginBottom:'1rem'}}>{parts[solutionType].subtitle}</Typography>
            </Grid>
            <Grid
              size={{
                sm: 12,
                md: 7
              }}>
              {parts[solutionType].content.map(t=>
                <Typography variant='h6' style={{marginBottom:'1em',fontWeight:300}}>{t}</Typography>)
              }
            </Grid>
            { parts[solutionType].image &&
            <Grid
              size={{
                sm: 12,
                md: 4
              }}>
              <img style={{color:'#353b41', boxShadow:'10px 10px 27px 0px', borderRadius:10, maxWidth:'80%'}} src={parts[solutionType].image}/>
              </Grid>
            }
          </Grid>
        </Container>
      </Box>)
    );
  }
    return (
      <Container maxWidth={false} disableGutters>
        {
        returnBannerText()}
        { Object.keys(content).includes(solutionType) ?
          returnParts()
          :
          returnPart()
        }
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{padding:'100px 0px', marginBottom:'-15vh',backgroundColor:'#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))'}}>
          <ContactForm/>
        </Box>
      </Container>
    )
}

const content = {
  ria:{
    title:'Solutions for RIAs',
    subtitle:'Design and calculate investable indices with fully customizable exposure.',
    parts: ['backtesting','calculation','rebalancing','factsheets', 'verification']
  },
  hedgefunds:{
    title:'Solutions for Hedge Fund Managers',
    subtitle:'Design and calculate investable indices with fully customizable exposure.',
    parts: ['backtesting','rebalancing','integrations','delivery', 'factsheets']
  },
  assetowners:{
    title:'Solutions for Asset Owners',
    subtitle:'Construct indices specific to your mandates',
    parts:['calculation','backtesting', 'delivery', 'rebalancing']
  },
  dataproviders:{
    title:'Solutions for Data Providers',
    subtitle:'Turn your data into custom indices.',
    parts: ['calculation','benchmarking','factsheets','integrations']
  },
  structuredproducts:{
    title:'Solutions for Structured Products Desks',
    subtitle:'All-in-one backtesting and index calculation platform.',
    parts:['backtesting','calculation','benchmarking', 'rebalancing', 'delivery']
  },
  wealthmanagers:{
    title:'Solutions for Wealth Managers',
    subtitle:'Get the right data to support your portfolios',
    parts:['calculation','backtesting','delivery','rebalancing', 'factsheets']
  },
  research:{
    title:'Solutions for Equity Researchers',
    subtitle:'Get the data you need with high accuracy',
    parts:['research', 'backtesting', 'verification', 'factsheets']
  },
  fintech:{
    title:'Solutions for Fintechs',
    subtitle:'Index data to support your application at scale',
    parts:['replication','delivery','benchmarking', 'factsheets']
  },
  academics:{
    title:'Solutions for Academics',
    subtitle:'Index data to support your academic pursuits',
    parts:['backtesting','calculation','benchmarking']
  }
}

const parts = {
  verification:{
    title:'Index Verification',
    subtitle:'Perform accurate and timely calculation of any strategy.',
    content:[
      `Index One can act as a third-party index verification agent for your existing indices.

      With the help of our flexible tools, you can easily replicate target exposures over multiple iterations in order to attain the most accurate proxy index and execute shadow calculation of other indices or passive strategies.
      
      Once running, use our tools to predict index composition changes or flag discrepancies in a primary index on an ongoing basis.`
      
    ]
  },
  delivery:{
    title:'Index Data Delivery',
    subtitle:'Deliver and disseminate index values anywhere.',
    content:[
      `Indices running on our platform are built to be distributed to external users, systems, and stakeholders.
      Our APIs make it possible to access index data at low latency and extreme granularity, from index values to individual index adjustment calculations.
      
      Other access methods include STFP, email, embed codes, or custom solutions depending on your needs.
      You may also define various rules-based delivery rules based on a schedule, index events, or market events.`
      
    ]
  },
  calculation:{
    title:'Custom Index Calculation',
    subtitle:'Customize your indices at scale.',
    content:[
      `Constructing bespoke indices for portfolios can be challenging without the right tools in place.

      Index One’s index calculation engine allows for seamless construction and ongoing calculation of real-time indices to support a wide variety of structured products following both plain and exotic strategies. 
      
      We offer a wide range of options including short positions, leverage, currency hedging, and more.
      
      Index One enhances the capacity to determine custom index constitution and weighting over time, while automating corporate action treatments and other adjustments.
      
      Our API-based approach allows for creation and distribution of custom client indices at scale.`
      
    ]
  },
  research:{
    title:'Equity Research',
    subtitle:'Build robust indices for accurate research.',
    content:[
      `Conducting accurate research relies on strong input data.

      Use Index One’s index calculation tools to easily construct multiple indices to support your research efforts.
      
      Our backtesting and index calculation platform makes it easy to extract the necessary data, and export it into other models and software for further analysis.`
      
    ]
  },
  replication:{
    title:'Index Replication',
    subtitle:'Cost effective replication of indices at scale.',
    content:[
      `Index replication requires robust and investable indices, with timely access to composition data.

      Index One’s index calculation platform helps investors construct indices with the right exposure, and access composition data in real-time through a variety of methods.`
      
    ]
  },
  benchmarking:{
    title:'Custom Benchmarks and Market Indices',
    subtitle:'Use an existing benchmark or create your own.',
    content:[
      `Index One provides a range of standardized indices designed for benchmarking, base universe references, display purposes, and various other applications.

      Our index calculation tools allow you to construct custom benchmarks or choose from an existing market benchmark depending on your investment process, asset base, or other constraints, or other factors that are a true reflection of the exposure you are benchmarking towards.`

    ]
  },
  backtesting:{
    title:'Backtesting and Basket Calculation',
    subtitle:'Calculate ongoing values and weightings for custom baskets.',
    content:[
      `Backtest and construct multi asset-class indices and calculate your index at any frequency with Index One’s versatile index calculation platform.

      Index One enables users to quickly generate backtests on customized baskets and index strategies, allowing for seamless sharing of backtest results externally and within teams. 
      
      Index One users can perform backtests on index strategies or defined baskets using up to 15 years of curated data, enabling flexibility to adjust index parameters based on index composition, risk factors, performance metrics, and exposure levels.`
      
    ]
  },
  directindexing:{
    title:'Direct Indexing',
    subtitle:'Manage direct indexing workloads on a single platform.',
    content:[
      `Index One allows for en-masse customization of indices depending on the needs of individual portfolios, to support direct indexing at a large scale.

      Indices can be accessed on demand, or delivered on regular schedules, or fully integrated with a direct indexing application for onward distribution and tracking.`
      
    ]
  },
  rebalancing:{
    title:'Rebalancing and Corporate Actions',
    subtitle:'Automate your index rebalancing and corporate actions.',
    content:[
      `Index One streamlines index adjustments through automated rebalancing and management of corporate actions, and provides a dedicated team specifically tasked with overseeing index adjustments.`
    ]
  },
  factsheets:{
    title:'Custom Factsheet Generation',
    subtitle:'Create custom factsheets for your index.',
    content:[
      `Index One offers standard and custom factsheets for all live indices calculated by Index One.` 
    ]
  },
  integrations:{
    title:'Index Integrations',
    subtitle:'Integrate index values into any platform.',
    content:[
      `Incorporate your index seamlessly into any website or platform using Index One’s streamlined integration choices, including API, SFTP, and iframes.`
    ]
  }
}