function returnLongestLabel(data){
    const response = Math.max(...data.map(d => d.label.length))
    return response
}

function truncateLabels(data){
    for (const d of data){
        const newKey = d.label.length > 30 ? `${d.label.substring(0, 30)}...` : d.label
        d.label = newKey
    }
    return data
}

export {returnLongestLabel,truncateLabels}