import React, { useState, useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
//dialog
import { useLazyBacktestPreviewQuery } from "../../../store/indexOneApi";

import { Grow } from '@mui/material'
import { FixedSizeList } from 'react-window';

function SecuritySearchMemo(props) {
  const [searchInput,setSearchInput] = useState('')

  const [fetchTrigger, { data=[], error, isLoading, isFetching } , lastPromiseInfo] = useLazyBacktestPreviewQuery()

  useEffect(() => {
    const timeoutId = setTimeout(() => handleSearch(searchInput), 1000);
    return () => clearTimeout(timeoutId);
  }, [searchInput]);

  const handleSearch = async (payload) => {
    await fetchTrigger({'search':true,'string':payload},true)
  }

  const returnListRow = ({data,index,style}) => {
    const item = data[index]
    return(
      <Grow
        in={true}
        timeout={Math.min(index*100,1000)}
        style={style}
        key={item[2]}
        >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={item[1]} src={`https://storage.googleapis.com/iex/api/logos/${item[1]}.png`} />
          </ListItemAvatar>
          <ListItemButton role={undefined} onClick={e=>props.selectFunction(item)} dense>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={props.selected.includes(item[1]) || props.selected.includes(item[2])}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': item[1] }}
        />
      </ListItemIcon>
      <ListItemText
            primary={`${item[1]} | ${item[2]}`}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item[0]}
                </Typography>              
              </React.Fragment>
            }
          />
    </ListItemButton>
        </ListItem>
        </Grow>
    )}

  return (
    <Container maxWidth='sm'>
      <TextField sx={{margin:'1em', width:'90%'}} value={searchInput} onChange={e=>setSearchInput(e.target.value)}/>
      {isLoading && <LinearProgress/>}
      <FixedSizeList
        height={600}
        width={560}
        itemSize={56}
        itemCount={data.length}
        overscanCount={1}
        itemData={data}
      >
      {returnListRow}
          {/*<List sx={{ width: '100%', bgcolor: 'background.paper',maxHeight: '80vh',overflow:'auto' }}>
        {props.securities.map((item, index) => (
        <Grow
          in={true}
          timeout={Math.min(index*1000,10000)}
        >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={item.iex_symbol} src={`https://storage.googleapis.com/iex/api/logos/${item.iex_symbol}.png`} />
          </ListItemAvatar>
          <ListItemText
            primary={item.iex_symbol}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {item.company_name}
                </Typography>              
              </React.Fragment>
            }
          />
        </ListItem>
        </Grow>
        ))}
      
          </List>*/}
      </FixedSizeList>
    </Container>
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
 if (prevProps == nextProps){
   return true
 } else {
   return false
 }
}

const SecuritySearch = React.memo(SecuritySearchMemo,areEqual);
export default SecuritySearch