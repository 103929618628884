import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTeamQuery,useDeletePaymentMethodMutation,useSetupIntentMutation, useTeamPatchMutation } from '../../../store/indexOneApi';
import { useSearchParams,useParams } from "react-router-dom"
import CreditCard from '../../elements/CreditCard'
import config from '../../../config'

import { NotificationContext } from '../../elements/NotificationContext';


import { 

  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Tooltip,
  Stack,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Alert,
  Snackbar
} from '@mui/material'

import Grid from "@mui/material/Grid2"

import { LoadingButton } from '@mui/lab'

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import StripeSetupForm from './StripeSetupForm';

/*
use stripe setupintent and payment element combination
flow:
user inputs card details into form
submit creates i1 API request
backend: get stripe customer id, or create stripe customer id
backend: create payment intent, return the key
frontend: use key to add payment card to payment intent
additional: list payment methods/intents, provide ability to delete
*/


const subscriptions = [
  {
    "name":"free",
    "display_name":"Free",
    "description":"View-only access to published indices.",
    "starting_price":0,
    "current":false,
    "features":[
      "View-only Access"
    ],
    "pricing":[
      "Free to use"
    ]
  },
  /*{
    "name":"start",
    "display_name":"Start",
    "description":"Ideal for consuming existing indices on the platform.",
    "starting_price":20,
    "current":false,
    "features":[
      "Public Indices",
      "Realtime Index Values",
      "Historical Index Values",
      "Index Constituents",
      "Streaming Notifications",
      "Unlimited API Keys",
    ],
    "pricing":[
      "$20/month"
    ]
  },*/
  {
    "name":"professional",
    "display_name":"Professional",
    "description":"Access to full functionality, including custom index creation.",
    "starting_price":90,
    "current":false,
    "features":[
      "Custom Indices",
      "Unlimited Backtesting",
      "Public Indices",
      "Realtime Index Values",
      "Historical Index Values",
      "Index Constituents",
      "Streaming Notifications",
      "Unlimited API Keys",
    ],
    "pricing":[
      "$90/month per live custom index",
      "minimum $90/month"
    ]
  },
  {
    "name":"enterprise",
    "display_name":"Enterprise",
    "description":"Get in touch with our team at contact@indexone.io for information about our enterprise offering.",
    "starting_price":"x",
    "current":false,
    "features":[
      "Bespoke Functionality",
      "Private Instances",
      "Index Administration",
      "more..."
    ],
    "pricing":[
      "Customized pricing depending on usage"
    ]
  }
]

const customEqual = (oldValue, newValue) => oldValue === newValue

export default function Billing(props) {
  const teamId = useSelector(state => state.main.teamData.id)
  const [ setupIntent, setupIntentResult ] = useSetupIntentMutation()
  const [ deletePaymentMethod, deletePaymentMethodResult ] = useDeletePaymentMethodMutation()
  const [ teamPatch, teamPatchResult ] = useTeamPatchMutation()
  const teamResponse = useTeamQuery({"id":teamId,"return_payment_methods":true , "return_invoices":true})
  const { createNotification } = useContext(NotificationContext)
  const [searchParams, setSearchParams] = useSearchParams()
  //const teamId = useSelector(state => state.activeTeamId,customEqual)
  //const store = useStore().getState()
  //asdasdasd
  const dispatch = useDispatch()
  const stripePromise = loadStripe(config.stripeKey);

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false)
  const [subscriptionSelected,setSubscriptionSelected] = useState('professional')
  const [cardDialogOpen, setCardDialogOpen] = useState(false)
  const [teamState,setTeamState] = useState({})
  const [stripeClientSecret,setStripeClientSecret] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [paymentMethods,setPaymentMethods] = useState([])
  const [invoices,setInvoices] = useState([])
  const [upcomingInvoice,setUpcomingInvoice] = useState({})
  const [loading,setLoading] = useState(true)

  //const plan = useSelector(state => state.activeTeam.subscription)
  //const asd = useSelector(state => console.log(state.activeTeam))

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'billing'})
    document.title = `Index One | Billing`


    console.log(props)
    if(searchParams.get('subscription_selected')){
      setSubscriptionSelected(searchParams.get('subscription_selected'))
      setSubscriptionDialogOpen(true)
    }

    /*
    const teamData = await getTeam(teamId)
    setTeamState(teamData)
    if(teamData.hasOwnProperty('payment_methods')){
      setPaymentMethods(teamData.payment_methods)
    }
    if(teamData.hasOwnProperty('invoices')){
      setInvoices(teamData.invoices)
    }
    if(teamData.hasOwnProperty('upcoming_invoice')){
      setUpcomingInvoice(teamData.upcoming_invoice)
    }
    console.log(teamData)
    */
    setLoading(false)
  })()},[])

  const createPaymentIntent = async () => {
    setLoading(true)
    setCardDialogOpen(true)
    if(stripeClientSecret === null){
      console.log(teamId)
      //const setupIntentResponse = await setupIntentApi(teamId)
      try {
        const returned = await setupIntent({"id":teamId}).unwrap();
        console.log(returned)
        setStripeClientSecret(returned.client_secret)
     } catch (error) {
        console.log(error)
       // you can handle errors here if you want to
     }
      //setupIntent(teamId)
      //console.log(setupIntentResponse)
      //setStripeClientSecret(setupIntentResponse.client_secret)
    }
    setLoading(false)
  }

  const changeSubscription = async () => {
    //const changeResponse = await changeSubscriptionApi(teamId,subscriptionSelected)
    try{
      const patchResponse = await teamPatch({
        "id":teamId,
        "update_subscription":true,
        "target_subscription":subscriptionSelected
      }).unwrap()
      setSubscriptionDialogOpen(false)
      console.log(patchResponse)
    }catch (error) {
      if(error.data?.message == 'payment_method_required'){
        createPaymentIntent()
      }else{
        alert("UNDEF")
      }
      console.log(error)
    }
    /*
    if(changeResponse.status != 200){
      var message = changeResponse.data.message
      if(message == 'payment_method_required'){
        createNotification({
          message: "Please add a payment method before proceeding",
          status: 'warning',
        })
        createPaymentIntent()
      } else {
        createNotification({
          message: "Failed to update subscription, please contact support.",
          status: 'error',
        })
      }
    } else {
      setTeamState(changeResponse.data)
      //update application state
      dispatch({ type: 'SET_ACTIVE_TEAM_DATA', payload: { data: changeResponse.data } })
      createNotification({
        message: "Subscription updated successfully!",
        status: 'success',
      })
      setSubscriptionDialogOpen(false)
    }
    */
  }

  const returnSubscriptionArray = () => {
    //check if team sub matches any other subs
    //asd
    var thisSubscriptions = JSON.parse(JSON.stringify(subscriptions))

    var found = false
    if (teamResponse.data?.hasOwnProperty("subscription")){
      for (let i = 0; i < thisSubscriptions.length; i++) {
        if(thisSubscriptions[i].name === teamResponse.data.subscription){
          found = true
          thisSubscriptions[i].current = true
          break
        } 
      }
      if(found === false){
        thisSubscriptions.push({
          "name":teamResponse.data.subscription,
          "display_name":teamResponse.data.subscription.substring(0, 1).toUpperCase() + teamResponse.data.subscription.substring(1),
          "features":[],
          "current":true
        })
      }
    }
    return(thisSubscriptions)
  }

  const subscriptionBox = (plan) => {
    var boxStyle = {borderStyle:'solid',height:'100%',width:'100%',borderRadius:'5px',borderWidth:'1px',padding:'10px',cursor:'pointer',borderColor:'aquamarine'}
    if(plan.current){
      boxStyle.cursor = 'auto'
      boxStyle.backgroundColor = 'gainsboro'
      boxStyle.borderColor = 'gainsboro'
    }
    return(
      <Paper onClick={e=>{setSubscriptionSelected(plan.name);setSubscriptionDialogOpen(true)}} elevation={plan.current?0:2} sx={boxStyle}>
        <Stack direction='column' sx={{height:'100%'}} spacing={1}>
          <Stack direction='row' spacing={1}>
            <Typography variant='body' fontWeight={400}>{plan.display_name}</Typography>
            {plan.current && <Chip size="small" color='primary' label='Current'/>}
            <div style={{flexGrow:1}}/>
            <Typography variant='body2' fontWeight={400}>from ${plan.starting_price}/mo</Typography>
          </Stack>
          <Typography variant='body2'>{plan.description}</Typography>
          <Stack direction='column' justifyContent='center'>
            {plan.features.slice(0,4).map((f)=>(
              <Stack direction='row' spacing={1} alignItems='center'>
                <DoneIcon/>
                <Typography variant='body2'>{f}</Typography>
              </Stack>
            ))}
          </Stack>
          <div style={{flexGrow:1}} />
          {!plan.current && <Button variant='outlined' fullWidth endIcon={<KeyboardArrowRightIcon/>}>Select</Button>}
        </Stack>
      </Paper>
    )
  }

  const timestampToString = (timestamp) => {
    var date = new Date(timestamp*1000)
    var response = date.toLocaleDateString("en-GB")
    return(response)
  }


  const returnCardDialog = (clientSecret) => {
    //test 'seti_1MPQIhHYnkmaDxpQ7fXNG65z_secret_N9jmVpdYhlEpMxr0jbvnBbvKeegXUIN'
    const options = {
      // passing the client secret obtained in step 3
      clientSecret: stripeClientSecret,
      // Fully customizable with appearance API.
      appearance: {/*...*/},
    };

    return(
      <Dialog
        onClose={e=>setCardDialogOpen(false)}
        open={cardDialogOpen}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          Input Card Details
          <IconButton
          aria-label="close"
          onClick={e=>setCardDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          {stripeClientSecret ?
            <Elements stripe={stripePromise} options={options}>
              <StripeSetupForm subscriptionSelected={subscriptionSelected} subscriptionDialogOpen={subscriptionDialogOpen}/>
            </Elements>
          :
          <CircularProgress />
          }
        </DialogContent>
      </Dialog>
    )
  }

  const returnSubscriptionDialog = () => {
    var thisSubscription = {}
    for (let i = 0; i < subscriptions.length; i++) {
      if(subscriptions[i].name === subscriptionSelected){
        thisSubscription = subscriptions[i]
        break
      } 
    }
    return (
      (<Dialog
        onClose={e=>setSubscriptionDialogOpen(false)}
        open={subscriptionDialogOpen}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {thisSubscription.display_name}
          <IconButton
          aria-label="close"
          onClick={e=>setSubscriptionDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2}>
            <Grid container spacing={2}>
            <Grid size={6}>
            <div style={{padding:'10px',borderRadius:'5px',borderColor:'aquamarine',borderWidth:'1px',borderStyle:'solid'}}>
            <Typography variant='body'>{thisSubscription.description}</Typography>
            <br/>
            <Stack direction='column' justifyContent='center'>
              {thisSubscription.features.map((f)=>(
                <Stack direction='row' spacing={1} alignItems='center'>
                  <DoneIcon/>
                  <Typography variant='body2'>{f}</Typography>
                </Stack>
              ))}
            </Stack>
            </div>
            </Grid>
            <Grid size={6}>
            <Typography fontWeight={300} variant='h6'>Pricing</Typography>
            <Typography variant='body'>
              <ul>
              {thisSubscription.pricing.map((p)=>(
                <li>{p}</li>
              ))}
              </ul>
            </Typography>
            {thisSubscription.name != 'enterprise' && <Typography variant='body2'>You will be billed for the first month of usage immediately.</Typography>}
            </Grid>
            </Grid>
            {thisSubscription.name != 'enterprise' &&
            <>
              <Alert severity="info">By continuing, you agree to the Index One <a href="/terms" target="_blank">Terms & Conditions</a></Alert>
              <LoadingButton variant='contained' fullWidth loading={teamPatchResult.isLoading} onClick={e=>changeSubscription()}>Confirm Changes</LoadingButton>
            </>
            }
          </Stack>
        </DialogContent>
      </Dialog>)
    );
  }

  return (
    (<Container maxWidth="false" sx={{marginTop:'2em',marginBottom:'2em'}}>
      {returnCardDialog()}
      {returnSubscriptionDialog()}
      <Grid container spacing={3} alignItems='stretch'>
        <Grid size="grow">
          <Typography variant='h5' fontWeight={300}>Subscriptions</Typography>
            <Grid container spacing={3}>
              {returnSubscriptionArray().map((s)=>(
                <Grid size={3}>{subscriptionBox(s)}</Grid>
              ))}
            </Grid>
        </Grid>
        <Grid size={3}>
          <Typography variant='h5' fontWeight={300}>Payment Methods</Typography>
          {teamResponse.data?.payment_methods?.map((m)=>{
            return(
              <Stack direction='row' justifyContent='center' alignItems='center'>
                <CreditCard brand={m.card.brand} last4={m.card.last4} expYear={m.card.exp_year} expMonth={m.card.exp_month}/>
                <Tooltip title="Delete Payment Method">
                  <IconButton onClick={e=>deletePaymentMethod(m.id)}>
                    <CloseIcon/>
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          })}
          <LoadingButton variant='contained' fullWidth loading={loading} onClick={e=>createPaymentIntent()}>Add Payment Method</LoadingButton>
        </Grid>
      </Grid>
      <br/>
      <Typography variant='h5' fontWeight={300}>Upcoming Invoice</Typography>
      <TableContainer component={Paper} elevation={4}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell align="right">Invoice Number</TableCell>
              <TableCell align="right">Amount Due (USD)</TableCell>
              <TableCell align="right">Amount Paid (USD)</TableCell>
              <TableCell align="right">Remaining Balance (USD)</TableCell>
            </TableRow>
          </TableHead>
          {Object.keys(upcomingInvoice).length > 0 ?
            <TableBody>
            <TableRow key={upcomingInvoice.number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell><Chip color="warning" size='small' label={upcomingInvoice.status}/></TableCell>
              <TableCell>{timestampToString(upcomingInvoice.period_end)}</TableCell>
              <TableCell align="right">{upcomingInvoice.number}</TableCell>
              <TableCell align="right">${upcomingInvoice.amount_due/100}</TableCell>
              <TableCell align="right">${upcomingInvoice.amount_paid/100}</TableCell>
              <TableCell align="right">${upcomingInvoice.amount_remaining/100}</TableCell>
            </TableRow>
          </TableBody>
          :
          <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="center" sx={{height:'100px'}}>
              No upcoming invoices
            </TableCell>
          </TableRow>
        </TableBody>
          }
        </Table>
      </TableContainer>
      <br/>
      <Typography variant='h5' fontWeight={300}>Invoices</Typography>
      <TableContainer component={Paper} elevation={4}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell align="right">Invoice Number</TableCell>
              <TableCell align="right">Amount Due (USD)</TableCell>
              <TableCell align="right">Amount Paid (USD)</TableCell>
              <TableCell align="right">Remaining Balance (USD)</TableCell>
            </TableRow>
          </TableHead>
          {teamResponse?.data?.invoices?.length > 0 ?
            <TableBody>
            {teamResponse.data.invoices.map((invoice) => (
              <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell><Chip color="primary" size='small' label={invoice.status}/></TableCell>
                <TableCell>{timestampToString(invoice.period_start)}</TableCell>
                <TableCell align="right">{invoice.number}<IconButton aria-label="open" size="small" onClick={e=>window.open(invoice.hosted_invoice_url, '_blank')}><OpenInNewIcon fontSize="inherit" /></IconButton></TableCell>
                <TableCell align="right">${invoice.amount_due/100}</TableCell>
                <TableCell align="right">${invoice.amount_paid/100}</TableCell>
                <TableCell align="right">${invoice.amount_remaining/100}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          :
            <TableBody>
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{height:'100px'}}>
                  No invoices
                </TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
    </Container>)
  );
}